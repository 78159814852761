import React, { useMemo } from "react";
import moment from "moment";
import { Bar } from "../../plugins/react-chartjs-2";
import config from "../../constants/charts";
import { defaultChartOptions } from "../../helpers";
import { getChartConfig } from "../../helpers/getChartConfig";
import { useMetricsState } from "../../context/metrics";
import { useChartData } from "../../data/useChartData";
import { useAnalyticsState } from "../../context/analytics";
import { roundTo } from "./Grouping";

function getDatasets(data, selectedResolution, differenceInWeeks) {
  const groupedData = {};
  let selectedRes = selectedResolution;
  //if selected resolution is 24 hours,  difference in days must be more than 1
  if (selectedRes === "24 hours") {
    const diffInDays = differenceInWeeks * 7;
    if (diffInDays <= 1) {
      selectedRes = "1 hour";
    }
  } else if (selectedRes === "15 mins") {
    //if selected resolution is 15 mins,  difference in weeks must be more than 2
    const diffInWeeks = differenceInWeeks;
    if (diffInWeeks >= 2) {
      selectedRes = "1 hour";
    }
  }

  let dateKeyFormat = "YYYY-MM-DD HH";
  if (selectedRes === "15 mins") {
    dateKeyFormat = "YYYY-MM-DD HH:mm";
  } else if (selectedRes === "1 hour") {
    dateKeyFormat = "YYYY-MM-DD HH";
  } else if (selectedRes === "24 hours") {
    dateKeyFormat = "YYYY-MM-DD";
  }

  // Group data based on date
  data.forEach((record) => {
    const date = moment(record.timestamp).format(dateKeyFormat);
    groupedData[date] = groupedData[date] || {};
    groupedData[date][record.timestamp] = {
      timestamp: record.timestamp,
      gains_pos: record.gains > 0 ? record.gains : 0,
      gains_neg: record.gains < 0 ? record.gains : 0,
    };
  });

  // Sum data based on groups
  var res = Object.keys(groupedData).map((date) => {
    const res = {
      timestamp: roundTo(
        groupedData[date][Object.keys(groupedData[date])[0]].timestamp,
        selectedResolution
      ),
      gains_pos: 0,
      gains_neg: 0,
    };
    Object.keys(groupedData[date]).forEach((timestamp) => {
      res.gains_pos += groupedData[date][timestamp].gains_pos;
      res.gains_neg += groupedData[date][timestamp].gains_neg;
    });
    return res;
  });

  return res;
}

const ChartMoistureGains = ({
  data,
  fieldId,
  chart,
  range,
  theme,
  chartEvents,
  selectedResolution,
}) => {
  const metrics = useMetricsState();
  // calculate diff in weeks/days to display correct information
  const {
    range: { start_date, end_date },
  } = useAnalyticsState();
  const calculateDifferenceInWeeks = () => {
    const msInWeek = 1000 * 60 * 60 * 24 * 7;
    const diffEpoch = end_date - start_date;
    const differenceInWeeks = diffEpoch / msInWeek;
    return differenceInWeeks;
  };
  let differenceInWeeks = calculateDifferenceInWeeks();

  const { chartData } = useChartData(chart.group, range, fieldId);
  const chartConfig = useMemo(() => {
    const config = getChartConfig(
      getDatasets(chartData ?? [], selectedResolution, differenceInWeeks),
      chart.id,
      theme,
      metrics
    );
    //construct a localstorage key for this field
    const positiveKey = "displayPositiveGains" + fieldId.toString();
    const negativeKey = "displayNegativeGains" + fieldId.toString();

    //check if chart lines are hidden or not
    if (config.datasets.length > 0) {
      config.datasets[0].hidden = localStorage.getItem(positiveKey) === "false";
      config.datasets[1].hidden = localStorage.getItem(negativeKey) === "false";
    }

    return config;
  }, [chartData, theme, chart.id, metrics, selectedResolution]);
  const chartOptions = useMemo(
    () =>
      defaultChartOptions(
        config[chart.id].axis,
        config[chart.id].legend,
        range,
        chartEvents,
        fieldId
      ),
    [chart.id, range]
  );

  return (
    <Bar ref={chartEvents.chartRef} data={chartConfig} options={chartOptions} />
  );
};
export default ChartMoistureGains;
