import api from "./agritech";
import { User } from "./auth";

export async function getUser(token: string, id: number) {
  if (!id) return;
  const response = await api.get(`/users/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

export async function saveUser(token: string, values: User) {
  const response = await api.post(
    `/register`,
    { ...values },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

export async function updateUser(token: string, id: number, values: User) {
  if (!id) return;
  const response = await api.patch(
    `/users/${id}`,
    { ...values },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

export async function deleteUser(token: string, id: number) {
  const response = await api.delete(`/users/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

export async function setUserAdminAccess(
  token: string,
  userId: number,
  clientIds: number[]
) {
  const response = await api.patch(
    `/users/${userId}/admin`,
    { client_ids: clientIds },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}
