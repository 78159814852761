import React, { forwardRef, useMemo } from "react";
import moment from "moment";
import MaterialTable from "@material-table/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useTheme } from "@material-ui/core";
import { MTableToolbar } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import chartConfig from "../../constants/charts";
import { useMetricsState } from "../../context/metrics";
import { useChartData } from "../../data/useChartData";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const formatTableData = (data, headers, metrics, chartType) => {
  const chartMetrics = Object.values(headers) ?? [];
  if (!chartMetrics.length) return null;

  var res = {
    columns: [
      { title: "Timestamp", field: "timestamp" },
      ...chartMetrics.map((type) => {
        const metric = metrics.filter((item) => item.ref === type);
        const config = metric.length ? metric[0] : null;
        return {
          title: config?.name || `Unknown (${type})`,
          field: type,
          cellStyle: { minWidth: 120 },
        };
      }),
    ],
  };
  // for chilling hours and growing hours, call aggregation function to get the value
  if (
    (chartType === "chilling_hours" || chartType === "growing_hours") &&
    typeof chartConfig[chartType].metrics[chartType]?.aggregation == "function"
  ) {
    res["data"] = chartConfig[chartType].metrics[chartType]
      .aggregation(data)
      .map((item, i) => {
        return {
          id: item.id,
          timestamp: moment(item.x).format("DD/MM/YY HH:mm"),
          time: item.x,
          [chartType]: item.y,
        };
      });
  } else {
    res["data"] = data.map((item, i) => {
      var res = {
        id: item.id,
        timestamp: moment(item.timestamp).format("DD/MM/YY HH:mm"),
        time: item.timestamp,
      };
      chartMetrics.forEach((m) => {
        if (chartConfig[chartType].metrics[m]?.property) {
          res[m] = item[chartConfig[chartType].metrics[m].property];
        } else {
          res[m] = item[m];
        }
      });
      return res;
    });
  }
  res["data"].sort((a, b) => a.time - b.time);
  return res;
};

export const defaultTableOptions = {
  search: false,
  padding: "dense",
  actionsColumnIndex: -1,
  exportAllData: true,
  exportMenu: [
    {
      label: "Export PDF",
      exportFunc: (cols, data) => ExportPdf(cols, data, "export"),
    },
    {
      label: "Export CSV",
      exportFunc: (cols, data) => ExportCsv(cols, data, "export"),
    },
  ],
};

export const analyticsTableToolbar = (theme) => ({
  Toolbar: (props) => (
    <div
      style={{
        backgroundColor: theme.palette.tertiary.light,
        color: theme.palette.tertiary.dark,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      }}
    >
      <MTableToolbar {...props} />
    </div>
  ),
});

export default (props) => {
  const theme = useTheme();

  const metrics = useMetricsState();

  const tableConfig = {
    components: analyticsTableToolbar(theme),
    options: defaultTableOptions,
  };

  const { chartData } = useChartData(
    props.chart.group,
    props.range,
    props.field.id
  );

  const tableData = useMemo(() => {
    return formatTableData(
      chartData ?? [],
      props.chart.table,
      metrics,
      props.chart.id
    );
  }, [chartData, props.chart, metrics]);

  return (
    <MaterialTable
      {...props}
      icons={tableIcons}
      columns={tableData.columns}
      data={tableData.data}
      options={{
        ...tableConfig.options,
        ...{
          headerStyle: {
            ...tableConfig.options.headerStyle,
            backgroundColor: theme.palette.tertiary.main,
            color: theme.palette.common.white,
            lineHeight: "1.2rem",
            minWidth: 100,
          },
        },
      }}
    />
  );
};
