// import { resetDatabase } from "../../data/Database";
// import { resetDataLoader } from "../../data/DataLoader";
import { useEffect } from "react";
import CardWarning from "../cards/CardWarning";
import * as Sentry from "@sentry/react";
import { useAuthState } from "../../context/auth";

export const NoFieldsWarning = () => {
  const user = useAuthState();
  const client = user?.client;
  const name = client?.name ?? "Unknown client";
  useEffect(() => {
    if (!client?.id) {
      Sentry.captureMessage(
        `Client ID is undefined when loading fields for ${name}`
      );
    }
  }, [client, name]);
  return (
    <CardWarning
      message={`No ${name} fields found!`}
      // button={{
      //   text: "Refresh",
      //   action: () => {
      //     resetDatabase();
      //     resetDataLoader();
      //     window.location.reload();
      //   },
      // }}
    />
  );
};
