import React, { useMemo } from "react";
import AdminTable from "./AdminTable";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { deleteIrrigatorCommand } from "../../../api/irrigators";

export default function IrrigatorCommandsTable({
  user,
  activeClient,
  data,
  types,
  refresh,
}) {
  const history = useHistory();
  const commandsWithTypes = data.map((command) => {
    const type = types.find((t) => t.id === command.irrigator_type_id);
    if (type === undefined) return command;
    return { ...command, type: type.name };
  });

  const config = useMemo(
    () => ({
      columns: [
        {
          title: "Name",
          field: "name",
        },
        {
          title: "Command",
          field: "command",
        },
        {
          title: "Type",
          field: "type",
        },
      ],
      data: commandsWithTypes,
      options: { pageSizeOptions: [5, 10, 20, 50] },
    }),
    [commandsWithTypes]
  );

  // const history = useHistory();
  const onCreate = () => history.push("/admin/irrigator-command");
  const onEdit = (id) => history.push(`/admin/irrigator-command/${id}`);
  const onDelete = async (record) => {
    const { id } = record;
    const response = await deleteIrrigatorCommand(user.token, id);
    refresh();
    return response.data;
  };

  return (
    <AdminTable
      title={<Typography variant="h2">Irrigator Commands</Typography>}
      type="Irrigator Command"
      config={config}
      // onRefresh={loadIrrigators}
      onCreate={onCreate}
      onEdit={onEdit}
      onDelete={onDelete}
      deleteLabel="id"
    />
  );
}
