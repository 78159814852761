import { Theme, useTheme } from "@material-ui/core";

type ExtraColours = "orange" | "purple" | "pink" | "black" | "brown";
export type ExtendedTheme = Theme & {
  palette: {
    tertiary: Theme["palette"]["secondary"];
    danger: Theme["palette"]["error"];
  } & {
    [key in ExtraColours]: Theme["palette"]["primary"];
  };
};
export const useExtendedTheme = () => {
  return useTheme() as ExtendedTheme;
};
