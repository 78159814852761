import React from "react";
import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router";
import { useAuthState } from "../../../../../context/auth";
import FieldNavLink from "../../../../elements/FieldNavLink";
import FieldMeta from "../../../../field/FieldMeta";
import { useAnalyticsState } from "../../../../../context/analytics";

const AnalyticsHeader = () => {
  const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
      padding: spacing(4, 2, 3),
      marginTop: spacing(-4),
      backgroundColor: palette.grey[100],
    },
    icon: {
      marginLeft: spacing(1),
      marginRight: spacing(1),
      color: palette.text.disabled,
    },
  }));
  const classes = useStyles();
  const { isAdmin, client } = useAuthState();
  const { field } = useAnalyticsState();
  const history = useHistory();
  const handleClick = ({ id }) => {
    return history.push(`/admin/field/${id}`);
  };

  return (
    <Grid className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} md={true}>
          <FieldNavLink fields={client.fields} current={field.id}>
            {isAdmin ? (
              <IconButton
                size="small"
                className={classes.icon}
                onClick={() => handleClick(field)}
              >
                <EditIcon />
              </IconButton>
            ) : null}
            <Typography variant="h1">{field.name}</Typography>
          </FieldNavLink>
        </Grid>
        <Grid item>
          <FieldMeta field={field} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AnalyticsHeader;
