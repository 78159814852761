import moment from "moment";
import { MomentInput } from "moment-timezone";

export type Resolution = "15 mins" | "1 hour" | "24 hours";

export const roundTo = (t: MomentInput, r: Resolution) => {
  const time = moment(t);
  switch (r) {
    case "15 mins":
      return time.startOf("minute").valueOf();
    case "1 hour":
      return time.startOf("hour").valueOf();
    case "24 hours":
      // use noon to make snapping to day boundaries more intuitive
      return time.startOf("day").add(12, "h").valueOf();
  }
};
