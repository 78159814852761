import React, { useMemo } from "react";
import { Line } from "../../plugins/react-chartjs-2";
import config from "../../constants/charts";
import { defaultChartOptions } from "../../helpers";

import { useChartData } from "../../data/useChartData";

const thresholdIndex = (value, thresholds) => {
  let index = 0;
  thresholds.every((limit, i) => {
    if (value < limit) return false;
    else index = i;
    return true;
  });

  return index;
};

const ChartDisease = ({ data, fieldId, chart, range, theme, chartEvents }) => {
  const yAxesLabel = "Accrued Hours";

  const { chartData } = useChartData(chart.group, range, fieldId);

  const chartOptions = useMemo(() => {
    const options = defaultChartOptions(
      config[chart.id].axis,
      config[chart.id].legend,
      range,
      chartEvents
    );
    return {
      ...options,
      scales: {
        ...options.scales,
        y: {
          beginAtZero: true,
          suggestedMax: 140,
          title: {
            display: true,
            text: yAxesLabel,
          },
        },
      },
      interaction: {
        mode: 'nearest',
        intersect: false
      },
      plugins: {
        ...options.plugins,
        tooltip: {
          ...options.tooltip,
          mode: "nearest",
          position: "average",
          callbacks: {
            label: (tooltipItem) => tooltipItem.formattedValue + " " + yAxesLabel,
          },
        },
      }
    };
  }, [chart.id, range]);

  const createDatasets = useMemo(() => {
    const thresholds = [0, 115, 125];
    const { palette: { primary, secondary, danger } } = theme;
    const colors = [primary.main, secondary.main, danger.main];
  
    const datasets = [];
    let datasetIndex = 0;
    let prevThresholdIndex = 0;
    chartData?.forEach((record, index) => {
      const { timestamp, value } = record;

      let currentThresholdIndex = thresholdIndex(value, thresholds);
      // check if is first value or if current value has a different thresholdIndex that the last item
      if (index === 0 || currentThresholdIndex !== prevThresholdIndex) {
        if (index !== 0) {
          // Add value to the current dataset.data array
          datasets[datasetIndex].data.push({ x: timestamp, y: value });
          // Increment the datasetIndex if this isn't the first value
          datasetIndex++;
        }

        datasets.push({
          label: "mildew-" + datasetIndex,
          fill: false,
          borderWidth: 2,
          borderColor: colors[currentThresholdIndex],
          backgroundColor: colors[currentThresholdIndex],
          pointRadius: 0,
          pointBorderColor: colors[currentThresholdIndex],
          pointBackgroundColor: colors[currentThresholdIndex],
          data: [],
        });
      }

      // Add value to the current dataset.data array
      datasets.forEach((set, loopIndex) => {
        set.data.push({
          x: timestamp,
          y: loopIndex === datasetIndex ? value : null,
        });
      });

      // Update the prevThresholdIndex to current index
      prevThresholdIndex = currentThresholdIndex;
    });
    return datasets;
  }, [theme, chartData]);

  const errors = createDatasets.reduce((count, item) => {
    return !item || (item.label && item.label.toLowerCase()) ? count++ : count;
  }, []);

  return errors ? null : (
    <Line ref={chartEvents.chartRef} data={{ datasets: createDatasets }} options={chartOptions} />
  );
};

export default ChartDisease;
