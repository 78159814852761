import React, { useMemo } from "react";
import AdminTable from "./AdminTable";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useClientsState } from "../../../context/clients";

export default function IrrigatorsTable({ user, activeClient, data, types }) {
  const history = useHistory();

  const clients = useClientsState();
  const clientMap = useMemo(
    () => new Map(clients.map((c) => [c.id, c.name])),
    [clients]
  );

  const irrigatorsWithClients = useMemo(() => {
    const irrigatorsWithTypes = data.map((irrigator) => {
      const type = types.find((t) => t.id === irrigator.irrigator_type_id);
      if (type === undefined) return irrigator;
      return { ...irrigator, type: type.name };
    });

    if (activeClient) {
      return irrigatorsWithTypes
        .filter((irrigator) => irrigator.client_id === activeClient.id)
        .map((irrigator) => ({ ...irrigator, client: activeClient.name }));
    }
    return irrigatorsWithTypes.map((irrigator) => ({
      ...irrigator,
      client: clientMap.get(irrigator.client_id),
    }));
  }, [data, types, clientMap, activeClient]);

  const config = useMemo(
    () => ({
      columns: [
        {
          title: "Name",
          field: "name",
        },
        {
          title: "Phone number",
          field: "phone_number",
        },
        {
          title: "Client",
          field: "client",
        },
        {
          title: "Type",
          field: "type",
        },
      ],
      data: irrigatorsWithClients,
      options: { pageSizeOptions: [5, 10, 20, 50] },
    }),
    [irrigatorsWithClients]
  );

  // const history = useHistory();
  const onCreate = () => history.push("/admin/irrigator");
  const onEdit = (id) => history.push(`/admin/irrigator/${id}`);
  // const onDelete = (record) => deleteLogger(user.token, record.id);

  return (
    <AdminTable
      title={<Typography variant="h2">Irrigators</Typography>}
      type="irrigator"
      config={config}
      // onRefresh={loadIrrigators}
      onCreate={onCreate}
      onEdit={onEdit}
      // onDelete={onDelete}
      deleteLabel="id"
    />
  );
}
