import React, { useState, useMemo } from "react";
import moment from "moment";
import { useAuthState } from "../../../../../../../context/auth";
import {
  DeleteSpray,
  UpdateSpray,
} from "../../../../../../../api/disease-risk";
import EditDeleteTable from "../../../../../../tables/EditDeleteTable";
import SpraysForm from "../../../../../../forms/SpraysForm";
import Table, {
  defaultTableOptions,
} from "../../../../../../tables/ChartTable";
import Card from "../..";
import {
  useChartData,
  useReloadChartData,
} from "../../../../../../../data/useChartData";

const MildewRisk = ({ chart, data, loading, tableData, field, range }) => {
  const { chartData: sprayData } = useChartData("sprays", range, field.id);
  const { reloadData } = useReloadChartData();
  const [sprayId, setSprayId] = useState(null);

  const user = useAuthState();
  const sprays = useMemo(() => {
    if (!Array.isArray(sprayData)) {
      return [];
    }
    return (
      (sprayData ?? []).length && {
        columns: [
          { title: "Timestamp", field: "timestamp_label" },
          { title: "Chemical Used", field: "chemical_used" },
          { title: "Fungicide", field: "fungicide" },
          { title: "Active Ingredient", field: "active_ingredient" },
        ],
        data: (sprayData ?? []).map((item) => ({
          ...item,
          timestamp_label: moment(item.timestamp).format("DD/MM/YY HH:mm"),
        })),
      }
    );
  }, [sprayData]);

  const record = useMemo(() => {
    if (!sprayId) return null;
    const records = sprays?.data?.filter((item) => item.id === sprayId) ?? [];
    const record = records.length && {
      ...records[0],
      chemical_name: records[0].chemical_used,
    };
    delete record.chemical_used;
    return record;
  }, [sprayData, sprayId]);

  const formValues = record && {
    ...record,
    timestamp: moment.tz(record.timestamp, field.timezone),
  };

  const handleUpdate = (values) => {
    const record = {
      ...values,
      timestamp: values.timestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    return UpdateSpray(user.token, user.client.id, field.id, sprayId, record);
  };

  const handleDelete = () =>
    DeleteSpray(user.token, user.client.id, field.id, sprayId);

  const handleRefresh = () => reloadData();

  return (
    <Card
      loading={loading}
      chart={chart}
      data={data}
      tables={[
        {
          title: "Sensor Data",
          display: false,
          table: (
            <Table
              key="0"
              {...{ ...tableData, range: range, options: defaultTableOptions }}
            />
          ),
        },
        {
          title: "Spray Activities",
          display: false,
          table: (
            <EditDeleteTable
              key="1"
              title="Spray Records"
              type="Spray Record"
              data={sprays}
              record={formValues}
              renderForm={(props) => (
                <SpraysForm
                  title="Amend the Spray Activity:"
                  timezone={field.timezone}
                  {...props}
                />
              )}
              setRecord={setSprayId}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              onRefresh={handleRefresh}
            />
          ),
        },
      ]}
    />
  );
};

export default MildewRisk;
