import React, { useMemo } from "react";
import Chart from "./Chart";
import chartsConfig from "../../constants/charts";
import { defaultChartOptions } from "../../helpers";
import { useMetricsState } from "../../context/metrics";

import { useChartData } from "../../data/useChartData";

const getStackedColor = function (index, theme) {
  const colors = [
    ["secondary", "main"],
    ["orange", "main"],
    ["pink", "main"],
    ["purple", "main"],
    ["brown", "main"],
    ["tertiary", "main"],
    ["primary", "main"],
    ["info", "main"],
    ["danger", "tint_700"],
  ];

  return theme.palette[colors[index][0]][colors[index][1]];
};

const ChartStackedLine = ({
  fieldId,
  field,
  chart,
  range,
  theme,
  chartEvents,
}) => {
  const { chartData } = useChartData(chart.group, range, fieldId, field);

  const metrics = useMetricsState();

  const chartConfig = useMemo(() => {
    const dataKeys = Object.keys(chartsConfig[chart.id].metrics).filter(
      (key) => key !== "timestamp"
    );

    return {
      datasets: dataKeys.map((ref, index) => {
        const metric = metrics.filter((item) => item.ref === ref);
        const metricsSettings = metric.length ? metric[0] : null;
        const { type, hidden, formatter, property } =
          chartsConfig[chart.id].metrics[ref] || null;
        const color = getStackedColor(index, theme);
        return {
          xAxisID: "x",
          label: metricsSettings?.short_name
            ? metricsSettings.short_name
            : "unknown",
          type,
          hidden,
          data:
            //divide y value by 1000 if chart is soil conductivity (id==="vic")
            chart.id === "vic"
              ? chartData?.map((record) => ({
                  x: record.timestamp,
                  y: formatter
                    ? formatter(record) / 1000
                    : parseFloat(record[property ?? ref]) / 1000,
                })) ?? []
              : chartData?.map((record) => ({
                  x: record.timestamp,
                  y: formatter
                    ? formatter(record)
                    : parseFloat(record[property ?? ref]),
                })) ?? [],
          fill: false,
          borderWidth: 2,
          borderColor: color,
          backgroundColor: color,
          pointRadius: 0,
          pointBorderColor: color,
          pointBackgroundColor: color,
          datalabels: { display: false },
        };
      }),
    };
  }, [chartData, theme, chart.id, metrics]);
  const options = useMemo(
    () =>
      defaultChartOptions(
        chartsConfig[chart.id].axis,
        chartsConfig[chart.id].legend,
        range,
        chartEvents
      ),
    [chart.id, range]
  );

  return (
    <Chart
      chartRef={chartEvents.chartRef}
      data={chartConfig}
      options={options}
    />
  );
};

export default ChartStackedLine;
