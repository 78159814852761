import { forwardRef, useCallback, useEffect, useMemo } from "react";
import MaterialTable, {
  Column,
  MaterialTableProps,
} from "@material-table/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useExtendedTheme } from "../../helpers/useExtendedTheme";
import { useLocalStorage } from "../../helpers/useLocalStorage";

const tableIcons = {
  Add: forwardRef<SVGSVGElement, any>((props, ref) => (
    <AddBox {...props} ref={ref} />
  )),
  Check: forwardRef<SVGSVGElement, any>((props, ref) => (
    <Check {...props} ref={ref} />
  )),
  Clear: forwardRef<SVGSVGElement, any>((props, ref) => (
    <Clear {...props} ref={ref} />
  )),
  Delete: forwardRef<SVGSVGElement, any>((props, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef<SVGSVGElement, any>((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef<SVGSVGElement, any>((props, ref) => (
    <Edit {...props} ref={ref} />
  )),
  Export: forwardRef<SVGSVGElement, any>((props, ref) => (
    <SaveAlt {...props} ref={ref} />
  )),
  Filter: forwardRef<SVGSVGElement, any>((props, ref) => (
    <FilterList {...props} ref={ref} />
  )),
  FirstPage: forwardRef<SVGSVGElement, any>((props, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef<SVGSVGElement, any>((props, ref) => (
    <LastPage {...props} ref={ref} />
  )),
  NextPage: forwardRef<SVGSVGElement, any>((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef<SVGSVGElement, any>((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef<SVGSVGElement, any>((props, ref) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef<SVGSVGElement, any>((props, ref) => (
    <Search {...props} ref={ref} />
  )),
  SortArrow: forwardRef<SVGSVGElement, any>((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef<SVGSVGElement, any>((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef<SVGSVGElement, any>((props, ref) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

const getColumnField = <O extends object>(
  column: Column<O>
): string | undefined => {
  if (typeof column.field === "string") {
    return column.field;
  }
  return undefined;
};

const getColumnFields = <O extends object>(columns?: Column<O>[]): string[] => {
  return (columns?.map(getColumnField).filter((field) => field !== undefined) ??
    []) as string[];
};

export default <O extends object>(
  props: MaterialTableProps<O> & {
    columnStorageKey?: string;
  }
) => {
  // NOTE: SOME COMPONENTS SET COLUMNS TO UNDEFINED (e.g. recommendations table)
  const theme = useExtendedTheme();

  const usedColumnFields = useMemo(
    () => getColumnFields(props.columns),
    [props.columns]
  );

  const [columnOrder, setColumnOrder] = useLocalStorage<string[]>(
    props.columnStorageKey || "columnOrder", // due to hook rules we have to put something here
    props.columnStorageKey ? usedColumnFields : [],
    true
  );

  // add any new columns to columnOrder, since we might add new columns in the future
  useEffect(() => {
    const usedSet = new Set(usedColumnFields);
    const storedSet = new Set(columnOrder);
    if (
      usedSet.size !== storedSet.size ||
      [...usedSet].some((field) => !storedSet.has(field))
    ) {
      setColumnOrder(usedColumnFields);
    }
  }, [usedColumnFields, columnOrder]);

  // sort columns by columnOrder
  const columns =
    props.columns &&
    [...props.columns].sort((a, b) => {
      const aIndex = columnOrder.indexOf(getColumnField(a) || "");
      const bIndex = columnOrder.indexOf(getColumnField(b) || "");
      return aIndex - bIndex;
    });

  const handleColumnDragged = useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      if (!props.columnStorageKey) return;
      const newColumnOrder = [...columnOrder];
      newColumnOrder.splice(sourceIndex, 1);
      newColumnOrder.splice(destinationIndex, 0, columnOrder[sourceIndex]);
      setColumnOrder(newColumnOrder);
    },
    [columnOrder]
  );

  return (
    <MaterialTable
      {...props}
      icons={tableIcons}
      columns={columns}
      options={{
        ...props.options,
        ...{
          headerStyle: {
            ...props.options?.headerStyle,
            backgroundColor: theme.palette.tertiary.main,
            color: theme.palette.common.white,
            lineHeight: "1.2rem",
            minWidth: 100,
          },
        },
      }}
      onColumnDragged={handleColumnDragged}
    />
  );
};
