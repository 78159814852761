import React, { useLayoutEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAnalyticsDispatch, fetchField } from "../../../context/analytics";
import { setVisibility, useSettingsDispatch } from "../../../context/settings";
import { useAuthState, refresh, useAuthDispatch } from "../../../context/auth";
import {
  MetricsProvider,
  useMetricsDispatch,
  fetchMetrics,
} from "../../../context/metrics";
import Layout from "./components/Layout";

const Analytics = () => (
  <MetricsProvider>
    <AnalyticsContent />
  </MetricsProvider>
);

const AnalyticsContent = () => {
  const [status, setStatus] = useState("idle");
  const user = useAuthState();
  const { client, token, tokenExpiry, settings } = user;
  const { fieldId } = useParams();
  const authDispatch = useAuthDispatch();
  const analyticsDispatch = useAnalyticsDispatch();
  const metricsDispatch = useMetricsDispatch();
  const settingsDispatch = useSettingsDispatch();

  const loadField = useCallback(async () => {
    setStatus("pending");
    try {
      await Promise.all([
        fetchMetrics(metricsDispatch, token),
        fetchField(
          analyticsDispatch,
          client.id,
          fieldId,
          client.directory,
          token
        )]);
      setVisibility(settingsDispatch, client.id, settings);
      setStatus("resolved");
    } catch (e) {
      setStatus("rejected");
    }
  }, [
    setStatus,
    analyticsDispatch,
    client.id,
    client.directory,
    token,
    settings,
    fieldId,
    settingsDispatch,
  ]);

  // Load field initially
  useLayoutEffect(() => {
    refresh(authDispatch, { token, tokenExpiry });
    loadField();
  }, [authDispatch, user, loadField, token, tokenExpiry, metricsDispatch]);

  return <Layout fieldId={fieldId} loading={[status, setStatus]} />;
};

export default Analytics;
