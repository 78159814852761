import React, { useMemo } from "react";
import Chart from "./Chart";
import config from "../../constants/charts";
import { defaultChartOptions } from "../../helpers";
import { useMetricsState } from "../../context/metrics";

import { getChartConfig } from "../../helpers/getChartConfig";
import { useChartData } from "../../data/useChartData";

const ChartAmbientClimate = ({ chart, fieldId, range, theme, chartEvents }) => {

  const { chartData } = useChartData(chart.group, range, fieldId);

  const metrics = useMetricsState();
  const chartConfig = useMemo(() => {
    const axis = [{ yAxisID: "y" }, { yAxisID: "y1" }];
    const initialConfig = getChartConfig(chartData, chart.id, theme, metrics);
    return {
      datasets: initialConfig.datasets.map((item, i) => {
        return {
          ...item,
          ...axis[i],
        };
      }),
    };
  }, [chartData, chart.id, metrics]);

  const chartOptions = useMemo(() => {
    const options = defaultChartOptions(
      config[chart.id].axis,
      config[chart.id].legend,
      range,
      chartEvents
    );
    return {
      ...options,
      parsing: false,
      scales: {
        ...options.scales,
        y: {
          type: "linear",
          display: true,
          position: "left",
          title: {
            display: true,
            text: "Temp.",
          },
          beginAtZero: true,
          suggestedMax: 60,
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",
          title: {
            display: true,
            text: "R.H. %",
          },
          beginAtZero: true,
          suggestedMax: 1,
          grid: {
            borderDash: [4, 10],
          },
        },
      },
    };
  }, [chart.id, range]);

  return <Chart chartRef={chartEvents.chartRef} data={chartConfig} options={chartOptions} />;
};

export default ChartAmbientClimate;
