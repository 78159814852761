import React, { ComponentProps } from "react";
import {
  makeStyles,
  Grid,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles({
  card: { minWidth: 200 },
  actions: { justifyContent: "center" },
});

const CardWarning: React.FC<{
  color?: ComponentProps<typeof WarningIcon>["color"];
  message: string;
  button?: { text: string; action: () => void };
}> = ({ color, message, button }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Card className={classes.card}>
        <CardContent>
          <WarningIcon color={color ? color : "disabled"} />
          <Typography component="h2" variant="h6" color="textSecondary">
            {message}
          </Typography>
        </CardContent>
        {button && button.text && button.action && (
          <CardActions className={classes.actions}>
            <Button variant="contained" size="small" onClick={button.action}>
              {button.text}
            </Button>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};

export default CardWarning;
