import { Box } from "@material-ui/core";
import { Condition } from "../../../../api/automation";
import { ComponentPropsWithoutRef } from "react";
import { ConditionChart } from "../ConditionChart";
import { ClimateMetric, SoilMetric } from "../../../../constants/metrics";

export const parseNumber = (s: string) => {
  const n = parseFloat(s);
  if (isNaN(n)) {
    return undefined;
  }
  return n;
};

type WithChildren = {
  children?: React.ReactNode;
};

export const Spacer: React.FC<WithChildren> = ({ children }) => (
  <Box ml={1} mr={1} component="span">
    {children}
  </Box>
);

type PartialCondition<C extends Condition> = Partial<C> & { type: C["type"] };
export type SubConditionInput<C extends Condition> = React.FC<{
  value: PartialCondition<C>;
  info: { token: string; clientId: number; fieldId: number | undefined };
  onChange: (c: C | undefined) => void;
  field?: ComponentPropsWithoutRef<typeof ConditionChart>["field"];
  onSelectMetric?: (value: SoilMetric | ClimateMetric | undefined) => void;
}>;
