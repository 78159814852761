import {
  Card,
  MenuItem,
  Select,
  Box,
  InputLabel,
  Button,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import {
  ClimateCondition,
  SoilCondition,
  Condition,
} from "../../../api/automation";

import { MetricConditionInput } from "./inputs/MetricCondition";
import { SoilClimateInput } from "./inputs/SoilCondition";
import { TimeConditionInput } from "./inputs/TimeCondition";
import { ConditionChart } from "./ConditionChart";
import { ClimateMetric, SoilMetric } from "../../../constants/metrics";

const ClimateConditionInput = MetricConditionInput<ClimateCondition>(
  "climate",
  ["temperature", "humidity", "vpd"]
);
const SoilConditionInput = MetricConditionInput<SoilCondition>("soil", [
  "soil_temp",
  "vic",
  "gains",
  "run_off",
  "rainfall",
  "ec_bag",
  "ph_bag",
]);

const conditionChanged = (
  oldCondition: Condition | undefined,
  newCondition: Condition
) => {
  switch (oldCondition?.type) {
    case "climate":
    case "soil":
      return (
        newCondition.type !== oldCondition.type ||
        oldCondition.metric !== newCondition.metric ||
        oldCondition.comparison !== newCondition.comparison ||
        oldCondition.threshold !== newCondition.threshold
      );
    case "soil_climate":
      return (
        newCondition.type !== "soil_climate" ||
        oldCondition.threshold_id !== newCondition.threshold_id ||
        oldCondition.threshold_manual !== newCondition.threshold_manual ||
        oldCondition.threshold_name !== newCondition.threshold_name ||
        oldCondition.comparison !== newCondition.comparison
      );
    case "time":
      return (
        newCondition.type !== "time" ||
        oldCondition.start !== newCondition.start ||
        oldCondition.end !== newCondition.end
      );
    case undefined:
      return true;
  }
};

const conditionLabels: Record<Condition["type"], string> = {
  climate: "Climate",
  soil: "Soil",
  soil_climate: "Root Zone Moisture",
  time: "Time",
  or: "Or",
  and: "And",
};

export const ConditionCard: React.FC<{
  value: Condition | undefined;
  user: { token: string; client: { id: number } };
  fieldId: number | undefined;
  field: any;
  onChange: (c: Condition | undefined) => void;
  onRemove: () => void;
}> = ({ value, user, fieldId, field, onChange, onRemove }) => {
  const [type, setType] = useState<Condition["type"] | undefined>(value?.type);
  const typeOptions: Condition["type"][] = ["climate", "soil", "soil_climate"];

  const [result, setResult] = useState<Condition | undefined>(value);
  const [selectedMetric, setSelectedMetric] = useState<
    SoilMetric | ClimateMetric
  >();
  const info = useMemo(
    () => ({
      token: user.token,
      clientId: user.client.id,
      fieldId,
    }),
    [user, fieldId]
  );

  useEffect(() => {
    if (type === "soil_climate") setSelectedMetric("moisture_content");
  }, [type]);

  return (
    <Box m={2}>
      <Card style={{ display: "flex", flexDirection: "row" }}>
        <Box p={2} width={fieldId && selectedMetric ? "45%" : "100%"}>
          {type !== "time" && (
            <>
              <InputLabel id="type" shrink>
                Type
              </InputLabel>
              <Box
                flexDirection="row"
                display="flex"
                justifyContent="space-between"
              >
                <Select
                  label="type"
                  value={type ?? ""}
                  onChange={(e) => {
                    setType(e.target.value as Condition["type"]);
                    setResult(undefined);
                  }}
                >
                  {typeOptions.map((t) => (
                    <MenuItem value={t} key={t}>
                      {conditionLabels[t]}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </>
          )}
          <Box mt={1}>
            {type === "climate" && (
              <ClimateConditionInput
                value={{ ...(value as ClimateCondition), type: "climate" }}
                info={info}
                onChange={setResult}
                field={field}
                onSelectMetric={(value) => {
                  setSelectedMetric(value);
                }}
              />
            )}
            {type === "soil" && (
              <SoilConditionInput
                value={{ ...(value as SoilCondition), type: "soil" }}
                info={info}
                onChange={setResult}
                field={field}
                onSelectMetric={(value) => {
                  setSelectedMetric(value);
                }}
              />
            )}
            {type === "soil_climate" && (
              <SoilClimateInput
                value={{ ...value, type: "soil_climate" }}
                info={info}
                onChange={setResult}
                field={field}
                onSelectMetric={(value) => {
                  setSelectedMetric(value);
                }}
              />
            )}
            {type === "time" && (
              <TimeConditionInput
                value={{ ...value, type: "time" }}
                info={info}
                onChange={setResult}
              />
            )}
          </Box>
          <Box mt={3}>
            <Button
              disabled={
                result === undefined || !conditionChanged(value, result)
              }
              onClick={() => onChange(result)}
              variant="contained"
              color="primary"
            >
              {value === undefined ? "Add" : "Update"}
            </Button>
            {type !== "time" && (
              <Button
                onClick={onRemove}
                variant="contained"
                style={{
                  background: "lightgrey",
                  marginLeft: 8,
                }}
              >
                Remove
              </Button>
            )}
          </Box>
        </Box>
        {fieldId && type && type !== "time" && selectedMetric && (
          <Box width={"45%"} height={250}>
            <ConditionChart field={field} metric={selectedMetric} />
          </Box>
        )}
      </Card>
    </Box>
  );
};
