import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
import CardWarning from "../cards/CardWarning";
import { useSettingsState } from "../../context/settings";
import ChartData from "./ChartData";
import { usePermissions } from "../../context/auth";
import config from "../../constants/charts";
import Irrimax from "../pages/Analytics/components/Card/components/Irrimax";

import { useDataLoader } from "../../data/DataLoader";
import { useAuthState } from "../../context/auth";

import "./ChartsList.css";

const ChartsList = ({ field, range }) => {
  const {
    chartsSettings: { visibility },
  } = useSettingsState();
  const permissions = usePermissions();

  const charts = useMemo(() => {
    return Object.keys(config)
      .filter((chart) => permissions.includes(config[chart].module_id))
      .map((chart) => {
        const settings = visibility?.filter(
          ({ field_id: id, ref }) => field.id === id && chart === ref
        );
        const { order, hidden } = settings[0] || { order: 999, hidden: 0 };
        return { ...config[chart], id: chart, ...{ order, hidden } };
      })
      .filter((chart) => !chart.hidden)
      .sort((a, b) => (a.order > b.order ? 1 : -1));
  }, [field.id, visibility, permissions]);

  const hasIrrimax = field.irrimax?.length;
  const hasCharts = charts?.length || hasIrrimax;

  const chartGroups = charts
    .map((item) => item.group)
    .filter((value, index, self) => self.indexOf(value) === index);
  if (chartGroups.includes("soil_climates")) {
    chartGroups.push("recommendations");
    chartGroups.push("squeezes");
  }
  if (chartGroups.includes("mildew_risk")) {
    chartGroups.push("sprays");
  }

  const user = useAuthState();
  useDataLoader({ user, field, groups: chartGroups });

  return visibility && hasCharts ? (
    <Grid container spacing={0} className="charts-grid">
      {hasIrrimax ? <Irrimax charts={field.irrimax} /> : null}
      {charts.map((chart, i) => (
        <ChartData key={i} chart={chart} range={range} />
      ))}
    </Grid>
  ) : (
    <CardWarning message="No charts found!" />
  );
};

export default ChartsList;
