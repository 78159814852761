type Form = {
  label: string;
  permissions: {
    admin: boolean;
    module: number;
  };
  submission: string;
};

const formTypes: Record<string, Form> = {
  spray: {
    label: "Fungicide Spray Activity",
    permissions: {
      admin: false,
      module: 1,
    },
    submission: "RecordSprayActivity",
  },
  agronomics: {
    label: "Record Agronomics",
    permissions: {
      admin: false,
      module: 2,
    },
    submission: "RecordAgronomics",
  },
  squeeze: {
    label: "Record Observation",
    permissions: {
      admin: false,
      module: 4,
    },
    submission: "RecordSqueeze",
  },
  recommendations: {
    label: "Create Recommendation",
    permissions: {
      admin: true,
      module: 4,
    },
    submission: "RecordRecommendation",
  },
  parameters: {
    label: "Set Moisture Parameter(s)",
    permissions: {
      admin: true,
      module: 4,
    },
    submission: "RecordParameters",
  },
};
export default formTypes;
