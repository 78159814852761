import React, { useEffect, useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import { Typography } from "@material-ui/core";
import { TextInput, DateTimeInput } from "../forms/FormInputs";
import { useMetricsState } from "../../context/metrics";

var decimalRegex = /^-?(\d+\.?\d*|\d*\.?\d+)$/;
// THIS NEEDS TO REFERENCE METRICS CONTEXT
const validate = (value, allValues, props, name) => {
  const limits = {
    ambient_temp: { min: -10, max: 60 },
    humidity: { min: 0, max: 100 },
    ag_ml_in: { min: null, max: null },
    ag_ml_out: { min: null, max: null },
    ag_run_off: { min: 0, max: null },
    ag_ph_in: { min: 0, max: 9 },
    ag_ph_out: { min: 0, max: 9 },
    ag_ph_bag: { min: 0, max: 9 },
    ag_ec_in: { min: 0, max: 4 },
    ag_ec_out: { min: 0, max: 8 },
    ag_ec_bag: { min: 0, max: 4 },
    ag_ec_sum: { min: 0, max: 12 },
    ag_water_cubes: { min: 0, max: 10000 },
    ag_moisture: { min: 0, max: 100 },
    ag_irrigations_per_day: { min: 0, max: null },
    ag_total_run_time: { min: 0, max: 25 },
    ag_litres_per_day: { min: 0, max: null },
    ag_drain_target: { min: 0, max: 100 },
    ag_ec_target: { min: 0, max: 8 },
    vpd: { min: 0, max: 42 },
    mildew: { min: 0, max: null },
    growing_hours: { min: null, max: null },
    chilling_hours: { min: null, max: null },
    moisture_content: { min: null, max: null },
    gains: { min: null, max: null },
    gains_neg: { min: null, max: null },
    gains_pos: { min: null, max: null },
    gains_daily: { min: null, max: null },
    vic: { min: null, max: null },
    soil_temp: { min: null, max: null },
    rainfall: { min: null, max: null },
    moisture_10: { min: null, max: null },
    moisture_20: { min: null, max: null },
    moisture_30: { min: null, max: null },
    moisture_40: { min: null, max: null },
    moisture_50: { min: null, max: null },
    moisture_60: { min: null, max: null },
    moisture_70: { min: null, max: null },
    moisture_80: { min: null, max: null },
    moisture_90: { min: null, max: null },
    vic_10: { min: null, max: null },
    vic_20: { min: null, max: null },
    vic_30: { min: null, max: null },
    vic_40: { min: null, max: null },
    vic_50: { min: null, max: null },
    vic_60: { min: null, max: null },
    vic_70: { min: null, max: null },
    vic_80: { min: null, max: null },
    vic_90: { min: null, max: null },
    soil_temp_10: { min: null, max: null },
    soil_temp_20: { min: null, max: null },
    soil_temp_30: { min: null, max: null },
    soil_temp_40: { min: null, max: null },
    soil_temp_50: { min: null, max: null },
    soil_temp_60: { min: null, max: null },
    soil_temp_70: { min: null, max: null },
    soil_temp_80: { min: null, max: null },
    soil_temp_90: { min: null, max: null },
    squeeze: { min: null, max: null },
    run_off: { min: 0, max: 100 },
    ph_in: { min: 0, max: 9 },
    ph_out: { min: 0, max: 9 },
    ph_bag: { min: 0, max: 9 },
    ec_in: { min: 0, max: 4 },
    ec_out: { min: 0, max: 8 },
    ec_bag: { min: 0, max: 4 },
    ec_sum: { min: 0, max: 12 },
  };

  if (value && typeof value == "string") {
    if (value.match(decimalRegex)) {
      if (
        !(
          (limits[name].min === null ||
            parseFloat(value) >= parseFloat(limits[name].min)) &&
          (limits[name].max === null ||
            parseFloat(value) <= parseFloat(limits[name].max))
        )
      ) {
        return `Min: ${limits[name].min},  Max: ${limits[name].max}`;
      }
    } else {
      return "Numeric values only.";
    }
  }
  return undefined;
};

const RecordAgronomics = ({
  title,
  timezone,
  handleSubmit,
  error,
  valid,
  pristine,
  submitting,
  isValid,
  isWaiting,
}) => {
  useEffect(() => {
    isValid(!pristine && valid && !submitting);
    isWaiting(submitting);
  });

  const allMetrics = useMetricsState();
  const metrics = useMemo(
    () =>
      allMetrics &&
      allMetrics.filter(
        (item) => item.module_id === 2 && !item.ref.includes("ec_sum")
      ),
    [allMetrics]
  );

  return (
    <div className="agronomics-form">
      {title && (
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
      )}
      <form noValidate onSubmit={handleSubmit}>
        <Field
          component={DateTimeInput}
          name="timestamp"
          id="timestamp"
          label="Timestamp"
          timezone={timezone}
        />
        {metrics.map((metric, index) => (
          <Field
            key={`agronomics-input-${metric.ref}`}
            component={TextInput}
            name={metric.ref}
            id={metric.ref}
            type={metric.ref}
            autoFocus={index === 0}
            label={metric.short_name}
            validate={validate}
          />
        ))}

        <Typography variant="body1" gutterBottom color="error">
          <b>{error}</b>
        </Typography>
      </form>
    </div>
  );
};

export default reduxForm({ form: "RecordAgronomics" })(RecordAgronomics);
