import React, { createContext, useReducer, useContext, Dispatch } from "react";
import { Client, listClients } from "../api/clients";
import { handleRequestError } from "../helpers";

type ClientDispatch = Dispatch<{
  type: "setClients";
  payload: Client[];
}>;

const ClientsStateContext = createContext<Client[]>([]);
const ClientsDispatchContext = createContext<ClientDispatch>(() => null);

function clientsReducer(
  _state: Client[],
  action: { type: "setClients"; payload: Client[] }
) {
  switch (action.type) {
    case "setClients": {
      return [...action.payload];
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const ClientsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(clientsReducer, []);
  return (
    <ClientsStateContext.Provider value={state}>
      <ClientsDispatchContext.Provider value={dispatch}>
        {children}
      </ClientsDispatchContext.Provider>
    </ClientsStateContext.Provider>
  );
};

export function useClientsState() {
  const context = useContext(ClientsStateContext);
  if (context === undefined) {
    throw new Error("useClientsState must be used within a ClientsProvider");
  }
  return context;
}

export function useClientsDispatch() {
  const context = useContext(ClientsDispatchContext);
  if (context === undefined) {
    throw new Error("useClientsDispatch must be used within a ClientsProvider");
  }
  return context;
}

export async function fetchClients(dispatch: ClientDispatch, token: string) {
  try {
    const response = await listClients(token);

    dispatch({ type: "setClients", payload: response.data });
  } catch (e) {
    handleRequestError(e, "Failed fetching clients: ");
  }
}
