import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import config from "../../constants/charts";
import { hexToRgb, lighten } from "@material-ui/core/styles";
import { calculateSmd, defaultChartOptions, rgbToRgba } from "../../helpers";
import Chart from "./Chart";
import { useMetricsState } from "../../context/metrics";

import { getChartConfig } from "../../helpers/getChartConfig";
import { useChartData } from "../../data/useChartData";
import { getMinChartValue } from "../../helpers/minChartValue";

const thresholdConfig = (key, label, data, theme) => {
  const styles = {
    field_capactiy: {
      color: {
        main: theme.palette.info.main,
        tint: theme.palette.info.light,
      },
      borderDash: [10, 5],
      fill: "end",
      backgroundColor: rgbToRgba(theme.palette.primary.light, 0.6),
    },
    refill_point: {
      fill: "start",
      color: {
        main: theme.palette.danger.main,
        tint: theme.palette.danger.main,
      },
      borderDash: [10, 5],
    },
    scab_trigger: {
      fill: null,
      color: {
        main: theme.palette.pink.main,
        tint: theme.palette.pink.main,
      },
      borderDash: [10, 5],
    },
    irrigation_trigger: {
      color: {
        main: theme.palette.purple.main,
        tint: theme.palette.purple.light,
      },
      fill: [
        {
          metricId: "field_capactiy",
          color: rgbToRgba(theme.palette.primary.light, 0.6),
        },
        {
          metricId: "refill_point",
          color: rgbToRgba(theme.palette.secondary.light, 0.6),
        },
      ],
      backgroundColor: rgbToRgba(theme.palette.primary.light, 0.6),
      borderDash: [10, 5],
    },
    pwp: {
      fill: null,
      color: {
        main: theme.palette.grey[400],
        tint: theme.palette.grey[200],
      },
      borderDash: [10, 5],
    },
    saturation: {
      fill: null,
      color: {
        main: theme.palette.brown.main,
        tint: theme.palette.brown.light,
      },
      borderDash: [10, 5],
    },
    smd_5: {
      fill: null,
      color: { main: hexToRgb("#06D59E"), tint: hexToRgb("#06D59E") },
      borderDash: [10, 5],
    },
    smd_10: {
      fill: null,
      color: { main: hexToRgb("#167547"), tint: hexToRgb("#167547") },
      borderDash: [5, 5],
    },
    smd_15: {
      fill: null,
      color: { main: hexToRgb("#1ea47e"), tint: hexToRgb("#1ea47e") },
      borderDash: [10, 5],
    },
    smd_20: {
      fill: null,
      color: { main: hexToRgb("#4fb56a"), tint: hexToRgb("#4fb56a") },
      borderDash: [5, 5],
    },
    smd_25: {
      fill: null,
      color: { main: hexToRgb("#82c250"), tint: hexToRgb("#82c250") },
      borderDash: [10, 5],
    },
    smd_30: {
      fill: null,
      color: { main: hexToRgb("#bacc30"), tint: hexToRgb("#bacc30") },
      borderDash: [5, 5],
    },
    smd_35: {
      fill: null,
      color: { main: hexToRgb("#f8cf10"), tint: hexToRgb("#f8cf10") },
      borderDash: [10, 5],
    },
    smd_40: {
      fill: null,
      color: { main: hexToRgb("#fbb800"), tint: hexToRgb("#fbb800") },
      borderDash: [5, 5],
    },
    smd_45: {
      fill: null,
      color: { main: hexToRgb("#fda000"), tint: hexToRgb("#fda000") },
      borderDash: [10, 5],
    },
    smd_50: {
      fill: null,
      color: { main: hexToRgb("#fc8700"), tint: hexToRgb("#fc8700") },
      borderDash: [5, 5],
    },
    smd_55: {
      fill: null,
      color: { main: hexToRgb("#fa6d00"), tint: hexToRgb("#fa6d00") },
      borderDash: [10, 5],
    },
    smd_60: {
      fill: null,
      color: { main: hexToRgb("#f55014"), tint: hexToRgb("#f55014") },
      borderDash: [5, 5],
    },
    smd_65: {
      fill: null,
      color: { main: hexToRgb("#ce3922"), tint: hexToRgb("#ce3922") },
      borderDash: [10, 5],
    },
    smd_70: {
      fill: null,
      color: { main: hexToRgb("#a52726"), tint: hexToRgb("#a52726") },
      borderDash: [5, 5],
    },
    smd_75: {
      fill: null,
      color: { main: hexToRgb("#7b1a23"), tint: hexToRgb("#7b1a23") },
      borderDash: [10, 5],
    },
    smd_80: {
      fill: null,
      color: { main: hexToRgb("#52111c"), tint: hexToRgb("#52111c") },
      borderDash: [5, 5],
    },
    smd_85: {
      fill: null,
      color: { main: hexToRgb("#2b090f"), tint: hexToRgb("#2b090f") },
      borderDash: [10, 5],
    },

    full_point: {
      color: {
        main: theme.palette.info.dark,
        tint: theme.palette.info.light,
      },
      borderDash: [10, 5],
      fill: "end",
      backgroundColor: rgbToRgba(theme.palette.primary.light, 0.6),
    },
    irri_upper: {
      fill: null,
      color: {
        main: theme.palette.info.main,
        tint: lighten(theme.palette.info.dark, 0.25),
      },
      borderDash: [10, 5],
    },
    med_squeeeze: {
      color: {
        main: theme.palette.secondary.dark,
        tint: theme.palette.secondary.light,
      },
      borderDash: [10, 5],
      fill: { metricId: "irri_lower" },
      backgroundColor: rgbToRgba(theme.palette.secondary.light, 0.6),
    },
    hard_squeeeze: {
      fill: null,
      color: {
        main: theme.palette.orange.main,
        tint: theme.palette.orange.light,
      },
      borderDash: [10, 5],
    },
    irri_lower: {
      fill: "start",
      color: {
        main: theme.palette.danger.main,
        tint: theme.palette.danger.light,
      },
      borderDash: [10, 5],
    },
    high_et_days: {
      color: {
        main: theme.palette.primary.main,
        tint: theme.palette.primary.light,
      },
      borderDash: [10, 5],
      fill: [{ metricId: "full_point" }, { metricId: "med_squeeeze" }],
      backgroundColor: rgbToRgba(theme.palette.info.light, 0.6),
    },
    low_et_days: {
      fill: null,
      color: {
        main: theme.palette.orange.main,
        tint: theme.palette.orange.light,
      },
      borderDash: [10, 5],
    },
  };
  const hasStyles = Object.keys(styles).includes(key);
  const defaultColor = "rgba(55,55,55,0.5)";
  const color = (color, intensity) =>
    hasStyles ? rgbToRgba(color, intensity) : defaultColor;
  const config = {
    metricId: key,
    label: label,
    type: "line",
    data: data,
    fill: hasStyles ? styles[key].fill : null,
    borderWidth: 2,
    backgroundColor: color(styles[key].color.tint, 0.6),
    pointBackgroundColor: color(styles[key].color.tint, 0.6),
    borderColor: color(styles[key].color.main, 1),
    pointBorderColor: color(styles[key].color.main, 1),
    pointRadius: 0,
    steppedLine: true,
    borderDash: hasStyles ? styles[key].borderDash : [10, 5],
    tension: 0,
  };
  return hasStyles && styles[key].betweenLines
    ? { ...config, betweenLines: styles[key].betweenLines }
    : config;
};

const ChartSoilClimate = ({
  data,
  chart,
  fieldId,
  chartEvents,
  field: { fieldType, timezone },
  range,
  theme,
}) => {
  const metrics = useMetricsState();

  const {
    // moisture_content,
    squeeze,
    params: { events, settings: params },
  } = data;

  const { chartData: types } = useChartData("parameter_types", range, fieldId);
  const { chartData } = useChartData(chart.group, range, fieldId);

  const [minParamValue, setMinParamValue] = useState(Infinity);
  const [defaultMinValue, setDefaultMinValue] = useState(Infinity);

  const resetScale = (chart) => {
    chart.options.scales.y.min = defaultMinValue;
    chart.update();
  };

  chartEvents.onZoomComplete = (evt) => {
    if (evt.chart.isZoomedOrPanned()) {
      const minX = moment.unix(evt.chart.scales.x.min / 1000);
      const maxX = moment.unix(evt.chart.scales.x.max / 1000);
      evt.chart.options.scales.y.min =
        getMinChartValue(minX, maxX, chartConfig.datasets) ?? minParamValue;
      evt.chart.update();
      evt.chart.resetScale = resetScale;
    } else {
      resetScale();
    }
  };

  const chartConfig = useMemo(() => {
    const initialConfig = getChartConfig(chartData, chart.id, theme, metrics);
    const squeezeConfig = getChartConfig(squeeze, chart.id, theme, metrics);

    // Get min value of parameters to make sure chart always includes them in view
    let minValue = Infinity;

    const paramsConfig = Object.keys(params).map((key) => {
      const type = types.filter((item) => item.slug === key);
      const data = params[key];
      // Fix issue preventing changes to params being shown on charts due to missing x values
      for (var i = 0; i < data.length; i++) {
        if (
          !data[i].x &&
          i - 1 < data.length &&
          i !== 0 &&
          !moment().isSame(data[i + 1].x, "day")
        ) {
          data[i].x = moment(data[i + 1].x).subtract(1, "s");
        }
      }

      var p = [];
      data.forEach((param) => {
        if (
          !p.find((d) => {
            if (d.y < minValue) {
              minValue = parseInt(d.y);
            }
            return d.y === param.y && param.x && param.x.isSame(d.x, "minute");
          })
        ) {
          p.push(param);
        }
      });

      return type[0] && thresholdConfig(key, type[0].name, p, theme);
    });

    setMinParamValue(minValue);

    return (
      initialConfig &&
      paramsConfig && {
        ...initialConfig,
        ...squeezeConfig,
        datasets: [
          ...initialConfig.datasets,
          ...squeezeConfig.datasets,
          ...paramsConfig,
        ],
      }
    );
  }, [chartData, squeeze, params, theme, chart.id, types, metrics]);

  // run function that removes any element with id  starting with comment- (id for annotaions) when component is unmounted
  useEffect(() => {
    // return function to run when component is unmounted
    return () => {
      // get element that has id starting with comment
      const commentElements = document.querySelectorAll(`[id^="comment-"]`);
      // remove comment elements from document
      commentElements.forEach((item) => {
        item.remove();
      });
    };
  }, []);

  const options = useMemo(() => {
    const defaultOptions = defaultChartOptions(
      config[chart.id].axis,
      config[chart.id].legend,
      range,
      chartEvents
    );
    const annotationSettings = {};
    events.forEach((item, i) => {
      annotationSettings[`event-${i}`] = {
        drawTime: "afterDatasetsDraw",
        id: `event-${i}`,
        type: "line",
        mode: "vertical",
        scaleID: "x",
        value: item.x.toString(),
        borderColor: "#555",
        borderWidth: 2,
        borderDash: [2, 2],
        label: {
          position: "start",
          yAdjust: 5,
          font: {
            size: 10,
          },
          backgroundColor: "#fff",
          color: "#555",
          content: item.label,
          enabled: true,
        },
      };
    });

    //checks if annotation is availabel for the specific data and display it
    chartData?.forEach((d, i) => {
      if (d.notation) {
        annotationSettings[`comment-${i}`] = {
          drawTime: "afterDatasetsDraw",
          id: `comment-${i}`,
          type: "point",
          mode: "vertical",
          scaleID: "x",
          xValue: d.timestamp,
          yValue: d.moisture_content,
          xAdjust: 0,
          yAdjust: 0,
          content: d.notation,
          display: true,
          borderWidth: 0,
          borderRadius: 0,
          pointStyle: "circle",
          backgroundColor: "red",
          radius: 10,
          callout: { enabled: true, size: 10 },
          enter(context, e) {
            var tag = document.createElement("p");
            tag.id = `comment-${i}`;
            tag.className = "annotation-tooltip";
            tag.style = `top: ${e.native.clientY}px; left: ${e.native.clientX}px`;
            var text = document.createTextNode(d.notation);
            tag.appendChild(text);
            var element = document.getElementById("root");
            element.appendChild(tag);
          },
          leave(context, e) {
            var element = document.getElementById(`comment-${i}`);
            if (element) {
              element.remove();
            }
          },
          click(context, e) {
            chartEvents.triggerAnnotationModal(d.id, d.notation, d.timestamp);
          },
        };
      }
    });

    // Calculate min value of y axis
    if (minParamValue > 0 && minParamValue !== Infinity) {
      defaultOptions.scales.y.min = minParamValue;
    }
    if (chartConfig.datasets.length > 1) {
      var minVal = chartConfig.datasets[0]?.helpers?.minValue;
      if (minVal !== undefined && minVal < defaultOptions.scales.y.min) {
        // don't go negative if min value is 0-1
        defaultOptions.scales.y.min =
          minVal < 1 && minVal >= 0 ? 0 : minVal - 1;
      }
    }
    setDefaultMinValue(defaultOptions.scales.y.min);

    return {
      ...defaultOptions,
      plugins: {
        ...defaultOptions.plugins,
        annotation: { annotations: annotationSettings },
        datalabels: {
          display: false,
          color: "#333",
          backgroundColor: ({ dataset }) => dataset.backgroundColor,
          font: {
            size: 10,
            weight: "bold",
          },
          borderRadius: 6,
          formatter: (value) => value.label,
        },

        tooltip: {
          ...defaultOptions.tooltip,
          filter: (tooltipItem) => !tooltipItem.datasetIndex,
          callbacks: {
            footer: (tooltipItem) => {
              if (fieldType !== "soil" || !tooltipItem[0]) return null;
              const moistureDate = tooltipItem[0].parsed.x;
              const field_capacity = tooltipItem[0].chart.data.datasets.find(
                (i) => i.metricId === "field_capactiy"
              );

              const moisture =
                moistureDate && timezone
                  ? moment.tz(moistureDate, timezone)
                  : null;
              const data = {
                moisture: tooltipItem[0].parsed.y,
                field_capacity:
                  field_capacity?.data.length > 1
                    ? field_capacity?.data
                        .reverse()
                        .find(({ x }) => x.isBefore(moisture))?.y
                    : field_capacity?.data[0].y,
              };
              const smd =
                data && calculateSmd(data.moisture, data.field_capacity);

              return smd && !isNaN(smd) ? `SMD: ${smd}` : null;
            },
          },
        },
      },
    };
  }, [chart.id, chartData, range, events, fieldType, timezone, minParamValue]);

  return (
    <Chart
      chartRef={chartEvents.chartRef}
      data={chartConfig}
      options={options}
      plugins={[ChartDataLabels]}
    />
  );
};

export default ChartSoilClimate;
