import React, { useMemo } from "react";
import moment from "moment";
import { useAnalyticsState } from "../../context/analytics";
import ChartDefault from "./ChartDefault";
import ChartAgronomics from "./ChartAgronomics";
import ChartAmbientClimate from "./ChartAmbientClimate";
import ChartAdvisoryLine from "./ChartAdvisoryLine";
import ChartDisease from "./ChartDisease";
import ChartSoilClimate from "./ChartSoilClimate";
import theme from "../../constants/theme";
import ChartMoistureGains from "./ChartMoistureGains";
import ChartStackedLine from "./ChartStackedLine";
import ChartRunOff from "./ChartRunOff";
import ChartRainfall from "./ChartRainfall";

const ChartSelection = ({
  chart,
  data,
  selectedField,
  chartEvents,
  selectedResolution,
}) => {
  const {
    field,
    range: { start_date, end_date },
  } = useAnalyticsState();

  const f = selectedField || field;

  const range = useMemo(() => {
    if (!f?.timezone) {
      return { start_date, end_date };
    }
    return {
      start_date: moment(
        start_date.clone().tz(f.timezone).format("YYYY-MM-DD HH:mm:ss")
      ),
      end_date: moment(
        end_date.clone().tz(f.timezone).format("YYYY-MM-DD HH:mm:ss")
      ),
    };
  }, [end_date, start_date, f?.timezone]);

  if (!chart?.id || !range) return null;

  chartEvents.setRange(range);

  switch (chart.id) {
    case "ambient_climate":
      return (
        <ChartAmbientClimate
          data={data}
          fieldId={f.id}
          chartEvents={chartEvents}
          chart={chart}
          range={range}
          theme={theme}
        />
      );

    case "agronomics_overview":
      return (
        <ChartAgronomics
          data={data}
          fieldId={f.id}
          chartEvents={chartEvents}
          chart={chart}
          range={range}
          theme={theme}
        />
      );

    case "vpd":
      return (
        <ChartAdvisoryLine
          data={data}
          fieldId={f.id}
          chartEvents={chartEvents}
          chart={chart}
          range={range}
          theme={theme}
          thresholds={{
            low: {
              label: "Low",
              value: 5,
              color: theme.palette.info,
              fill: "start",
            },
            optimum: {
              label: "Optimum",
              value: 15,
              color: theme.palette.primary,
              fill: 1,
            },
            excess: {
              label: "Excess",
              value: 15,
              color: theme.palette.danger,
              fill: "end",
            },
          }}
          tooltip="VPD"
        />
      );

    case "mildew":
      return (
        <ChartDisease
          data={data}
          fieldId={f.id}
          chartEvents={chartEvents}
          chart={chart}
          range={range}
          theme={theme}
        />
      );

    case "soil_climate":
      return (
        <ChartSoilClimate
          data={data}
          fieldId={f.id}
          chartEvents={chartEvents}
          chart={chart}
          field={{ fieldType: f.field_type, timezone: f.timezone }}
          range={range}
          theme={theme}
        />
      );

    case "soil_moisture_gains":
      return (
        <ChartMoistureGains
          data={data}
          fieldId={f.id}
          chartEvents={chartEvents}
          chart={chart}
          range={range}
          theme={theme}
          selectedResolution={selectedResolution}
        />
      );

    case "run_off":
      return (
        <ChartRunOff
          data={data}
          fieldId={f.id}
          chartEvents={chartEvents}
          chart={chart}
          range={range}
          theme={theme}
          selectedResolution={selectedResolution}
        />
      );

    case "rainfall":
      return (
        <ChartRainfall
          data={data}
          fieldId={f.id}
          field={f}
          chartEvents={chartEvents}
          chart={chart}
          range={range}
          theme={theme}
          selectedResolution={selectedResolution}
        />
      );

    default:
      if (["soil_moisture", "vic", "soil_temp"].includes(chart.id)) {
        return (
          <ChartStackedLine
            data={data}
            fieldId={f.id}
            field={f}
            chartEvents={chartEvents}
            chart={chart}
            range={range}
            theme={theme}
          />
        );
      }
      return (
        <ChartDefault
          data={data}
          fieldId={f.id}
          field={f}
          chartEvents={chartEvents}
          chart={chart}
          range={range}
          theme={theme}
          selectedResolution={selectedResolution}
        />
      );
  }
};

export default ChartSelection;
