import React, { useMemo } from "react";
import { makeStyles, Typography, Fade, useTheme } from "@material-ui/core";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import CardLayout from "../CardLayout";
import { useChartDropdownActions, useIrrimaxCharts, useChartEvents } from "../../hooks";

const useStyles = makeStyles((theme) => ({
  chart: {
    height: "100%",
    maxWidth: "100%",
  },
}));

export default ({ charts }) => {
  const classes = useStyles();
  const theme = useTheme();
  const chartEvents = useChartEvents();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const defaultActions = useChartDropdownActions();
  const { irrimaxCharts, irrimaxChartActions } = useIrrimaxCharts(charts);
  const options = useMemo(
    () => [{ ...defaultActions }, { ...irrimaxChartActions }],
    [defaultActions, irrimaxChartActions]
  );

  return (
    <CardLayout title="Irrigation" options={options} chartEvents={chartEvents} isStaticImage={true}>
      {irrimaxCharts.map((chart, i) => {
        return (
          <Fade
            key={"irrimax-" + i}
            in={chart.display}
            timeout={transitionDuration}
            style={{ transitionDelay: `${transitionDuration.exit}ms` }}
            unmountOnExit
          >
            <>
              <Typography variant="h5"> {chart.name} </Typography>
              <TransformWrapper alignmentAnimation={{ sizeX: 0, sizeY: 0 }} pan = {{paddingSize: 0}} wheel={{ activationKeys: ["Shift"], step: 0.1 }} panning={{velocityDisabled: true}}>
                
                {(transformFunctions) => {
                  chartEvents.setImageFcns(transformFunctions);
                  return (
                    <React.Fragment>
                      <TransformComponent>
                        <img
                          src={chart.link}
                          alt={chart.name}
                          className={classes.chart}
                        />
                      </TransformComponent>
                    </React.Fragment>
                  )
                }}
              </TransformWrapper>
            </>
          </Fade>
        );
      })}
    </CardLayout>
  );
};
