import { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Card,
  Box,
  TextField,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { useAuthState } from "../../../context/auth";
import { useStyles } from "./Admin";
import { handleRequestError } from "../../../helpers";
import Loader from "../../elements/Loader";
import {
  addIrrigatorType,
  updateIrrigatorType,
  getIrrigatorType,
} from "../../../api/irrigators";

import Headline from "../../elements/Headline";
import FormButton from "../../forms/FormButton";

export const ManageIrrigatorType = ({ id, setIsLoading }) => {
  const history = useHistory();
  const user = useAuthState();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [irrigatorType, setIrrigatorType] = useState(null);

  useEffect(() => {
    setIsLoading(loading);
  }, [setIsLoading, loading]);

  const loadIrrigatorType = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getIrrigatorType(user.token, id);
      setIrrigatorType(response.data);
      setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching irrigator command: ");
      setLoading(false);
    }
  }, [user.token, id]);

  useEffect(() => {
    if (id) loadIrrigatorType();
  }, [loadIrrigatorType]);

  const [name, setName] = useState();

  useEffect(() => {
    if (irrigatorType?.name) {
      setName(irrigatorType.name);
    }
  }, [irrigatorType]);

  const changed = useMemo(() => {
    if (!name) return false;
    return name !== irrigatorType?.name;
  }, [name, irrigatorType]);

  const missingRequiredInfo = useMemo(() => {
    return !name;
  }, [name]);

  const [waiting, setWaiting] = useState(false);

  const onSubmit = useCallback(async () => {
    if (!missingRequiredInfo && changed) {
      setWaiting(true);
      const response = id
        ? await updateIrrigatorType(user.token, id, {
            name,
          })
        : await addIrrigatorType(user.token, {
            name,
          });
      if (response.status === 200) {
        setIrrigatorType({
          ...irrigatorType,

          name,
        });
      }
      setWaiting(false);
      if (!id) history.push(`/admin`);
    }
  }, [name, changed, id, user.token]);

  return (
    <Loader
      active={id ? loading : false}
      showChildren={false}
      message={`Loading Irrigator type...`}
    >
      <Headline
        headline={
          id
            ? `Edit Irrigator Type: ${irrigatorType?.name}`
            : "Add Irrigator Type"
        }
        headlineVariant="h1"
        divider={true}
      >
        <FormButton
          text={id ? "Update" : "Add"}
          disabled={!changed || waiting || missingRequiredInfo}
          handleClick={() => onSubmit()}
          variant="contained"
          color="primary"
          waiting={waiting}
          waitingText={id ? "Updating" : "Adding"}
        />
      </Headline>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12} lg={7}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.subTitle}
              >
                DETAILS:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ marginTop: 10 }}>
                <Box p={2} pt={1}>
                  <InputLabel id={`select-${id}-label`}>Name</InputLabel>
                  <TextField
                    style={{ width: "100%" }}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    error={!name}
                  ></TextField>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
};
