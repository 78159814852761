import React, { useState } from "react";
import {
  makeStyles,
  Card as MuiCard,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import Modal from "../../../../../../elements/Modal";
import moment from "moment";

import { useAuthState } from "../../../../../../../context/auth";
import { addRootZoneMoistureAnnotations } from "../../../../../../../api/soil-climate";
import { handleRequestError, triggerToast } from "../../../../../../../helpers";
import { useReloadChartData } from "../../../../../../../data/useChartData";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  close: {
    marginLeft: "10px",
  }
}));

const AnnotationModal = ({
  modalOpen,
  setModalOpen,
  data,
  chartEvents
}) => {
  const user = useAuthState();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [commentValue, setComment] = useState("");
  const [timestampValue, setTimestamp] = useState(-1);

  //remove onClick event and close modal
  const closeModal = () => {
    setModalOpen(false);
    chartEvents.chartRef.current._options["onClick"] = null;
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  chartEvents.onAnnotationTrigger((id, annotation, timestamp) => {
    if (!user.isAdmin) { return; }
    setComment(annotation);
    setTimestamp(timestamp);
    setModalOpen(true);
    var elements = document.getElementsByClassName(`annotation-tooltip`);
    if (elements) {
      for (var i = 0; i < elements.length; i++) {
        if (elements[0].remove) { elements[0].remove(); }
      }
    }
  })

  const { reloadData } = useReloadChartData();
  const handleModalSubmission = async (e) => {
    //format date and time
    const fieldId = data.id;
    const timestamp = moment
      .tz(moment(timestampValue).format("YYYY-MM-DD HH:mm:ss"), data.timezone)
      .utc()
      .format("DDMMYYYYHHmmss");
    const annotation = commentValue;
    //api call to store annotation
    try {
      const values = { fieldId, timestamp, annotation };
      const response = await addRootZoneMoistureAnnotations(
        user.token,
        user.client.id,
        values
      );
      reloadData();
      dispatch(
        triggerToast(response.message ?? "Annotation saved successfully", {
          variant: "success",
        })
      );
      //close modal
      setModalOpen(!modalOpen);
    } catch (e) {
      handleRequestError(e, `Failed to save annotation: `);
    }
    //reset comment value
    setComment("");
    //remove onclick event
    chartEvents.chartRef.current._options["onClick"] = null;
  };

  return (
    <Modal open={modalOpen} closeable={false}>
      <MuiCard>
        <CardContent className={classes.modalHeader}>
          <Typography variant="h5" className={classes.modalTitle}>
            Add annotation
          </Typography>
          <br />
          <TextField
            id="name-input"
            name="Comment"
            label="Enter a comment"
            type="text"
            defaultValue={commentValue}
            fullWidth
            variant="filled"
            icon="ForumIcon"
            onChange={handleCommentChange}
          />
          <br />
          <br />
          <Button
            size="medium"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => handleModalSubmission()}
          >
            Save
          </Button>
          <Button
            size="medium"
            variant="contained"
            className={classes.close}
            onClick={() => closeModal()}
          >
            Close
          </Button>
        </CardContent>
      </MuiCard>
    </Modal>
  );
};

export default AnnotationModal;
