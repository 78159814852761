import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import ViewListIcon from "@material-ui/icons/ViewList";
import ShowChartIcon from "@material-ui/icons/ShowChart";

export function useChartEvents() {
  const chartRef = useRef(null);
  let range = {};
  let zoom = 0;
  let imageFunctions = null;

  let annotationCallback = null;

  return {
    onAnnotationTrigger: (callback) => {
      annotationCallback = callback;
    },
    setImageFcns: (a) => {
      imageFunctions = a;
    },
    setRange: (newRange) => {
      range = newRange;
    },
    resetZoom: () => {
      if (chartRef && chartRef.current) {
        chartRef.current.zoomScale("x", {
          min: moment(range.start_date),
          max: moment(range.end_date).add(1, "days"),
        });
        if (chartRef.current.resetScale) {
          chartRef.current.resetScale(chartRef.current);
        }
      }
      if (imageFunctions && imageFunctions.resetTransform) {
        imageFunctions.resetTransform();
      }
    },
    zoomOut: () => {
      if (chartRef && chartRef.current) {
        if (zoom > 0) {
          zoom = 0;
        }
        zoom = zoom - 1;
        chartRef.current.zoom({ x: 1 + zoom / 10 });
      }
      if (imageFunctions && imageFunctions.zoomOut) {
        imageFunctions.zoomOut();
      }
    },
    zoomIn: () => {
      if (chartRef && chartRef.current) {
        if (zoom < 0) {
          zoom = 0;
        }
        zoom = zoom + 1;
        chartRef.current.zoom({ x: 1 + zoom / 10 });
      }
      if (imageFunctions && imageFunctions.zoomIn) {
        imageFunctions.zoomIn();
      }
    },
    onZoomComplete: () => {},
    chartRef,
    triggerAnnotationModal: (field_id, annotation, timestamp) => {
      if (annotationCallback) {
        annotationCallback(field_id, annotation, timestamp);
      }
    },
  };
}

export function useChartWidth(options) {
  return options
    ?.find(({ headline }) => headline === "Actions")
    ?.items.find(({ meta: { width } }) => width)?.meta?.width;
}

export function useChartDropdownActionWidth() {
  const [chartWidth, setChartWidth] = useState(6);
  const handleToggleWidth = () => setChartWidth(chartWidth === 12 ? 6 : 12);

  return {
    type: "link",
    icon: <AspectRatioIcon />,
    label: "Resize card",
    onClick: handleToggleWidth,
    meta: {
      width: chartWidth,
    },
  };
}

export function useChartDropdownActions() {
  const resize = useChartDropdownActionWidth();
  return {
    headline: "Actions",
    items: [{ ...resize }],
  };
}

const cardTableState = {};

export function useChartTables(tableList, chart, data) {
  const [tables, updateTables] = useState([]);

  const key = `${chart.id}-${data?.length}`;
  useEffect(() => {
    if (data) {
      if (cardTableState[key] && cardTableState[key].length > 0) {
        cardTableState[key].forEach((item) => {
          const index = tableList.findIndex((i) => i.title === item.title);
          if (index > -1) {
            tableList[index].display = item.display;
          }
        });
      } else {
        cardTableState[key] = tableList;
      }
    }
    updateTables(tableList);
  }, [tableList]);

  const toggleTableDisplay = (index) => {
    let newTables = [...tables];
    if (newTables[index]) {
      newTables[index].display = !newTables[index].display;
      updateTables(newTables);
      cardTableState[key] = newTables;
    }
  };

  const tableActions = {
    icon: <ViewListIcon />,
    headline: "Table Display",
    items: tables.map(({ display, title }, i) => ({
      type: "checkbox",
      label: title,
      onClick: () => toggleTableDisplay(i),
      att: { checked: display },
    })),
  };

  return {
    tables,
    tableActions,
    toggleTableDisplay,
  };
}

export function useIrrimaxCharts(charts) {
  const [irrimaxCharts, setIrrimaxCharts] = useState([]);

  useEffect(() => {
    setIrrimaxCharts(
      charts.map((item, i) => ({
        ...item,
        display: typeof item.display === "undefined" ? !i : false,
      }))
    );
  }, [charts]);

  const toggleIrrimaxChartDisplay = (index) => {
    let newCharts = [...irrimaxCharts];
    if (newCharts[index]) {
      newCharts[index].display = !newCharts[index].display;
      setIrrimaxCharts(newCharts);
    }
  };

  const irrimaxChartActions = {
    icon: <ShowChartIcon />,
    headline: "Chart Display",
    items: irrimaxCharts.map(({ display, name }, i) => ({
      type: "checkbox",
      label: name,
      onClick: () => toggleIrrimaxChartDisplay(i),
      att: { checked: display },
    })),
  };

  return {
    irrimaxCharts,
    irrimaxChartActions,
    toggleIrrimaxChartDisplay,
  };
}
