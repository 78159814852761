import api, { ServerResponse } from "./agritech";

export type ModuleInfo = {
  id: number;
  name: string;
  slug: string;
};
async function listModules(token: string) {
  const response = await api.get<ServerResponse<ModuleInfo[]>>(`/modules`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

export { listModules };
