import { useState } from "react";
import { syncConfigWithProd } from "../../../api/admin";
import FormButton from "../../forms/FormButton";
import { UserState } from "../../../context/auth";

export const SyncConfigButton = ({ user }: { user: UserState }) => {
  const [loading, setLoading] = useState(false);
  const sync = () => {
    if (!user.token) return;
    if (
      window.confirm(
        "Are you sure you want to overwrite the staging configuration? This will replace all field settings with those from the live site."
      )
    ) {
      setLoading(true);
      void syncConfigWithProd(user.token).then(() => setLoading(false));
    }
  };
  return (
    <FormButton
      text="Sync with live"
      handleClick={sync}
      variant="contained"
      waiting={loading}
      disabled={!user.token}
    />
  );
};
