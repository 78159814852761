import Layout from "../layout/Layout";
import { useEffect, useState, useCallback, useMemo } from "react";
import * as Sentry from "@sentry/react";
import {
  useFieldsState,
  useFieldsDispatch,
  fetchFields,
} from "../../context/fields";
import { fetchField, useAnalyticsDispatch } from "../../context/analytics";
import { setVisibility } from "../../context/settings";
import { useAuthState, useAuthDispatch } from "../../context/auth";
import { useSettingsDispatch } from "../../context/settings";
import { refresh } from "../../context/auth";

import { useParams } from "react-router-dom";
import Card from "../cards/Card";
import { SelectInput, TextInput } from "../forms/FormInputs";
import { ConditionCard } from "./automation/ConditionCard";
import { ActionCard } from "./automation/ActionCard";
import {
  Button,
  Typography,
  Divider,
  Box,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import FormButton from "../forms/FormButton";
import {
  addAutomation,
  getAutomation,
  updateAutomation,
} from "../../api/automation";
import { useHistory } from "react-router-dom";
import Loader from "../elements/Loader";
import CardWarning from "../cards/CardWarning";
import { AutomationLogTable } from "../tables/AutomationLogTable";

import {
  MetricsProvider,
  fetchMetrics,
  useMetricsDispatch,
} from "../../context/metrics";

const AutomationConfig = () => {
  const { id } = useParams();
  const editing = id !== "new";

  const user = useAuthState();
  const [fieldsLoading, setFieldsLoading] = useState(false);
  const [automationLoading, setAutomationLoading] = useState(false);
  const [automationError, setAutomationError] = useState(false);
  const isLoading = fieldsLoading || automationLoading;
  const history = useHistory();

  const { fields: allFields } = useFieldsState();

  const [fieldId, setFieldId] = useState("");

  const fieldsDispatch = useFieldsDispatch();
  const settingsDispatch = useSettingsDispatch();
  const authDispatch = useAuthDispatch();
  const metricsDispatch = useMetricsDispatch();
  const analyticsDispatch = useAnalyticsDispatch();
  const loadFields = useCallback(async () => {
    setFieldsLoading(true);
    try {
      await fetchFields(
        fieldsDispatch,
        user.client.id,
        user.token,
        user.client.directory
      );
      setVisibility(settingsDispatch, user.client.id, user.settings);
      setFieldsLoading(false);
    } catch (error) {
      setFieldsLoading(false);
    }
  }, [
    fieldsDispatch,
    user.client.id,
    user.token,
    user.client.directory,
    settingsDispatch,
    user.settings,
  ]);

  const loadField = useCallback(async () => {
    try {
      fetchField(
        analyticsDispatch,
        user.client.id,
        fieldId,
        user.client.directory,
        user.token
      );
      await fetchMetrics(metricsDispatch, user.token);
      setVisibility(settingsDispatch, user.client.id, user.settings);
    } catch (error) {
      console.error("Error loading field", error);
      Sentry.captureMessage(error.response.data, Sentry.Severity.Error);
    }
  }, [fieldId]);

  // Load fields initially
  useEffect(() => {
    if (!allFields.length) {
      const { token, tokenExpiry } = user;
      refresh(authDispatch, { token, tokenExpiry });
      loadFields();
    }
  }, [authDispatch, user, loadFields, allFields, metricsDispatch]);

  useEffect(() => {
    loadField();
  }, [fieldId]);

  const fieldOptions = useMemo(
    () =>
      allFields.reduce((obj, field) => {
        obj[field.id] = field.name;
        return obj;
      }, {}),
    [allFields]
  );

  const [name, setName] = useState("");
  const [conditions, setConditions] = useState({
    0: { type: "time", start: "07:00", end: "18:00" },
  });
  const [actions, setActions] = useState({});
  const [enabled, setEnabled] = useState(true);
  const [isOr, setIsOr] = useState(false);

  // Load existing automation if in edit mode
  useEffect(() => {
    if (editing) {
      setAutomationLoading(true);
      setAutomationError(false);
      getAutomation(user.token, user.client.id, id)
        .then((response) => {
          setFieldId(response.data.field_id.toString());
          setName(response.data.name);
          let conditions = response.data.data.conditions;
          let conditionId = 0;
          if (conditions.length === 2 && conditions[1].type === "or") {
            setIsOr(true);
            conditionId = 1;
            setConditions({
              0: conditions[0],
              ...conditions[1].conditions.reduce((obj, condition) => {
                obj[conditionId] = condition;
                conditionId++;
                return obj;
              }, {}),
            });
          } else {
            setConditions(
              response.data.data.conditions.reduce((obj, condition) => {
                obj[conditionId] = condition;
                conditionId++;
                return obj;
              }, {})
            );
          }
          setNextConditionId(conditionId);
          let actionId = 0;
          setActions(
            response.data.data.actions.reduce((obj, action) => {
              obj[actionId] = action;
              actionId++;
              return obj;
            }, {})
          );
          setNextActionId(actionId);
          setAutomationLoading(false);
          setEnabled(!response.data.data.disabled);
        })
        .catch(() => {
          setAutomationError(true);
          setAutomationLoading(false);
        });
    }
  }, [editing, id, user]);

  const currentAutomation = useMemo(
    () => ({
      id: editing ? id : undefined,
      field_id: fieldId,
      name: name,
      invalid: false,
      data: {
        disabled: !enabled,
        conditions: isOr
          ? [
              conditions[0],
              {
                type: "or",
                conditions: Object.values(conditions)
                  .slice(1)
                  .filter((c) => c !== undefined),
              },
            ]
          : Object.values(conditions).filter((c) => c !== undefined),
        actions: Object.values(actions).filter((c) => c !== undefined),
      },
    }),
    [fieldId, name, conditions, actions, enabled, isOr]
  );

  const automationValid = useMemo(() => {
    return (
      currentAutomation.field_id !== undefined &&
      currentAutomation.name &&
      currentAutomation.data.conditions.length &&
      currentAutomation.data.conditions.every(
        (c) => !("conditions" in c) || c.conditions.length
      ) &&
      currentAutomation.data.actions.length
    );
  }, [currentAutomation]);

  const [nextConditionId, setNextConditionId] = useState(1);
  const [nextActionId, setNextActionId] = useState(0);

  const fieldInput = {
    value: fieldId,
    onChange: (evt) => {
      setFieldId(evt.target.value);
    },
  };

  const nameInput = {
    value: name,
    onChange: (evt) => {
      setName(evt.target.value);
    },
  };

  const [submitting, setSubmitting] = useState(false);

  const submit = useCallback(async () => {
    if (automationValid) {
      setSubmitting(true);
      if (editing) {
        await updateAutomation(user.token, user.client.id, currentAutomation);
      } else {
        await addAutomation(user.token, user.client.id, currentAutomation);
      }
      history.push("/automation");
    }
  }, [automationValid, currentAutomation, user.token]);

  const cancel = useCallback(() => {
    history.push("/automation");
  }, [history]);

  return (
    <Layout>
      <Loader
        active={isLoading}
        showChildren={false}
        message="Loading automation..."
      >
        {automationError ? (
          <CardWarning
            message={`Automation #${id} not found`}
            color="disabled"
          />
        ) : (
          <>
            {editing && (
              <Box mb={3}>
                <AutomationLogTable automation_id={id} />
              </Box>
            )}
            <Card
              headline={`${id === "new" ? "Add" : "Update"} Automation`}
              headlineVariant="h1"
            >
              <TextInput label="Name" input={nameInput} meta={{}} />
              <SelectInput
                label="Target Field"
                input={fieldInput}
                options={fieldOptions}
                meta={{}}
              />

              <FormControlLabel
                control={
                  <Switch
                    name="Enabled"
                    checked={enabled}
                    onChange={(evt, checked) => setEnabled(checked)}
                    color="primary"
                  />
                }
                label={enabled ? "Enabled" : "Disabled"}
              />

              <Box mt={2} mb={2}>
                <Divider />
              </Box>
              <Typography variant="h5">Conditions</Typography>
              {Object.entries(conditions).map(([id, condition]) => (
                <>
                  <ConditionCard
                    key={id}
                    field={allFields.find((f) => f.id === parseInt(fieldId))}
                    mode="update"
                    user={user}
                    fieldId={fieldId ? parseInt(fieldId) : undefined}
                    onChange={(value) => {
                      setConditions({ ...conditions, [id]: value });
                    }}
                    onRemove={() => {
                      const newConditions = { ...conditions };
                      delete newConditions[id];
                      setConditions(newConditions);
                    }}
                    value={condition}
                  />
                  {id === "0" && Object.values(conditions).length > 1 && (
                    <>
                      <Typography style={{ marginLeft: 16 }}>
                        And{" "}
                        <Select
                          label="And/Or"
                          value={isOr ? "or" : "and"}
                          onChange={(e) => {
                            setIsOr(e.target.value === "or");
                          }}
                        >
                          <MenuItem value="and">
                            all of the below (AND)
                          </MenuItem>
                          <MenuItem value="or">some of the below (OR)</MenuItem>
                        </Select>
                      </Typography>
                    </>
                  )}
                </>
              ))}
              <Button
                onClick={() => {
                  setConditions({
                    ...conditions,
                    [nextConditionId]: undefined,
                  });
                  setNextConditionId(nextConditionId + 1);
                }}
                variant="contained"
                color="primary"
              >
                Create New Condition
              </Button>
              <Box mt={2} mb={2}>
                <Divider />
              </Box>
              <Typography variant="h5">Actions</Typography>
              {Object.entries(actions).map(([id, action]) => (
                <ActionCard
                  key={id}
                  mode="update"
                  onChange={(value) => {
                    setActions({ ...actions, [id]: value });
                  }}
                  onRemove={() => {
                    const newActions = { ...actions };
                    delete newActions[id];
                    setActions(newActions);
                  }}
                  value={action}
                  targetField={parseInt(fieldId)}
                />
              ))}
              <Button
                onClick={() => {
                  setActions({ ...actions, [nextActionId]: undefined });
                  setNextActionId(nextActionId + 1);
                }}
                variant="contained"
                color="primary"
              >
                Create New Action
              </Button>

              <Box mt={2} mb={2}>
                <Divider />
              </Box>

              <FormButton
                handleClick={cancel}
                variant="contained"
                text="Cancel"
              />
              <FormButton
                disabled={!automationValid}
                handleClick={submit}
                variant="contained"
                color="primary"
                text={editing ? "Update Automation" : "Create Automation"}
                waiting={submitting}
              />
            </Card>
          </>
        )}
      </Loader>
    </Layout>
  );
};

export const AutomationConfigPage = () => (
  <MetricsProvider>
    <AutomationConfig />
  </MetricsProvider>
);
