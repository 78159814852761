import { createContext, useReducer, useContext, ReactNode } from "react";
import moment, { Moment } from "moment-timezone";
import { handleRequestError } from "../helpers";
import { craft } from "../api/agritech";

type ReportsState = {
  list: { date: Moment; group: number; id: number; title: string }[];
  report: {
    id: number;
    title: string;
    pdf: string;
    report_comments: string;
    content: {
      name: string;
      group: string;
      probe: string;
      status: string;
      comments: string;
    }[];
  } | null;
};
const ReportsStateContext = createContext<ReportsState | undefined>(undefined);
type ReportAction =
  | {
      type: "setReports";
      payload: ReportsState["list"];
    }
  | {
      type: "setActiveReport";
      payload: ReportsState["report"];
    };
type ReportsDispatch = React.Dispatch<ReportAction>;
const ReportsDispatchContext = createContext<ReportsDispatch | undefined>(
  undefined
);

function reportsReducer(state: ReportsState, action: ReportAction) {
  switch (action.type) {
    case "setReports": {
      return { ...state, list: action.payload };
    }
    case "setActiveReport": {
      return { ...state, report: action.payload };
    }
  }
}

function ReportsProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reportsReducer, {
    list: [],
    report: null,
  });
  return (
    <ReportsStateContext.Provider value={state}>
      <ReportsDispatchContext.Provider value={dispatch}>
        {children}
      </ReportsDispatchContext.Provider>
    </ReportsStateContext.Provider>
  );
}

function useReportsState() {
  const context = useContext(ReportsStateContext);
  if (context === undefined) {
    throw new Error("useReportsState must be used within a ReportsProvider");
  }
  return context;
}

function useReportsDispatch() {
  const context = useContext(ReportsDispatchContext);
  if (context === undefined) {
    throw new Error("useReportsDispatch must be used within a ReportsProvider");
  }
  return context;
}

async function fetchReportById(dispatch: ReportsDispatch, id: string) {
  try {
    const response = await craft.get(`/report/${id}.json`);
    const {
      data: { data },
    } = response;

    if (data.length) {
      const {
        date: { date, timezone },
      } = data[0];
      const timestamp = moment.tz(
        date.substring(0, date.length - 7),
        "YYYY-MM-DD hh:mm:ss",
        timezone
      );

      dispatch({
        type: "setActiveReport",
        payload: { ...data[0], date: timestamp },
      });
    }
  } catch (e) {
    handleRequestError(e, "Failed fetching reports: ");
  }
}

async function fetchReports(dispatch: ReportsDispatch, clientName: string) {
  try {
    const response = await craft.get<{
      data: {
        date: { date: string; timezone: string };
        id: number;
        title: string;
      }[];
    }>(`/reports/${clientName}.json`);
    const {
      data: { data },
    } = response;
    const reports =
      data.length &&
      data.map((item) => {
        const {
          date: { date, timezone },
        } = item;
        const timestamp = moment.tz(
          date.substring(0, date.length - 7),
          "YYYY-MM-DD hh:mm:ss",
          timezone
        );

        return {
          ...item,
          date: timestamp,
          group: timestamp.startOf("month").unix(),
        };
      });
    dispatch({ type: "setReports", payload: reports || [] });
  } catch (e) {
    handleRequestError(e, "Failed fetching reports: ");
  }
}

export {
  ReportsProvider,
  useReportsState,
  useReportsDispatch,
  fetchReportById,
  fetchReports,
};
