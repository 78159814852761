import {
  Card,
  MenuItem,
  Select,
  Box,
  InputLabel,
  Typography,
  Button,
  Input,
  TextField,
} from "@material-ui/core";
import React, { useMemo, useState } from "react";
import {
  Action,
  EmailAction,
  IrrigatorAction,
  SMSAction,
  TalgilAction,
} from "../../../api/automation";

import { IrrigationAction } from "./actions/IrrigatorAction";
import { SMSActionConfig } from "./actions/SMSAction";

type WithChildren = {
  children?: React.ReactNode;
};
export const Spacer: React.FC<WithChildren> = ({ children }) => (
  <Box ml={1} mr={1} mb={2} component="span">
    {children}
  </Box>
);

const actionChanged = (oldAction: Action | undefined, newAction: Action) => {
  if (oldAction?.type !== newAction.type) {
    return true;
  }
  switch (oldAction.type) {
    case "sms":
      return (
        oldAction.phone_number !== (newAction as SMSAction).phone_number ||
        oldAction.message !== (newAction as SMSAction).message ||
        oldAction.repeat !== (newAction as SMSAction).repeat
      );
    case "email":
      return (
        oldAction.email !== (newAction as EmailAction).email ||
        oldAction.message !== (newAction as EmailAction).message
      );
    case "talgil":
      return (
        oldAction.irrigator_id !== (newAction as TalgilAction).irrigator_id ||
        oldAction.program_id !== (newAction as TalgilAction).program_id
      );
    case "irrigator":
      return (
        oldAction.command !== (newAction as IrrigatorAction).command ||
        oldAction.irrigator_id !==
          (newAction as IrrigatorAction).irrigator_id ||
        oldAction.repeat !== (newAction as IrrigatorAction).repeat
      );
  }
};

const actionLabels: Record<Action["type"], string> = {
  sms: "Send SMS",
  email: "Send Email",
  irrigator: "Run Irrigator",
  talgil: "Run Talgil Program",
};

export const ActionCard: React.FC<{
  value: Action | undefined;
  targetField: number;
  onChange: (c: Action | undefined) => void;
  onRemove: () => void;
}> = ({ value, targetField, onChange, onRemove }) => {
  const typeOptions: Action["type"][] = ["sms", "irrigator"];
  const [action, setAction] = useState<Partial<Action>>({ ...value });

  const result = useMemo<Action | undefined>(() => {
    switch (action.type) {
      case "sms":
        if (action.phone_number && action.message) {
          return { ...action } as SMSAction;
        }
        break;
      case "email":
        if (action.email && action.message) {
          return { ...action } as EmailAction;
        }
        break;
      case "talgil":
        if (
          action.irrigator_id !== undefined &&
          action.program_id !== undefined
        ) {
          return { ...action } as TalgilAction;
        }
        break;

      case "irrigator":
        if (action.irrigator_id && action.command) {
          return { ...action } as IrrigatorAction;
        }
    }
  }, [action]);

  return (
    <Box m={2}>
      <Card>
        <Box p={2}>
          <InputLabel id="type" shrink>
            Type
          </InputLabel>
          <Box
            flexDirection="row"
            display="flex"
            justifyContent="space-between"
          >
            <Select
              label="type"
              value={action.type ?? ""}
              onChange={(e) =>
                setAction({
                  type: e.target.value as Action["type"],
                })
              }
            >
              {typeOptions.map((t) => (
                <MenuItem value={t} key={t}>
                  {actionLabels[t]}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box mt={1}>
            {action.type === "sms" && (
              <SMSActionConfig action={action} setAction={setAction} />
            )}
            {action.type === "email" && (
              <>
                <Box mb={1}>
                  <Typography>
                    Send an email to
                    <Spacer>
                      <Input
                        value={action.email}
                        onChange={(e) =>
                          setAction({
                            ...action,
                            email: e.target.value as string,
                          })
                        }
                      />
                    </Spacer>
                    with content
                  </Typography>
                </Box>

                <TextField
                  value={action.message}
                  fullWidth
                  multiline
                  variant="outlined"
                  onChange={(e) =>
                    setAction({
                      ...action,
                      message: e.target.value as string,
                    })
                  }
                />
              </>
            )}
            {action.type === "irrigator" && (
              <IrrigationAction
                targetField={targetField}
                action={action}
                setAction={setAction}
                isManual={false}
              />
            )}
          </Box>
          <Box mt={2}>
            <Button
              disabled={result === undefined || !actionChanged(value, result)}
              onClick={() => onChange(result)}
              variant="contained"
              color="primary"
            >
              {value === undefined ? "Add" : "Update"}
            </Button>
            <Button
              onClick={onRemove}
              variant="contained"
              style={{ backgroundColor: "lightgrey", marginLeft: 8 }}
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
