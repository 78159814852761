import React, { useMemo } from "react";
import Card from "../pages/Analytics/components/Card";
import Agronomics from "../pages/Analytics/components/Card/components/Agronomics";
import { useAnalyticsState } from "../../context/analytics";
import Table from "../tables/ChartTable";
import RootZoneMoisture from "../pages/Analytics/components/Card/components/RootZoneMoisture";
import MildewRisk from "../pages/Analytics/components/Card/components/MildewRisk";

const ChartData = ({ chart, range }) => {
  const { field } = useAnalyticsState();

  const tableData = useMemo(() => {
    return {
      chart,
      range,
      field,
    };
  }, [chart, range, field]);

  const chartData = {};

  switch (chart.group) {
    case "ambient_climates":
      return (
        <Card
          chart={chart}
          data={chartData}
          tables={[
            {
              title: "Sensor Data",
              display: false,
              table: (
                <Table
                  {...{
                    ...tableData,
                    title: `${chart?.label} Sensor Data`,
                  }}
                />
              ),
            },
          ]}
        />
      );
    case "soil_climates":
      if (chart.id === "soil_climate") {
        return (
          <RootZoneMoisture
            chart={chart}
            data={chartData}
            tableData={tableData}
            field={field}
            range={range}
          />
        );
      } else {
        return (
          <Card
            chart={chart}
            data={chartData}
            tables={[
              {
                title: "Sensor Data",
                display: false,
                table: (
                  <Table
                    {...{
                      ...tableData,
                      title: `${chart?.label} Sensor Data`,
                    }}
                  />
                ),
              },
            ]}
          />
        );
      }
    case "agronomics":
      return (
        <Agronomics
          chart={chart}
          data={chartData}
          tableData={tableData}
          field={field}
          range={range}
        />
      );
    case "mildew_risk":
      return (
        <MildewRisk
          chart={chart}
          data={chartData}
          tableData={tableData}
          field={field}
          range={range}
        />
      );
    default:
      throw new Error(`Unhandled chart group: ${chart.group}`);
  }
};

export default ChartData;
