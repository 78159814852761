import moment from "moment-timezone";
import type { AdminLog } from "../api/admin";

const isoRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:.\d{3})?Z/g;

// turn ISO timestamps into human readable times (e.g. 2020-01-01T00:25:00Z -> 01:25)
export const replaceTimestamps = (log: string, targetFormat = "HH:mm") => {
  return log.replace(isoRegex, (match) => {
    return moment.utc(match).local().format(targetFormat);
  });
};

export const processAdminLog = (log: AdminLog): AdminLog => {
  return {
    ...log,
    message: replaceTimestamps(log.message),
  };
};
