import React from "react";
import { Box, Button, ListSubheader, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { sidebarStyles } from "./SidebarContent";

export default (props) => {
  const classes = sidebarStyles();

  return (
    <ListSubheader>
      <Grid container justifyContent="space-between">
        <Box>Settings</Box>
        <Button onClick={props.close} className={classes.closeIcon}>
          <CloseIcon />
        </Button>
      </Grid>
    </ListSubheader>
  );
};
