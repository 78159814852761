import api from "./agritech";

async function ListSqueezes(
  token: string,
  clientId: number,
  fieldId: number,
  range: { start_date: string; end_date: string }
) {
  const response = await api.post(
    `${clientId}/squeezes/${fieldId}/search`,
    range,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

type Squeeze = {
  timestamp: string;
};
async function SaveSqueeze(
  token: string,
  clientId: number,
  fieldId: number,
  squeeze: Squeeze
) {
  const response = await api.post(`${clientId}/squeezes/${fieldId}`, squeeze, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function UpdateSqueeze(
  token: string,
  clientId: number,
  fieldId: number,
  id: number,
  squeeze: Squeeze
) {
  const response = await api.patch(
    `${clientId}/squeezes/${fieldId}/${id}`,
    squeeze,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function DeleteSqueeze(
  token: string,
  clientId: number,
  fieldId: number,
  id: number
) {
  const response = await api.delete(`${clientId}/squeezes/${fieldId}/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

export { ListSqueezes, SaveSqueeze, UpdateSqueeze, DeleteSqueeze };
