import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import FieldMeta from "./FieldMeta";
import Card from "../cards/Card";
import ChartGrowerMoisture from "../charts/ChartGrowerMoisture.js";
import Statistic from "../elements/Statistic";
import Image from "../elements/Image";
import { NoFieldsWarning } from "./NoFieldsWarning";

const FieldsCards = ({
  title,
  fields,
  onClick,
  fieldType,
}: {
  title: string;
  fields: any[];
  onClick: (id: string) => void;
  fieldType: string;
}) => {
  return fields ? (
    <>
      <Grid item xs={12}>
        <Typography variant="h1">{title}</Typography>
      </Grid>
      {fields.length ? (
        fields.map((field) => (
          <Grid
            key={`${field.id}-card`}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2}
          >
            <Card
              headline={field.name}
              headlineVariant="h2"
              subheadline={<FieldMeta field={field} />}
              subHeadlineVariant="subtitle1"
              divider={true}
              link={() => onClick(field.id)}
            >
              {field.data && (
                <List dense={true}>
                  {field.irrimax && field.irrimax.length && (
                    <Image
                      src={field.irrimax[0].gauge}
                      alt={field.irrimax[0].name}
                    />
                  )}
                  {!field.irrimax && field.data[0]?.value && (
                    <ChartGrowerMoisture
                      id={field.name}
                      level={field.data[0].value}
                      isSoil={fieldType === "soil"}
                      paramData={field.parameters}
                    />
                  )}
                  {Object.keys(field.data).map((item) => {
                    const {
                      short_name,
                      ref,
                      value,
                      suffix,
                      thresholds,
                      parameters,
                    } = field.data[item];
                    return (
                      <ListItem
                        key={`${field.id}-stat-${ref}`}
                        disableGutters={true}
                      >
                        <ListItemText primary={`${short_name}: `} />
                        <Statistic
                          {...{
                            type: ref,
                            value,
                            suffix,
                            thresholds,
                            parameters,
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </Card>
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Typography>No fields found.</Typography>
        </Grid>
      )}
    </>
  ) : (
    <NoFieldsWarning />
  );
};

export default FieldsCards;
