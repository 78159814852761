import moment from "moment-timezone";
import { Moment } from "moment-timezone";

type Timestamp = string | number | Date | Moment;

/**
 * Returns a unix timestamp with the timezone offset applied
 * @param utcTimestamp
 * @param timezone
 * @returns
 */
export const shiftTimestamp = (utcTimestamp: Timestamp, timezone: string) => {
  const time = moment.utc(utcTimestamp);
  const offset = time.tz(timezone).utcOffset();
  const localOffset = moment(utcTimestamp).utcOffset();
  return time.add(offset - localOffset, "minutes").valueOf();
  // --- ORIGINAL IMPLEMENTATION ---
  // return moment(
  //   moment.utc(utcTimestamp).tz(timezone).format("YYYY-MM-DD HH:mm:ss")
  // ).valueOf();
};

export const unshiftTimestamp = (
  localTimestamp: Timestamp,
  timezone: string
) => {
  const time = moment(localTimestamp);
  const offset = time.tz(timezone).utcOffset();
  const localOffset = moment(localTimestamp).utcOffset();
  return time.subtract(offset - localOffset, "minutes");
};
