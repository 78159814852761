import moment from "moment";
import { Moment } from "moment-timezone";

type ChartDataset =
  | { xAxisId: string }
  | { metricId: string; data: { x: Moment | number; y: string | number }[] };
export const getMinChartValue = (
  minDate: Moment,
  maxDate: Moment,
  datasets: ChartDataset[]
) => {
  const minData = new Map<string, number>();
  for (const dataset of datasets) {
    if ("xAxisId" in dataset) {
      continue;
    }
    // Find any data points between minDate and maxDate
    const withinRange = dataset.data.filter((data) =>
      moment(data.x).isBetween(minDate, maxDate, undefined, "[]")
    );
    if (withinRange.length) {
      minData.set(
        dataset.metricId,
        Math.min(...withinRange.map((data) => Number(data.y)))
      );
      continue;
    }
    // Find the first data point before minDate
    let before: number | undefined;
    for (const data of dataset.data) {
      if (minDate.isSameOrAfter(data.x)) {
        before = Number(data.y);
      } else {
        break;
      }
    }
    if (before !== undefined) {
      minData.set(dataset.metricId, before);
    }
  }
  return minData.size ? Math.min(...minData.values()) : undefined;
};
