import { Bar } from "../../plugins/react-chartjs-2";

const Chart: React.FC<{
  data?: { datasets: any[] };
  options: any;
  plugins?: any[];
  chartRef?: any;
}> = ({ data, options, plugins, chartRef }) => {
  if (!chartRef || !data?.datasets) return null;

  const errors = data.datasets.reduce((count, item) => {
    return !item || (item.label && item.label.toLowerCase()) ? count++ : count;
  }, []);

  return errors ? null : (
    // @ts-ignore
    <Bar ref={chartRef} data={data} plugins={plugins} options={options} />
  );
};

export default Chart;
