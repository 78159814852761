import React, { useCallback, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useAuthState } from "../../../context/auth";
import { connectionTest } from "../../../api/loggers";
import { Typography, CircularProgress, Grid } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const ApiTest: React.FC<{
  loggerId: number;
}> = ({ loggerId }) => {
  const user = useAuthState();
  const [data, setData] = useState<Record<string, number>>();
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState<any>(null);
  const testConnection = useCallback(async () => {
    if (!user.token) return;
    try {
      setWaiting(true);
      setError(null);
      const res = await connectionTest(user.token, loggerId);
      setData(res.data);
      setWaiting(false);
    } catch (e: any) {
      setError(e.response);
      setWaiting(false);
    }
  }, [user.token, loggerId]);

  useEffect(() => {
    testConnection();
  }, [testConnection]);

  if (waiting) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <CircularProgress color="primary" size={30} />
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textSecondary">
            Loading external data...
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <WarningIcon color="disabled" />
        <Typography variant="h6" color="textSecondary">
          Connection Error.
        </Typography>
        {error.data.message ? (
          <Typography variant="body2">{error.data.message}</Typography>
        ) : null}
      </Grid>
    );
  }

  if (data) {
    return (
      <Grid container>
        <CheckCircleOutlineIcon color="disabled" />
        <Typography variant="h6" color="textSecondary">
          Data retrieval sucessfull!
        </Typography>
        <Typography variant="body2">
          The data is in the format "[API Node Reference]": [Latest Reading]
        </Typography>
        <Grid item xs={12}>
          <ReactJson
            src={data}
            theme="solarized"
            displayDataTypes={false}
            enableClipboard={false}
            collapsed={3}
            style={{
              width: "100%",
              padding: "10px",
              maxHeight: 400,
              overflow: "auto",
              marginTop: "10px",
            }}
          />
        </Grid>
      </Grid>
    );
  } else {
    return <Typography>Unknown logger manufacturer.</Typography>;
  }
};

export default ApiTest;
