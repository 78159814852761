import { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import {
  Typography,
  FormControl,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import { TextInput, CheckboxInput, SelectInput } from "../FormInputs";
import { ModuleInfo } from "../../../api/modules";
import { APIConfig } from "../../../api/clients";

const frequencyOptions = {
  hour1: "Hourly",
  hour2: "Every 2 hours",
  hour4: "Every 4 hours",
  hour6: "Every 6 hours",
  hour12: "Every 12 hours",
  day1: "Daily",
};

export type ClientAccessKey = `access_${number}`;
export type ClientFormValue = {
  id: number;
  name: string;
  directory: string;
  api?: APIConfig["api"];
  syncFrequency?: keyof typeof frequencyOptions;
} & Record<ClientAccessKey, boolean>;

const validate = (values: ClientFormValue) => {
  const errors: Partial<Record<keyof ClientFormValue, string>> = {};
  if (!values.name) {
    errors.name = "Required";
  }
  return errors;
};

type FormProps = {
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  error?: string;
  valid?: boolean;
  pristine?: boolean;
  submitting?: boolean;
  isValid: (valid: boolean) => void;
  isWaiting: (waiting: boolean) => void;
  initialValues?: Partial<ClientFormValue>;
  permissions?: ModuleInfo[];
};

const ClientForm = ({
  handleSubmit,
  error,
  valid,
  pristine,
  submitting,
  isValid,
  isWaiting,
  initialValues,
  permissions,
}: FormProps) => {
  useEffect(() => {
    isValid(!pristine && !!valid && !submitting);
    isWaiting(!!submitting);
  });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Field
        component={TextInput}
        name="name"
        id="name"
        autoFocus={!initialValues}
        label="Name"
      />
      <Field
        component={TextInput}
        name="directory"
        id="directory"
        label="Irrimax Directory"
      />
      <FormControl component="fieldset">
        <FormLabel component="legend">Permissions</FormLabel>
        <FormGroup row>
          {permissions &&
            permissions.map((permission, index) => {
              return (
                <Field
                  key={`access_${index}`}
                  name={`access_${permission.id}`}
                  id={`access_${permission.id}`}
                  component={CheckboxInput}
                  label={permission.name}
                />
              );
            })}
        </FormGroup>
      </FormControl>

      <Field
        component={SelectInput}
        name="api"
        id="api"
        label="External API"
        allowEmpty
        options={{
          costa: "Costa",
        }}
      />
      <Field
        component={SelectInput}
        name="syncFrequency"
        id="syncFrequency"
        label="Sync Frequency"
        allowEmpty
        options={frequencyOptions}
      />

      <Typography variant="body1" gutterBottom color="error">
        <b>{error}</b>
      </Typography>
    </form>
  );
};

export default reduxForm<ClientFormValue, FormProps>({
  form: "ClientForm",
  validate,
})(ClientForm);
