import React, { createContext, useReducer, useContext } from "react";
import moment from "moment";
import { handleRequestError, filterIrrimax } from "../helpers";
import { fetchIrrimax } from "../api/irrimax";
import { getField } from "../api/fields";

const AnalyticsStateContext = createContext();
const AnalyticsDispatchContext = createContext();

const AnalyticsDateTimeDurationStorageKey = "AnalyticsDateTimeDuration";

const defaultDateRange = () => {
  return {
    start_date: moment().utc().subtract(10, "days"),
    end_date: moment().utc(),
  };
};

function analyticsReducer(state, action) {
  switch (action.type) {
    case "setActiveField": {
      const updatedState = { ...state, field: action.payload };

      if (Storage !== undefined && updatedState.field?.id) {
        const days = localStorage.getItem(
          AnalyticsDateTimeDurationStorageKey + updatedState.field.id
        );
        if (days) {
          let count = parseInt(days);
          // Limit possible values to 1-30, don't want to save larger values as they will be slow to load
          if (!count || count < 1 || isNaN(count) || count > 30) count = 10;
          updatedState.range = {
            start_date: moment().utc().subtract(count, "days"),
            end_date: moment().utc(),
          };
        }
      }

      return updatedState;
    }
    case "clearDaysCache": {
      if (Storage !== undefined && state.field?.id) {
        localStorage.removeItem(
          AnalyticsDateTimeDurationStorageKey + state.field.id
        );
      }
      return state;
    }

    case "setDateRange": {
      return { ...state, range: { ...state.range, ...action.payload } };
    }
    case "resetDateRange": {
      return { ...state, range: defaultDateRange() };
    }
    case "toggleFooter": {
      return { ...state, footer: !state.footer };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AnalyticsProvider({ children }) {
  const [state, dispatch] = useReducer(analyticsReducer, {
    footer: false,
    field: null,
    range: defaultDateRange(),
  });
  return (
    <AnalyticsStateContext.Provider value={state}>
      <AnalyticsDispatchContext.Provider value={dispatch}>
        {children}
      </AnalyticsDispatchContext.Provider>
    </AnalyticsStateContext.Provider>
  );
}

function useAnalyticsState() {
  const context = useContext(AnalyticsStateContext);
  if (context === undefined) {
    throw new Error(
      "useAnalyticsState must be used within a AnalyticsProvider"
    );
  }
  return context;
}

function useAnalyticsDispatch() {
  const context = useContext(AnalyticsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useAnalyticsDispatch must be used within a AnalyticsProvider"
    );
  }
  return context;
}

async function fetchField(dispatch, clientId, fieldId, folder, token) {
  try {
    const response = await getField(token, clientId, fieldId);
    let field = { ...response.data };

    if (folder) {
      try {
        const irrimax = await fetchIrrimax(folder);
        if (irrimax) {
          field = { ...field, ...filterIrrimax(irrimax, field) };
        }
      } catch (e) {
        handleRequestError(e, "Failed fetching irrimax: ");
      }
    }
    dispatch({ type: "setActiveField", payload: field });
  } catch (e) {
    handleRequestError(e, "Failed fetching fields: ");
  }
}

export {
  AnalyticsProvider,
  useAnalyticsState,
  useAnalyticsDispatch,
  fetchField,
  AnalyticsDateTimeDurationStorageKey,
};
