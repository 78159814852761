import React, { useState, useEffect, Fragment } from "react";
import { submit } from "redux-form";
import {
  makeStyles,
  withStyles,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  CardContent,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import ActionSelection from "../actions/ActionSelection";
import FormSelection from "../forms/FormSelection";
import StepperButtons from "./StepperButtons";
import formConfig from "../../constants/forms";

const CustomConnector = withStyles((theme) => ({
  line: {
    borderColor: theme.palette.divider,
    borderTopWidth: 2,
  },
  active: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
}))(StepConnector);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
  },
  body: {
    [theme.breakpoints.down("sm")]: {
      overflowY: "scroll",
      maxHeight: "80vh",
    },
  },
}));

const ActionStepper = ({ action, close }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [activeForm, setActiveForm] = useState("");
  const [valid, setValid] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [waitingSecondary, setWaitingSecondary] = useState(false);
  const [secondarySuccessButtonClicked, setSecondarySuccessButtonClicked] =
    useState(false);

  const steps = ["Choose an action", "Insert your data"];

  useEffect(() => {
    if (action) {
      setActiveStep(1);
      setActiveForm(action);
    }
  }, [action]);

  useEffect(() => {
    setSecondarySuccessButtonClicked(secondarySuccessButtonClicked);
  }, [secondarySuccessButtonClicked]);

  const dispatchFormSuccess = (buttonClicked) => {
    if (buttonClicked === "primaryButton") {
      dispatch(submit(formConfig[activeForm].submission));
    } else if (buttonClicked === "secondaryButton") {
      dispatch(submit(formConfig[activeForm].submission));
      // stay on page to allow user to add more data
      setActiveStep(1);
    }
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        className={classes.header}
        connector={<CustomConnector />}
      >
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <CardContent className={classes.body}>
        <div>
          {activeStep === 0 && (
            <Fragment>
              <ActionSelection
                config={formConfig}
                stepValid={(status) => setValid(status)}
                selection={setActiveForm}
              />
              <StepperButtons
                failButtonText="Close"
                handleFailButton={() => close()}
                successButtonText="Next"
                disableSuccessButton={!valid}
                handleSuccessButton={() =>
                  setActiveStep((prevActiveStep) => prevActiveStep + 1)
                }
                waiting={waiting}
                waitingText={activeStep && "Saving"}
              />
            </Fragment>
          )}
          {activeStep === 1 && (
            <Fragment>
              <FormSelection
                type={activeForm}
                handleWaiting={
                  secondarySuccessButtonClicked
                    ? setWaitingSecondary
                    : setWaiting
                }
                handleValid={setValid}
                closeModal={close}
                isSecondarySuccessButtonClicked={secondarySuccessButtonClicked}
              />
              <div>
                <StepperButtons
                  failButtonText="Back"
                  handleFailButton={() =>
                    setActiveStep((prevActiveStep) => prevActiveStep - 1)
                  }
                  successButtonText="Save"
                  disableSuccessButton={!valid || waiting}
                  handleSuccessButton={() => {
                    setSecondarySuccessButtonClicked(false);
                    // call function after time out to allow time for setIsSecondarySuccessButtonClicked to be set
                    setTimeout(() => {
                      dispatchFormSuccess("primaryButton");
                    }, 400);
                  }}
                  waiting={waiting}
                  waitingText="Saving"
                />
              </div>
              <div style={{ marginTop: 10 }}>
                {(activeForm === "squeeze" || activeForm === "agronomics") && (
                  <StepperButtons
                    hideSucessButton={true}
                    failButtonText={"Save and add another"}
                    disableFailButton={!valid || waitingSecondary}
                    handleFailButton={() => {
                      setSecondarySuccessButtonClicked(true);
                      // call function after time out to allow time for setIsSecondarySuccessButtonClicked to be set
                      setTimeout(() => {
                        dispatchFormSuccess("secondaryButton");
                      }, 400);
                    }}
                    waiting={waitingSecondary}
                    waitingText="Saving"
                  />
                )}
              </div>
            </Fragment>
          )}
        </div>
      </CardContent>
    </div>
  );
};

export default ActionStepper;
