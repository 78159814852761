import React, { useMemo } from "react";

import { useSettingsState } from "../../../../context/settings";
import { useChartEvents } from "../../Analytics/components/Card/hooks";

import ChartSelection from "../../../charts/ChartSelection";
import config from "../../../../constants/charts";

import { useAuthState } from "../../../../context/auth";
import { useDataLoader } from "../../../../data/DataLoader";
import { useChartData } from "../../../../data/useChartData";
import moment from "moment";
import { useAnalyticsState } from "../../../../context/analytics";
import { formatChartParams } from "../../../../helpers/formatChartParams";

export const MoistureContentConditionChart: React.FC<{
  field: { id: string; timezone: string };
}> = ({ field }) => {
  const {
    chartsSettings: { visibility },
  } = useSettingsState();

  const user = useAuthState();
  useDataLoader({
    user,
    field,
    groups: ["ambient_climates", "soil_climates"],
  });

  const chartEvents = useChartEvents();

  const chart = useMemo(() => {
    const chart = config["soil_climate"];

    const settings = visibility?.find(
      ({ field_id: id, ref }) => field.id === id && ref === "soil_climate"
    );
    const { order, hidden } = settings ?? { order: 999, hidden: 0 };
    return { ...chart, id: "soil_climate", ...{ order, hidden } };
  }, [field.id]);

  const {
    range: { start_date, end_date },
  } = useAnalyticsState();

  const range = useMemo(
    () => ({
      start_date: moment(
        start_date.clone().tz(field.timezone).format("YYYY-MM-DD HH:mm:ss")
      ),
      end_date: moment(
        end_date.clone().tz(field.timezone).format("YYYY-MM-DD HH:mm:ss")
      ),
    }),
    [end_date, start_date, field.timezone]
  );

  const { chartData: paramsData } = useChartData("parameters", range, field.id);
  const { chartData: typesData } = useChartData(
    "parameter_types",
    range,
    field.id
  );

  const formatParams = useMemo(
    () => formatChartParams(paramsData, typesData, field.timezone, range),
    [field.timezone, range, paramsData, typesData]
  );

  const { chartData: squeezeData } = useChartData("squeezes", range, field.id);

  const data = {};
  const chartData = useMemo(() => {
    return data
      ? {
          ...data,
          ...field,
          squeeze:
            squeezeData &&
            squeezeData.map((item: any) => ({
              timestamp: item.timestamp,
              squeeze: item.value,
              label: item.type.toUpperCase(),
            })),
          params: formatParams,
        }
      : null;
  }, [data, squeezeData, formatParams, field.id]);

  return (
    <ChartSelection
      chart={chart}
      data={chartData}
      selectedField={field}
      chartEvents={chartEvents}
      selectedResolution={"15 mins"}
    />
  );
};
