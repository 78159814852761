import { useMemo, useState } from "react";
import moment from "moment";
import EditDeleteTable from "./EditDeleteTable";
import { deleteParameter, updateParameter } from "../../api/parameters";
import UpdateParametersForm from "../forms/UpdateParametersForm";
import { useTheme } from "@material-ui/core";
import {
  analyticsTableToolbar,
  defaultTableOptions,
} from "../pages/Analytics/util";
import { useReloadChartData } from "../../data/useChartData";
import { db } from "../../data/Database";
import { UserState } from "../../context/auth";
import { ClientField } from "../../api/clients";
import type { Parameter, ParameterType } from "../../api/parameters";

const ParameterTable = ({
  data,
  user,
  field,
}: {
  data: { params: Parameter[]; types: ParameterType[] };
  user: UserState;
  field: ClientField;
}) => {
  const { reloadData } = useReloadChartData();
  const { params, types } = data;
  const [parameterId, setParameterId] = useState<number>();
  const parameterRecord = useMemo(
    () =>
      parameterId ? params.find((item) => item.id === parameterId) : undefined,
    [params, parameterId]
  );
  const parameters = useMemo(
    () =>
      types &&
      params?.length && {
        columns: [
          { title: "Timestamp", field: "timestamp_label" },
          { title: "Description", field: "description" },
          { title: "Type", field: "type_label" },
          { title: "Value", field: "value" },
        ],
        data: params?.map((item) => {
          const paramType = types.filter((elm) => elm.id === item.type);
          return {
            ...item,
            type_label: paramType.length ? paramType[0].name : "Unknown",
            timestamp_label: moment(item.timestamp)
              .tz(field.timezone)
              .format("DD/MM/YY HH:mm"),
          };
        }),
      },
    [params, types]
  );

  const handleParameterUpdate = async ({
    type,
    description,
    timestamp,
    group,
    value,
  }: Parameter) => {
    if (!(user.token && user.client.id && parameterId)) return;
    await updateParameter(user.token, user.client.id, field.id, parameterId, {
      type,
      description,
      group,
      timestamp: moment(timestamp).utc().format("YYYY-MM-DD HH:mm:ss"),
      value: typeof value === "string" ? parseFloat(value) : value,
    });
  };
  const handleParameterDelete = async () => {
    if (!(user.token && user.client.id && parameterId)) return;

    await deleteParameter(
      user.token,
      user.client.id,
      field.id,
      parameterId
    ).then(() => {
      // update local state
      // @ts-expect-error Database is not typed yet
      db.parameters.delete(parameterId);
    });
  };
  const handleParameterRefresh = () => {
    reloadData();
  };

  const theme = useTheme();
  const tableConfig = {
    title: "Parameters",
    components: analyticsTableToolbar(theme),
    options: defaultTableOptions,
  };

  return (
    <EditDeleteTable
      title="Parameters"
      type="Parameter"
      data={{ ...parameters, ...tableConfig }}
      record={parameterRecord}
      renderForm={(props) => (
        <UpdateParametersForm
          title="Amend the Parameter:"
          timezone={field.timezone}
          types={types}
          {...props}
        />
      )}
      setRecord={setParameterId}
      onUpdate={handleParameterUpdate}
      onDelete={handleParameterDelete}
      onRefresh={handleParameterRefresh}
      readonly={!user.isAdmin}
    />
  );
};

export default ParameterTable;
