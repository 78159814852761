import moment from "moment";
import AdminTable from "./AdminTable";
import {
  deleteField,
  deleteDataField,
  importHistoricData,
  refreshMildew,
  restoreField,
} from "../../../api/fields";
import { useTheme, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import { ClientField } from "../../../api/clients";

const useStyles = makeStyles((theme) => {
  const defaultIcon = {
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
  };
  return {
    iconOn: {
      ...defaultIcon,
      color: theme.palette.primary.main,
    },
    iconOff: {
      ...defaultIcon,
      color: theme.palette.error.main,
    },
  };
});

type ImportSummary = {
  timestamp: string;
  /** @deprecated from the old import summaries */
  status?: string;
  /** The last time a soil import was FULLY successful */
  last_soil_import?: string;
  /** The last time an ambient import was successful (always fully since ambient columns are required) */
  last_ambient_import?: string;
};

export type ParsedField = Omit<ClientField, "client_id" | "import_summary"> & {
  import_summary?: ImportSummary;
  client: { id: number; name?: string };
};

const FieldsTable = ({
  status,
  data,
  user,
  onRefresh,
  onRowClick,
  activeField,
}: {
  status: string[];
  data?: ParsedField[];
  user: any;
  onRefresh: () => void;
  onRowClick: (event: any, row: ParsedField) => void;
  activeField: any;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const config = {
    columns: [
      {
        title: "ID",
        field: "id",
        width: 30,
      },
      {
        title: "Name",
        field: "name",
        render: ({ name }: ParsedField) => <strong>{name}</strong>,
      },
      {
        title: "Farm",
        field: "farm",
      },
      {
        title: "Crop Type",
        field: "crop_type",
      },
      {
        title: "Field Type",
        field: "field_type",
        render: ({ field_type }: ParsedField) => field_type,
      },
      {
        title: "Client",
        field: "client",
        render: ({ client }: ParsedField) => client && client.name,
      },
      {
        title: "Import",
        field: "import",
        render: (rowData: ParsedField) => {
          const { import_summary, last_import } = rowData;
          return (
            <Tooltip
              title={
                <>
                  <Typography color="inherit">
                    {import_summary && import_summary.timestamp
                      ? moment
                          .utc(import_summary.timestamp)
                          .format("DD/MM/YY HH:mm")
                      : "Unknown"}
                  </Typography>
                  {import_summary && import_summary.last_soil_import && (
                    <p>{`Last soil data: ${moment
                      .utc(import_summary.last_soil_import)
                      .format("DD/MM/YY HH:mm")}`}</p>
                  )}
                  {import_summary && import_summary.last_ambient_import && (
                    <p>{`Last ambient data: ${moment
                      .utc(import_summary.last_ambient_import)
                      .format("DD/MM/YY HH:mm")}`}</p>
                  )}
                </>
              }
            >
              <span>
                {rowData.import ? (
                  <CloudDownloadIcon className={classes.iconOn} />
                ) : (
                  <CloudOffIcon className={classes.iconOff} />
                )}
                {last_import ? moment.utc(last_import).fromNow() : "Never"}
              </span>
            </Tooltip>
          );
        },
      },
    ],
    data,
    onRowClick,
    options: {
      pageSizeOptions: [5, 10, 20, 50],
      rowStyle: (row: ParsedField) => {
        return row && activeField && row.id === activeField.id
          ? { backgroundColor: theme.palette.secondary.main }
          : null;
      },
    },
  };

  const history = useHistory();
  const onCreate = () => history.push("/admin/field");
  // const onView = (id) => history.push(`/field/${id}`);
  const onEdit = (id: number) => history.push(`/admin/field/${id}`);
  const onDelete = (record: ParsedField) =>
    deleteField(user.token, record.client.id, record.id);
  const onDataDelete = (values: any) => deleteDataField(user.token, values);
  const onImport = (values: any) => importHistoricData(user.token, values);
  const onRefreshMildew = (values: any) => refreshMildew(user.token, values);
  const onRestore = (record: ParsedField) =>
    restoreField(user.token, record.client.id, record.id);

  return (
    <AdminTable
      title={<Typography variant="h2">Fields</Typography>}
      type="fields"
      config={config}
      onRefresh={onRefresh}
      onCreate={status?.includes("create") ? onCreate : undefined}
      onEdit={status?.includes("edit") ? onEdit : undefined}
      // onView={onView}
      onDelete={status?.includes("delete") ? onDelete : undefined}
      onDataDelete={status?.includes("dataDelete") ? onDataDelete : undefined}
      onImport={status?.includes("import") ? onImport : undefined}
      onRefreshMildew={
        status?.includes("refreshMildew") ? onRefreshMildew : undefined
      }
      onRestore={status?.includes("restore") ? onRestore : undefined}
    />
  );
};

export default FieldsTable;
