import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ClientsProvider } from "../../../context/clients";
import Layout from "../../layout/Layout";
import CardWarning from "../../cards/CardWarning";
import ManageClient from "./Client";
import ManageField from "./Field";
import ManageUser from "./User";
import ManageLogger from "./Logger";
import { ManageIrrigator } from "./Irrigator";
import { ManageIrrigatorCommand } from "./IrrigatorCommand";
import { ManageIrrigatorType } from "./IrrigatorType";

const Manage = () => {
  return (
    <ClientsProvider>
      <ManageContent />
    </ClientsProvider>
  );
};

const ManageContent = () => {
  const { model, id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const acceptedModels = [
    "client",
    "user",
    "field",
    "logger",
    "irrigator",
    "irrigator-command",
    "irrigator-type",
  ];
  return (
    <Layout loading={isLoading}>
      {!acceptedModels.includes(model) && (
        <CardWarning
          message={`"${model}" not an editable record`}
          color="disabled"
        />
      )}
      {model === "client" && (
        <ManageClient id={id} setIsLoading={setIsLoading} />
      )}
      {model === "field" && <ManageField id={id} setIsLoading={setIsLoading} />}
      {model === "user" && <ManageUser id={id} setIsLoading={setIsLoading} />}
      {model === "logger" && (
        <ManageLogger id={id} setIsLoading={setIsLoading} />
      )}
      {model === "irrigator" && (
        <ManageIrrigator id={id} setIsLoading={setIsLoading} />
      )}
      {model === "irrigator-command" && (
        <ManageIrrigatorCommand id={id} setIsLoading={setIsLoading} />
      )}
      {model === "irrigator-type" && (
        <ManageIrrigatorType id={id} setIsLoading={setIsLoading} />
      )}
    </Layout>
  );
};

export default Manage;
