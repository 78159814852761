import { handleRequestError } from "../helpers";
import api, { ServerResponse } from "./agritech";

export interface Irrigator {
  id: number;
  name: string;
  phone_number: string;
  client_id: number;
  irrigator_type_id: number;
}

export interface IrrigatorCommand {
  id: number;
  name: string;
  command: string;
  irrigator_type_id: number;
}

export interface IrrigatorType {
  id: number;
  name: string;
}

export interface Program {
  id: number;
  program_id: number;
  irrigator_id: number;
  name: string;
}

export async function listIrrigators(
  token: string
): Promise<ServerResponse<Irrigator[]>> {
  const response = await api.get<ServerResponse<Irrigator[]>>(`/irrigators`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

export async function listClientIrrigators(
  token: string,
  client_id: number
): Promise<ServerResponse<Irrigator[]>> {
  const response = await api.get<ServerResponse<Irrigator[]>>(
    `irrigators/${client_id}/irrigators`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export async function getIrrigator(
  token: string,
  id: number
): Promise<ServerResponse<Irrigator>> {
  const response = await api.get<ServerResponse<Irrigator>>(
    `/irrigators/${id}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export async function runIrrigator(
  token: string,
  client_id: number,
  irrigator_id: number,
  command_id: number
) {
  try {
    return await api.post<ServerResponse<[]>>(
      `${client_id}/irrigators/${irrigator_id}/run/${command_id}`,
      {},
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  } catch (error) {
    console.error(error);
    const e = { message: "Failed to run irrigator" };
    handleRequestError(e);
  }
}

export async function getIrrigatorAndPrograms(
  token: string,
  id: number
): Promise<ServerResponse<Irrigator & { programs: Program[] }>> {
  const response = await api.get<ServerResponse<Irrigator>>(
    `/irrigators/${id}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  const programs = await api.get<ServerResponse<Program[]>>(
    `/irrigators/${id}/programs`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return {
    ...response.data,
    data: { ...response.data.data, programs: programs.data.data },
  };
}

export async function addIrrigator(
  token: string,
  irrigator: Omit<Irrigator, "id">
) {
  return await api.post<ServerResponse<[]>>(`/irrigators`, irrigator, {
    headers: { Authorization: "Bearer " + token },
  });
}

export async function updateIrrigator(
  token: string,
  id: number,
  irrigator: Irrigator
) {
  return await api.patch<ServerResponse<[]>>(`/irrigators/${id}`, irrigator, {
    headers: { Authorization: "Bearer " + token },
  });
}

export async function assignIrrigator(
  token: string,
  id: number,
  client_id: number
) {
  return await api.patch<ServerResponse<[]>>(
    `/irrigators/${id}/client/${client_id}`,
    {},
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
}

// irrigator commands
export async function listIrrigatorCommands(
  token: string
): Promise<ServerResponse<IrrigatorCommand[]>> {
  const response = await api.get<ServerResponse<IrrigatorCommand[]>>(
    `/irrigator_commands`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export async function getIrrigatorCommand(
  token: string,
  id: number
): Promise<ServerResponse<IrrigatorCommand>> {
  const response = await api.get<ServerResponse<IrrigatorCommand>>(
    `/irrigator_commands/${id}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export async function addIrrigatorCommand(
  token: string,
  command: Omit<IrrigatorCommand, "id">
) {
  return await api.post<ServerResponse<[]>>(`/irrigator_commands`, command, {
    headers: { Authorization: "Bearer " + token },
  });
}

export async function updateIrrigatorCommand(
  token: string,
  id: number,
  irrigatorCommand: IrrigatorCommand
) {
  return await api.patch<ServerResponse<[]>>(
    `/irrigator_commands/${id}`,
    irrigatorCommand,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
}

export async function deleteIrrigatorCommand(token: string, id: number) {
  return await api.delete<ServerResponse<[]>>(`/irrigator_commands/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
}

// irrigator types
export async function listIrrigatorTypes(
  token: string
): Promise<ServerResponse<IrrigatorType[]>> {
  const response = await api.get<ServerResponse<IrrigatorType[]>>(
    `/irrigator_types`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export async function getIrrigatorType(
  token: string,
  id: number
): Promise<ServerResponse<IrrigatorType>> {
  const response = await api.get<ServerResponse<IrrigatorType>>(
    `/irrigator_types/${id}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export async function addIrrigatorType(
  token: string,
  type: Omit<IrrigatorType, "id">
) {
  return await api.post<ServerResponse<[]>>(`/irrigator_types`, type, {
    headers: { Authorization: "Bearer " + token },
  });
}

export async function updateIrrigatorType(
  token: string,
  id: number,
  irrigatorType: IrrigatorType
) {
  return await api.patch<ServerResponse<[]>>(
    `/irrigator_types/${id}`,
    irrigatorType,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
}

export async function deleteIrrigatorType(token: string, id: number) {
  return await api.delete<ServerResponse<[]>>(`/irrigator_types/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
}
