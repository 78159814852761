import { useState, useCallback, useEffect, useMemo } from "react";
import moment from "moment";
import {
  Grid,
  Fade,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core/";
import Layout from "../layout/Layout";
import Card from "../cards/Card";
import Loader from "../elements/Loader";
import CardWarning from "../cards/CardWarning";
import { useAuthState } from "../../context/auth";
import {
  useReportsState,
  useReportsDispatch,
  fetchReports,
  ReportsProvider,
} from "../../context/reports";
import Headline from "../elements/Headline";

const ReportsList = () => {
  return (
    <ReportsProvider>
      <Reports />
    </ReportsProvider>
  );
};

const Reports = () => {
  const dispatch = useReportsDispatch();
  const user = useAuthState();
  const [isLoading, setIsLoading] = useState(false);
  const { list } = useReportsState();

  const reports = useMemo(() => {
    const groups = new Map<number, typeof list>();
    if (list) {
      list.forEach((element) => {
        if (!groups.has(element.group)) {
          groups.set(element.group, []);
        }
        groups.get(element.group)!.push(element);
      });
    }

    return groups;
  }, [list]);

  const theme = useTheme();
  const {
    transitions: {
      duration: { enteringScreen, leavingScreen },
    },
  } = theme;
  const transitionDuration = {
    enter: enteringScreen,
    exit: leavingScreen,
  };

  const loadReports = useCallback(
    async (clientName) => {
      setIsLoading(true);
      try {
        await fetchReports(dispatch, clientName);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        dispatch({ type: "setActiveReport", payload: null });
      }
    },
    [dispatch]
  );

  // Load reports initially
  useEffect(() => {
    loadReports(user.client.name);
  }, [loadReports, user.client.name]);

  return (
    <Layout>
      <Loader
        active={isLoading}
        showChildren={false}
        message="Loading reports..."
      >
        {reports.size > 0 ? (
          <>
            <Headline headline="Reports" headlineVariant="h1" />
            <Grid container spacing={3}>
              {Array.from(reports.keys())
                .sort((a, b) => b - a)
                .map((group, index) => (
                  <Fade
                    key={`report-group-${index}`}
                    in={true}
                    timeout={transitionDuration}
                    style={{ transitionDelay: "1000ms" }}
                    unmountOnExit
                  >
                    <Grid item xs={12} md={4} lg={3} xl={2}>
                      <Card
                        headline={moment.unix(group).format("MMMM YYYY")}
                        headlineVariant="h3"
                        divider={true}
                      >
                        <List>
                          {reports.get(group)?.map((item) => (
                            <ListItem
                              key={`report-${item.id}`}
                              button
                              component="a"
                              href={`/report/${item.id}`}
                            >
                              <ListItemText>{item.title}</ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </Card>
                    </Grid>
                  </Fade>
                ))}
            </Grid>
          </>
        ) : (
          <CardWarning message="No reports found!" />
        )}
      </Loader>
    </Layout>
  );
};

export default ReportsList;
