import React, { useState, useEffect, PropsWithChildren } from "react";
import {
  makeStyles,
  Modal as MuiModal,
  Backdrop,
  Card,
  Slide,
} from "@material-ui/core";

const Modal: React.FC<
  PropsWithChildren<{ open: boolean; closeable?: boolean }>
> = ({ children, open, closeable }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = (event: unknown, reason: string) => {
    if (
      !closeable &&
      (reason === "backdropClick" || reason === "escapeKeyDown")
    ) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => setIsOpen(open), [open]);

  const useStyles = makeStyles(() => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      width: 450,
      maxWidth: "95%",
      outline: "none",
    },
  }));
  const classes = useStyles();

  return (
    <MuiModal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Slide direction="up" in={isOpen}>
        <Card className={classes.card} elevation={9}>
          {children}
        </Card>
      </Slide>
    </MuiModal>
  );
};

export default Modal;
