import React, { useMemo } from "react";
import config from "../../constants/charts";
import SettingsToggle from "./SettingsToggle";
import SettingsHeader from "./SettingsHeader";
import SettingsForm from "./SettingsForm";
import { useSidebarState, useSidebarDispatch } from "../../context/sidebar";
import { useSettingsState } from "../../context/settings";
import { useAuthState, usePermissions } from "../../context/auth";

const AnalyticsSettings = ({
  fieldId,
  clientId,
  loadingState,
}: {
  fieldId: string;
  clientId: string;
  loadingState: (state: boolean) => void;
}) => {
  const { open, settingsOpen } = useSidebarState();
  const permissions = usePermissions();
  const user = useAuthState();
  const {
    chartsSettings: { visibility },
  } = useSettingsState();

  const settings = useMemo(() => {
    const existing = visibility
      .filter(
        (item) =>
          item.field_id === fieldId &&
          config[item.ref] &&
          permissions.includes(config[item.ref].module_id)
      )
      .map((item) => ({
        ...item,
        label: config[item.ref] ? config[item.ref].label : "Unknown",
      }));
    const missing = Object.keys(config).filter(
      (item) =>
        !existing.find((setting) => setting.ref === item) &&
        permissions.includes(config[item as keyof typeof config].module_id)
    ) as (keyof typeof config)[];
    existing.push(
      ...missing.map((item, idx) => ({
        id: `new_${idx}`,
        client_id: clientId,
        field_id: fieldId,
        type: "chart" as const,
        ref: item,
        label: config[item].label,
        hidden: 0 as const,
        order: idx,
      }))
    );
    return existing;
  }, [visibility, fieldId, permissions]);

  const sidebarDispatch = useSidebarDispatch();
  const toggleDisplay = () => {
    !open && sidebarDispatch({ type: "setOpen", payload: !open });
    sidebarDispatch({ type: "setSettingsOpen", payload: !settingsOpen });
  };

  if (open && settingsOpen) {
    return (
      <SettingsForm
        user={user}
        label="chart"
        items={settings}
        subheader={<SettingsHeader close={toggleDisplay} />}
        setLoading={(state: boolean) => loadingState(state)}
      />
    );
  } else {
    return <SettingsToggle click={toggleDisplay} />;
  }
};

export default AnalyticsSettings;
