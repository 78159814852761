import { useMemo } from "react";
import moment from "moment";

import Chart from "./Chart";
import config from "../../constants/charts";
import { defaultChartOptions } from "../../helpers";
import { useMetricsState } from "../../context/metrics";
import { useAnalyticsState } from "../../context/analytics";

import { getChartConfig } from "../../helpers/getChartConfig";
import { useChartData } from "../../data/useChartData";
import { Resolution, roundTo } from "./Grouping";

const ChartRunOff: React.FC<{
  fieldId: number;
  chart: { id: keyof typeof config; group: string };
  range: any;
  theme: any;
  chartEvents: any;
  selectedResolution: Resolution;
}> = ({ fieldId, chart, range, theme, chartEvents, selectedResolution }) => {
  const { chartData } = useChartData(chart.group, range, fieldId);

  const {
    range: { start_date, end_date },
  } = useAnalyticsState();
  const calculateDifferenceInWeeks = () => {
    const msInWeek = 1000 * 60 * 60 * 24 * 7;
    const diffEpoch = end_date - start_date;
    const differenceInWeeks = diffEpoch / msInWeek;
    return differenceInWeeks;
  };
  let differenceInWeeks = calculateDifferenceInWeeks();

  const metrics = useMetricsState();
  const chartConfig = useMemo(() => {
    const axis = [{ yAxisID: "y" }, { yAxisID: "y1" }];
    const initialConfig = getChartConfig(chartData, chart.id, theme, metrics);
    const datasets = initialConfig.datasets.map((item, i) => {
      const data = getGroupedDatasets(
        item?.data,
        selectedResolution,
        differenceInWeeks,
        item?.type === "line"
      );
      return {
        ...item,
        data,
        ...axis[item?.type === "bar" ? 1 : 0],
      };
    });
    return {
      datasets,
    };
  }, [chartData, theme, chart.id, metrics, selectedResolution]);

  const chartOptions = useMemo(() => {
    const options = defaultChartOptions(
      config[chart.id].axis,
      config[chart.id].legend,
      range,
      chartEvents
    );
    return {
      ...options,
      scales: {
        ...options.scales,
        y: {
          type: "linear",
          display: true,
          position: "left",
          title: {
            display: true,
            text: "% Run-Off",
          },
          beginAtZero: true,
          suggestedMax: 100,
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",
          title: {
            display: true,
            text: "ml",
          },
          beginAtZero: true,
          grid: {
            borderDash: [4, 10],
          },
        },
      },
    };
  }, [chart.id, range]);

  return (
    <Chart
      chartRef={chartEvents.chartRef}
      data={chartConfig}
      options={chartOptions}
    />
  );
};

function getGroupedDatasets(
  data: {
    x: number;
    y: number;
    values: { run_off_in?: number; run_off_out?: number };
  }[],
  selectedResolution: Resolution,
  differenceInWeeks: number,
  calculateRunOffPercent: boolean
) {
  const groupedData = new Map<
    string,
    Map<
      number,
      { x: number; y: number; run_off_in?: number; run_off_out?: number }
    >
  >();
  let selectedRes = selectedResolution;
  //if selected resolution is 24 hours,  difference in days must be more than 1
  if (selectedRes === "24 hours") {
    const diffInDays = differenceInWeeks * 7;
    if (diffInDays <= 1) {
      selectedRes = "1 hour";
    }
  } else if (selectedRes === "15 mins") {
    //if selected resolution is 15 mins,  difference in weeks must be more than 2
    const diffInWeeks = differenceInWeeks;
    if (diffInWeeks >= 2) {
      selectedRes = "1 hour";
    }
  }

  let dateKeyFormat = "YYYY-MM-DD HH";
  if (selectedRes === "15 mins") {
    dateKeyFormat = "YYYY-MM-DD HH:mm";
  } else if (selectedRes === "1 hour") {
    dateKeyFormat = "YYYY-MM-DD HH";
  } else if (selectedRes === "24 hours") {
    dateKeyFormat = "YYYY-MM-DD";
  }

  // Group data based on date
  data.forEach((record) => {
    const date = moment(record.x).format(dateKeyFormat);
    const timeData: ReturnType<typeof groupedData.get> =
      groupedData.get(date) ?? new Map();
    timeData.set(record.x, {
      x: record.x,
      y: record.y > 0 ? record.y : 0,
      run_off_in: record.values?.run_off_in,
      run_off_out: record.values?.run_off_out,
    });
    groupedData.set(date, timeData);
  });

  // Sum data based on groups
  return Array.from(groupedData.entries()).map(([date, timeData]) => {
    const first = Array.from(timeData.values())[0];
    const res = {
      x: roundTo(first.x, selectedRes),
      y: 0,
      run_off_in: 0,
      run_off_out: 0,
    };
    if (calculateRunOffPercent) {
      for (const record of timeData.values()) {
        res.run_off_in += record.run_off_in ?? 0;
        res.run_off_out += record.run_off_out ?? 0;
        res.y =
          res.run_off_in > 0 && res.run_off_out > 0
            ? (res.run_off_out / res.run_off_in) * 100
            : res.y;
      }
    } else {
      for (const record of timeData.values()) {
        res.y += record.y;
      }
    }
    return res;
  });
}

export default ChartRunOff;
