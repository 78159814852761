import Card from "../../cards/Card";
import { Automation } from "../../../api/automation";
import { Irrigator } from "../../../api/irrigators";
import { IrrigatorCommand } from "../../../api/irrigators";
import FormButton from "../../forms/FormButton";
import { AutomationDisplay } from "./AutomationDisplay";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";

export const AutomationCard: React.FC<{
  automation: Automation;
  irrigators: Irrigator[];
  irrigatorCommands: IrrigatorCommand[];
  onDelete: () => void;
}> = ({ automation, irrigators, irrigatorCommands, onDelete }) => {
  const history = useHistory();
  const isDeleted = useMemo(
    () => automation.field_name === "Deleted Field",
    [automation]
  );
  const viewDetails = useCallback(() => {
    history.push(`/automation/config/${automation.id}`);
  }, []);

  let title = automation.name;
  if (automation.data.disabled) {
    title += " (disabled)";
  }

  return (
    <Card headline={title} headlineVariant="h3" divider={true}>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <AutomationDisplay
          automation={automation}
          irrigators={irrigators}
          irrigatorCommands={irrigatorCommands}
        />
        <Box flexGrow={1} justifyContent="center" display="flex">
          <FormButton
            text="Delete Automation"
            variant="contained"
            handleClick={onDelete}
          />
          {!isDeleted && (
            <FormButton
              text="View Details"
              variant="contained"
              color="primary"
              handleClick={viewDetails}
            />
          )}
        </Box>
      </Box>
    </Card>
  );
};
