import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
} from "../actionTypes";
const defaultState = {
  toast: [],
};

const Toast = (state = defaultState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        toast: [
          ...state.toast,
          {
            key: action.key,
            ...action.notice,
          },
        ],
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        toast: state.toast.map((notice) =>
          action.dismissAll || notice.key === action.key
            ? { ...notice, dismissed: true }
            : { ...notice }
        ),
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        toast: state.toast.filter((notice) => notice.key !== action.key),
      };

    default:
      return state;
  }
};

export default Toast;