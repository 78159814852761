import { ListAggregated } from "../api/agronomics";
import { db } from "./Database";
import moment from "moment";
import { shiftTimestamp } from "../helpers/timezones";
import { listParameters, listParameterTypes } from "../api/parameters";

let loader = {
  loadedQueries: [],
  token: "",
  clientId: "",
  fieldId: "",
  groups: [],
  user: null,
};

// Clear the database when user logs in / out
export const resetDataLoader = () => {
  loader = {
    loadedQueries: [],
    token: "",
    clientId: "",
    fieldId: "",
    timezone: "",
    groups: [],
    user: null,
  };
};

/**
 * DataLoader function used to initialise and load data from the API for the charts
 * @param {*} param0
 * @returns
 */
export const useDataLoader = ({ user, field = undefined, groups }) => {
  // Initialise fields necessary to send to API
  if (!loader.hasLoaded && user) {
    loader.token = user.token;
    loader.user = user;
  }
  if (groups) {
    // Update list of data groups (e.g. ambient_climate, soil_moisture...) to load from API
    // Each chart has it's own group, see constants/charts.js
    loader.groups = groups;
  }

  // If it hasn't loaded
  if (
    !loader.hasLoaded ||
    (user && loader.clientId !== user.client.id) ||
    (user && loader.fieldId !== field.id)
  ) {
    // Update client ID if it changes
    loader.clientId = user.client.id;

    // Update field ID if it changes
    loader.fieldId = field.id;
    loader.timezone = field.timezone;

    // Fetch parameter types once per client & field
    loader.hasLoaded = true;
    listParameterTypes(loader.token, loader.clientId, loader.fieldId).then(
      (res) => {
        const data = res?.data ?? [];
        db.parameter_types.clear();
        db.parameter_types.bulkAdd(data);
      }
    );
  }

  // Function to load data between a given date range
  const loadData = async (range, refresh) => {
    const endDate = moment(range.end_date).isAfter(moment())
      ? moment().subtract(1, "minute")
      : moment(range.end_date);
    const dateRange = {
      start_date: range.start_date.format("YYYY-MM-DD HH:mm:ss"),
      end_date: endDate.format("YYYY-MM-DD HH:mm:ss"),
    };
    const query = `${dateRange.start_date}-${dateRange.end_date}-${loader.fieldId}`;
    if (loader.loadedQueries.includes(query) && !refresh) {
      return;
    }
    loader.loadedQueries.push(query);

    try {
      // Load parameters for current field
      listParameters(
        loader.token,
        loader.clientId,
        loader.fieldId,
        dateRange
      ).then((res) => {
        const data =
          res?.data?.map((r) => {
            r.timestamp = moment.utc(r.timestamp).valueOf();
            if (!r.field_id) {
              r.field_id = loader.fieldId;
            }
            return r;
          }) ?? [];
        db.parameters
          .where(["field_id", "timestamp"])
          .between(
            [loader.fieldId, range.start_date.valueOf()],
            [loader.fieldId, range.end_date.valueOf()]
          )
          .delete();
        db.parameters.bulkPut(data);
      });

      // Load aggregated data from server for each chart (loading data for groups of charts selected)
      const res = await ListAggregated(
        loader.token,
        loader.clientId,
        loader.fieldId,
        dateRange,
        loader.groups
      );
      if (res) {
        loader.groups.forEach((group) => {
          const data =
            res.groups[group]?.data?.map((r) => {
              r.timestamp = shiftTimestamp(r.timestamp, loader.timezone);
              if (!r.field_id) {
                r.field_id = loader.fieldId;
              }
              return r;
            }) ?? [];
          db[group]
            ?.where(["field_id", "timestamp"])
            .between(
              [loader.fieldId, range.start_date.valueOf()],
              [loader.fieldId, range.end_date.valueOf()]
            )
            .delete();
          db[group]?.bulkPut(data);
        });
      }
    } catch (error) {
      console.error(error);
      loader.loadedQueries = [];
    }
  };

  const getData = (key) => {
    return db[key];
  };

  return { loadData, getData };
};
