import api from "./agritech";

async function ListSoilClimates(token, clientId, fieldId, range) {
  const response = await api.post(
    `${clientId}/climate/soil/${fieldId}/search`,
    range,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function addRootZoneMoistureAnnotations(token, clientId, values) {
  const response = await api.post(
    `${clientId}/charts/annotation`,
    { ...values },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

export { ListSoilClimates, addRootZoneMoistureAnnotations };
