import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@material-ui/core";
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SettingsIcon from "@material-ui/icons/Settings";
import AutomationIcon from "@material-ui/icons/Memory";
import { useHistory } from "react-router-dom";
import { sidebarStyles } from "./SidebarContent";
import { useAnalyticsDispatch } from "../../context/analytics";
import { useAuthState, useHasAccess } from "../../context/auth";

import { Modules } from "../../constants/modules";

const NavlistItem: React.FC<{ title: string; onClick: () => void }> = (
  props
) => {
  const classes = sidebarStyles();

  return (
    <ListItem button component="li" {...props}>
      <ListItemIcon>{props.children}</ListItemIcon>
      <ListItemText>
        <Box component="span" className={classes.navText}>
          {props.title}
        </Box>
      </ListItemText>
    </ListItem>
  );
};

const MainNav: React.FC = () => {
  const history = useHistory();
  const dispatch = useAnalyticsDispatch();
  const { isSomewhatAdmin } = useAuthState();
  const hasAutomationAccess = useHasAccess(Modules.AUTOMATION);
  const classes = sidebarStyles();
  const resetActiveField = () => {
    dispatch({ type: "setActiveField", payload: null });
  };
  const redirect = (url: string) => {
    resetActiveField();
    history.push(url);
  };
  return (
    <List>
      <NavlistItem title="Fields" onClick={() => redirect("/")}>
        <LocalFloristIcon className={classes.listItemIcon} />
      </NavlistItem>
      <NavlistItem title="Reports" onClick={() => redirect("/reports")}>
        <AssignmentIcon className={classes.listItemIcon} />
      </NavlistItem>
      {hasAutomationAccess && (
        <NavlistItem title="Automation" onClick={() => redirect("/automation")}>
          <AutomationIcon className={classes.listItemIcon} />
        </NavlistItem>
      )}
      {isSomewhatAdmin && (
        <NavlistItem title="Admin" onClick={() => redirect("/admin")}>
          <SettingsIcon className={classes.listItemIcon} />
        </NavlistItem>
      )}
    </List>
  );
};

export default MainNav;
