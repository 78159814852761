import { createContext, useReducer, useContext, Dispatch } from "react";

type FieldsState = { farm: string; crop_type: string };
const FieldsFiltersStateContext = createContext<FieldsState | undefined>(
  undefined
);

type FieldsAction =
  | {
      type: "fieldsFilterSelection";
      payload: Partial<FieldsState>;
    }
  | { type: "resetFilters" };
type FieldsDispatch = Dispatch<FieldsAction>;
const FieldsFiltersDispatchContext = createContext<FieldsDispatch>(() => null);

const FieldsFiltersLocalStorageKey = "fieldsFilters";

function fieldsFiltersReducer(state: FieldsState, action: FieldsAction) {
  switch (action.type) {
    case "fieldsFilterSelection": {
      const updatedState = {
        ...state,
        ...action.payload,
      };

      // if local storage is available, save the filters to local storage
      if (Storage !== undefined) {
        localStorage.setItem(
          FieldsFiltersLocalStorageKey,
          JSON.stringify(updatedState)
        );
      }

      return updatedState;
    }
    case "resetFilters": {
      return defaultFilters(true);
    }
  }
}

const defaultFilters = (skipLocalStorage = false): FieldsState => {
  if (Storage !== undefined && !skipLocalStorage) {
    const stored = localStorage.getItem(FieldsFiltersLocalStorageKey);
    const localFilters = stored && JSON.parse(stored);
    if (localFilters) {
      return localFilters;
    }
  }

  return { farm: "", crop_type: "" };
};

const FieldsFiltersProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(fieldsFiltersReducer, defaultFilters());
  return (
    <FieldsFiltersStateContext.Provider value={state}>
      <FieldsFiltersDispatchContext.Provider value={dispatch}>
        {children}
      </FieldsFiltersDispatchContext.Provider>
    </FieldsFiltersStateContext.Provider>
  );
};

function useFieldsFiltersState() {
  const context = useContext(FieldsFiltersStateContext);
  if (context === undefined) {
    throw new Error(
      "useFieldsFiltersState must be used within a FieldsFiltersProvider"
    );
  }
  return context;
}

function useFieldsFiltersDispatch() {
  const context = useContext(FieldsFiltersDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useFieldsFiltersDispatch must be used within a FieldsFiltersProvider"
    );
  }
  return context;
}

export {
  fieldsFiltersReducer,
  FieldsFiltersProvider,
  useFieldsFiltersState,
  useFieldsFiltersDispatch,
  FieldsFiltersLocalStorageKey,
};
