import React from "react";
import { render } from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import { store } from "./redux/store";
import { SnackbarProvider } from "notistack";
import * as serviceWorker from "./serviceWorker";
import theme from "./constants/theme";
import App from "./components/App";
import { AuthProvider } from "./context/auth";

import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import FillerPlugin from './plugins/chartjs-plugin-filler';
import ZoomPlugin from './plugins/chartjs-plugin-zoom/src';
import { CrosshairPlugin } from './plugins/chartjs-plugin-crosshair/index.esm';
import 'chartjs-adapter-moment';
import "./assets/app.css";

Chart.register(ZoomPlugin);
Chart.register(FillerPlugin);
Chart.register(CrosshairPlugin);
Chart.register(annotationPlugin);

Sentry.init({
  dsn:
    "https://4cf03058cb524367844236ce986afa30@sentry.cambridgekinetics.com/7",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
