import moment from "moment";
import chartsConfig from "../constants/charts";
import { metricColor, rgbToRgba } from ".";

export const getChartConfig = (data, chartType, theme, metrics, field) => {
  const lines = Object.keys(chartsConfig[chartType].metrics);
  return {
    datasets: lines
      .map((ref) => {
        const chartMetricConfig = chartsConfig[chartType].metrics[ref];
        const type = chartMetricConfig ? chartMetricConfig.type : null;
        const hidden = chartMetricConfig ? chartMetricConfig.hidden : null;
        const metric = metrics.filter((item) => item.ref === ref);
        const metricsSettings = metric.length ? metric[0] : null;
        let minValue = Infinity;
        const color = metricsSettings
          ? metricColor(theme, metricsSettings.color)
          : theme.palette.grey[500];

        let dataset = [];
        let hasValue = false;
        if (typeof chartMetricConfig.aggregation == "function") {
          // This calculates values as a function of time, using a custom aggregation function
          dataset = chartMetricConfig.aggregation(
            data?.sort((a, b) => {
              return moment(a.timestamp).diff(b.timestamp);
            }) ?? []
          );
          hasValue = true;
        } else {
          // Processes each row of data and finds the value for the current metric

          dataset =
            data?.map((record) => {
              let y = record[chartMetricConfig.property ?? ref];

              if (chartMetricConfig.formatter) {
                // Uses custom formatter function to get the value if set (e.g. if y = sum of other metrics)
                y = chartMetricConfig.formatter(record, field);
              } else {
                // Ensures y is a number value
                y = !!y ? parseFloat(y) : y;
              }
              if (y !== undefined) {
                hasValue = true;
              }
              if (y < minValue) {
                minValue = y;
              }
              return {
                x: record.timestamp,
                y,
                values: chartMetricConfig.includeValues ? record : undefined,
                label: ref === "squeeze" ? record.label : undefined,
              };
            }) ?? [];
        }

        if (!hasValue) {
          return undefined;
        }

        return {
          xAxisID: "x",
          label: metricsSettings ? metricsSettings.short_name : "Unknown",
          type: type !== undefined ? (type === "area" ? "line" : type) : "line",
          hidden: hidden !== undefined ? hidden : false,
          data: dataset,
          fill: type !== undefined ? (type === "area" ? true : false) : false,
          borderWidth: 2,
          borderColor: color,
          property: chartMetricConfig.property ?? ref,
          backgroundColor:
            type !== undefined && color !== undefined
              ? type === "area"
                ? rgbToRgba(color, 0.4)
                : color
              : color,
          pointRadius: ref === "squeeze" ? 5 : 0,
          pointBorderColor: color,
          pointBackgroundColor: color,
          datalabels: {
            display: type === "scatter",
          },
          parsing: false,
          barPercentage: 0.8,
          barThickness: "flex",
          maxBarThickness: 20,
          spanGaps: true,
          helpers: { minValue },
        };
      })
      .filter((r) => r !== undefined),
  };
};
