import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import ChartSelection from "../../../../charts/ChartSelection";
import {
  useChartTables,
  useChartDropdownActions,
  useChartEvents,
} from "./hooks";
import CardLayout from "./components/CardLayout";
import { ReactComponent as IconLogger } from "../../../../../assets/images/icons/icon-logger.svg";
import { ReactComponent as IconFarmer } from "../../../../../assets/images/icons/icon-farmer.svg";
import ListItemWarning from "../../../../elements/ListItemWarning";

const useStyles = makeStyles((theme) => ({
  chart: {
    height: "100%",
    "& .reset-zoom": {
      ...theme.typography.button,
      fontSize: theme.typography.pxToRem(13),
      border: 0,
      cursor: "pointer",
      outline: 0,
      position: "relative",
      userSelect: "none",
      margin: "5px auto",
      display: "block",
      padding: "4px 5px",
      minWidth: 64,
      borderRadius: theme.shape.borderRadius,
      boxSizing: "border-box",
      transition: theme.transitions.create(
        ["background-color", "box-shadow", "border"],
        {
          duration: theme.transitions.duration.short,
        }
      ),
    },
  },
}));

const Card = ({ loading, data, chart, tables: tableList }) => {
  const classes = useStyles();
  const defaultActions = useChartDropdownActions();
  const chartEvents = useChartEvents();
  const { tables, tableActions } = useChartTables(tableList, chart, data);

  const options = useMemo(
    () => [{ ...defaultActions }, { ...tableActions }],
    [defaultActions, tableActions]
  );

  // get last selected resolution
  const resolutionKey = `resolutionOptions-${chart.label.replace(/\s/g, "_").toLowerCase()}`;
  let lastResolution = localStorage.getItem(resolutionKey);
  const [resolution, setResolution] = useState(
    lastResolution ?? "15 mins"
  );

  return (
    <CardLayout
      title={chart.label}
      data={data}
      loading={loading}
      options={options}
      className={classes.chart}
      chartEvents={chartEvents}
      setResolution={setResolution}
    >
      {data ? (
        <div className="chart-content">
          <ChartSelection
            chart={chart}
            data={data}
            chartEvents={chartEvents}
            selectedResolution={resolution}
          />
        </div>
      ) : (
        <ListItemWarning
          icon={chart?.module_id === 2 ? <IconFarmer /> : <IconLogger />}
          message="No data found for this field within the selected date range."
        />
      )}
      {tables?.map(({ display, table }) => (display && table ? table : null))}
    </CardLayout>
  );
};

export default Card;
