import { makeStyles } from "@material-ui/core";
export const sidebarStyles = makeStyles((theme) => ({
  settingsList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  navText: {
    textTransform: "uppercase",
    fontWeight: 700,
    color: theme.palette.text.secondary,
  },
  listItem: {
    backgroundColor: theme.palette.secondary.main,
  },
  listItemIcon: {
    width: 30,
    height: 30,
    [theme.breakpoints.up("md")]: {
      marginLeft: 5,
    },
  },
  closeIcon: {
    color: theme.palette.text.disabled,
    minWidth: 40,
  },
  formButton: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));
