import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Picker from "./Picker";

const DatePickerToFrom = ({
  timestampFrom,
  onChangeFrom,
  timestampTo,
  onChangeTo,
  color,
  disabled,
  maxDays,
  disableTo,
}) => {
  // restrict from date to maxDays days from current date
  var date = new Date();
  if (maxDays !== null) {
    date.setDate(date.getDate() - maxDays);
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Picker
        label="FROM:"
        timestamp={timestampFrom}
        onChange={onChangeFrom}
        color={color}
        disabled={disabled}
        minDate={maxDays ? date : undefined}
      />
      {!disableTo && (
        <Picker
          label="TO:"
          timestamp={timestampTo}
          onChange={onChangeTo}
          color={color}
          disabled={disabled}
          minDate={timestampFrom}
        />
      )}
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerToFrom;
