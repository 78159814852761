import React, { useState, useMemo } from "react";
import moment from "moment";
import { IconButton, makeStyles } from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { useAnalyticsState } from "../../../../../context/analytics";
import CardWarning from "../../../../cards/CardWarning";
import ChartsList from "../../../../charts/ChartsList";
import Loader from "../../../../elements/Loader";
import Layout from "../../../../layout/Layout";
import AnalyticsSettings from "../../../../sidebar/AnalyticsSettings";
import Header from "../Header";
import AnalyticsFooter from "../Footer";
import Fab from "../../../../elements/Fab";
import Copyright from "../../../../elements/Copyright";

const AnalyticsLayout = ({ fieldId, loading }) => {
  const [status, setStatus] = loading;
  const {
    field,
    range: { start_date, end_date },
  } = useAnalyticsState();

  const range = useMemo(() => {
    if (!field?.timezone) {
      return { start_date, end_date };
    }
    return {
      start_date: moment(
        start_date.clone().tz(field.timezone).format("YYYY-MM-DD HH:mm:ss")
      ),
      end_date: moment(
        end_date.clone().tz(field.timezone).format("YYYY-MM-DD HH:mm:ss")
      ),
    };
  }, [end_date, start_date, field]);

  const [showFooter, setShowFooter] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      paddingBottom: 78,
    },
    cta: {
      display: "flex",
    },
  }));
  const { root, cta } = useStyles();
  const toggleFooter = () => {
    setShowFooter(!showFooter);
  };

  return (
    <Layout
      loading={status === "pending"}
      disableGutters={true}
      header={
        <div className={cta}>
          <IconButton onClick={() => toggleFooter()}>
            <ScheduleIcon />
          </IconButton>
          <Fab />
        </div>
      }
      sidebar={
        field && (
          <AnalyticsSettings
            key="analytics-settings"
            clientId={field.client_id}
            fieldId={field.id}
            loadingState={(state) => setStatus(state)}
          />
        )
      }
      footer={showFooter ? <AnalyticsFooter /> : null}
    >
      <Loader
        active={status === "pending"}
        showChildren={false}
        message="Loading the field..."
      >
        {field ? (
          <div className={root}>
            <Header />
            <ChartsList field={field} range={range} />
            <Copyright />
          </div>
        ) : (
          <CardWarning
            message={`Field ${fieldId} not found`}
            color="disabled"
          />
        )}
      </Loader>
    </Layout>
  );
};

export default AnalyticsLayout;
