import React from "react";
import { makeStyles, CardContent, Typography } from "@material-ui/core";
import FormButton from "../forms/FormButton";
import DatePickerToFrom from "../elements/DatePickerToFrom";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const ImportHistoricData = ({
  title,
  id,
  waiting,
  onImport,
  onStartDateChanged,
  onEndDateChanged,
  dateTimeValue,
  closeModal,
}) => {
  const classes = useStyles();

  const setTimestamp = (timestamp, timezone) =>
    timezone ? timestamp.clone().tz() : timestamp;

  return (
    <CardContent>
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>

      <DatePickerToFrom
        timestampFrom={setTimestamp(dateTimeValue.start_date)}
        onChangeFrom={onStartDateChanged}
        timestampTo={setTimestamp(dateTimeValue.end_date)}
        onChangeTo={onEndDateChanged}
        maxDays={70}
        color="green"
      />
      <div className={classes.buttonGroup}>
        <FormButton text="Close" handleClick={closeModal} />
        <FormButton
          text="Import"
          disabled={waiting}
          handleClick={() => onImport()}
          variant="contained"
          color="primary"
          waiting={waiting}
          waitingText="importing"
        />
      </div>
      {waiting && (
        <Typography gutterBottom>
          Importing, this may take few minutes
        </Typography>
      )}
    </CardContent>
  );
};

export default ImportHistoricData;
