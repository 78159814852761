import React from "react";
import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as Accent } from "../../assets/images/triangle.svg";
import UserMenu from "../header/UserMenu";
import { useSidebarState } from "../../context/sidebar";

const Header = ({ children, drawerWidth, onToggleSidebar }) => {
  const { open } = useSidebarState();
  const useStyles = makeStyles((theme) => ({
    toolbar: {
      justifyContent: "space-between",
    },
    appBar: {
      boxShadow: "none",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    uiNav: {
      marginLeft: 100,

      [theme.breakpoints.up("md")]: {
        marginLeft: open ? drawerWidth : 72 + 64 + theme.spacing(3),
      },
      transition: theme.transitions.create(["margin-left"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    logoContainer: {
      display: "flex",
      position: "fixed",
      left: 0,
      top: 0,
    },
    logo: {
      width: 72,
      height: 64,
      padding: theme.spacing(1),
      background: theme.palette.common.white,
      [theme.breakpoints.down("xs")]: {
        width: 56,
        height: 56,
      },
    },
    accent: {
      width: 64,
      height: "100%",
      [theme.breakpoints.down("xs")]: {
        width: 56,
        marginLeft: -1,
      },
    },
    buttonContainer: {
      flex: "0 0 auto",
      display: "inline-flex",
    },
  }));
  const {
    appBar,
    toolbar,
    logoContainer,
    logo,
    accent,
    uiNav,
    buttonContainer,
  } = useStyles();

  return (
    <AppBar className={appBar}>
      <Toolbar className={toolbar} disableGutters={true}>
        <div className={logoContainer}>
          <Logo className={logo} />
          <Accent className={accent} />
        </div>
        <div className={uiNav}>{children}</div>
        <div>
          <div className={buttonContainer}>
            <UserMenu />
            <Hidden lgUp>
              <IconButton onClick={onToggleSidebar}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
