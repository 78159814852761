import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AltDeleteIcon from "@material-ui/icons/DeleteOutline";
import SyncIcon from "@material-ui/icons/Sync";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReplayIcon from "@material-ui/icons/Replay";
import Table from "./Table";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(3),
  },
  icon: {
    width: 16,
    height: 16,
  },
}));

const ActionTable = ({
  config,
  onCreate,
  onEdit,
  onView,
  onDelete,
  onDataDelete,
  onImport,
  onRefreshMildew,
  onRestore,
  styles,
}) => {
  const classes = useStyles();
  const [actions, setActions] = useState(null);
  const tableStyle = styles && styles.table ? styles.table : classes.table;
  const iconStyle = styles && styles.icon ? styles.icon : classes.icon;

  useEffect(() => {
    const actionHandler = () => {
      const handleView = onView && {
        icon: () => <VisibilityIcon className={iconStyle} color="action" />,
        tooltip: onView.tooltip,
        onClick: (event, row) => onView.handleClick(event, row),
      };
      const handleEdit = onEdit && {
        icon: () => <EditIcon className={iconStyle} color="action" />,
        tooltip: onEdit.tooltip,
        onClick: (event, row) => onEdit.handleClick(event, row),
      };
      const handleDelete = onDelete && {
        icon: () => <DeleteIcon className={iconStyle} color="action" />,
        tooltip: onDelete.tooltip,
        onClick: (event, row) => onDelete.handleClick(event, row),
      };
      const handleDataDelete = onDataDelete && {
        icon: () => <AltDeleteIcon className={iconStyle} color="action" />,
        tooltip: onDataDelete.tooltip,
        onClick: (event, row) => onDataDelete.handleClick(event, row),
      };
      const handleImport = onImport && {
        icon: () => <SyncIcon className={iconStyle} color="action" />,
        tooltip: onImport.tooltip,
        onClick: (event, row) => onImport.handleClick(event, row),
      };
      const handleRefreshMildew = onRefreshMildew && {
        icon: () => <AvTimerIcon className={iconStyle} color="action" />,
        tooltip: onRefreshMildew.tooltip,
        onClick: (event, row) => onRefreshMildew.handleClick(event, row),
      };
      const handleCreate = onCreate && {
        icon: () => <AddCircleIcon color="primary" />,
        tooltip: onCreate.tooltip,
        isFreeAction: true,
        onClick: (event) => onCreate.handleClick(event),
      };
      const handleRestore = onRestore && {
        icon: () => <ReplayIcon className={iconStyle} color="action" />,
        tooltip: onRestore.tooltip,
        onClick: (event, row) => onRestore.handleClick(event, row),
      };
      const selectedActions = [
        handleCreate,
        handleView,
        handleImport,
        handleRefreshMildew,
        handleDataDelete,
        handleEdit,
        handleDelete,
        handleRestore,
      ].map((callback) => callback);

      if (selectedActions.length) {
        return selectedActions;
      }

      return null;
    };
    setActions(actionHandler());
  }, [
    onCreate,
    onView,
    onEdit,
    onDelete,
    onDataDelete,
    onImport,
    onRefreshMildew,
    onRestore,
    iconStyle,
  ]);

  return (
    <div className={tableStyle}>
      <Table {...config} actions={actions} />
    </div>
  );
};

export default ActionTable;
