import { useEffect } from "react";

import { useAuthState } from "../context/auth";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../data/Database";
import { useDataLoader } from "../data/DataLoader";
import { useAnalyticsState } from "../context/analytics";

export const useChartData = (group, range, fieldId, field = undefined) => {
  const user = useAuthState();
  const dataLoaderObject = { user: null };
  if (field) {
    dataLoaderObject.user = user;
    dataLoaderObject.field = field;
  }
  const { loadData } = useDataLoader(dataLoaderObject);
  const {
    range: { start_date, end_date },
  } = useAnalyticsState();

  let timer;
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => loadData({ start_date, end_date }), 200);
  }, [start_date, end_date, fieldId]);

  // Use range from chart (i.e. parameter range) as it's in local time for display
  const chartData = useLiveQuery(() => {
    if (group === "parameter_types") {
      return db.parameter_types.toArray() ?? [];
    } else if (group === "parameters") {
      return db[group ?? ""]?.where("field_id").equals(fieldId).toArray() ?? [];
    } else {
      return (
        db[group ?? ""]
          ?.where(["field_id", "timestamp"])
          .between(
            [fieldId, range.start_date.valueOf()],
            [fieldId, range.end_date.valueOf()]
          )
          .toArray() ?? []
      );
    }
  }, [start_date, end_date, fieldId]);

  const reloadData = () => {
    loadData({ start_date, end_date }, 200);
  };

  return { chartData, reloadData };
};

export const useReloadChartData = () => {
  const {
    range: { start_date, end_date },
  } = useAnalyticsState();

  const reloadData = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { loadData } = useDataLoader({ user: null });
    loadData({ start_date, end_date }, true);
  };

  return { reloadData };
};
