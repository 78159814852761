import { ServerResponse } from "./agritech";
import api from "./agritech";

export type ParameterType = {
  id: number;
  name: string;
  slug: string;
};
export async function listParameterTypes(
  token: string
): Promise<ServerResponse<ParameterType[]>> {
  const response = await api.get(`/parameter-types`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

export type Parameter = {
  id: number;
  client_id: number;
  field_id: number;
  type: number;
  group: string;
  description: string;
  value: string | number;
  timestamp: string;
};

export async function listParameters(
  token: string,
  clientId: number,
  fieldId: number,
  range?: { start_date: string; end_date: string }
): Promise<ServerResponse<Parameter[]>> {
  const response = await api.post(
    `${clientId}/parameters/${fieldId}/search`,
    range,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

export async function saveParameter(
  token: string,
  clientId: number,
  fieldId: number,
  value: Parameter
) {
  const response = await api.post(`${clientId}/parameters/${fieldId}`, value, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

export async function updateParameter(
  token: string,
  clientId: number,
  fieldId: number,
  id: number,
  value: Partial<Parameter>
) {
  const response = await api.patch(
    `${clientId}/parameters/${fieldId}/${id}`,
    value,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

export async function deleteParameter(
  token: string,
  clientId: number,
  fieldId: number,
  id: number
) {
  const response = await api.delete(`${clientId}/parameters/${fieldId}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}
