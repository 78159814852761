import React, { useState } from "react";
import moment from "moment";
import ActionTable from "../../tables/ActionTable";
import { makeStyles } from "@material-ui/core";
import DeleteRecord from "../../actions/DeleteRecord";
import ImportHistoricData from "../../actions/ImportHistoricData";
import RefreshMildew from "../../actions/RefreshMildew";
import DeleteFieldData from "../../actions/DeleteFieldData";
import { handleRequestError, triggerToast } from "../../../helpers";
import Modal from "../../elements/Modal";
import { useDispatch } from "react-redux";
import { Moment } from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(3),
  },
}));

type FieldInfo = {
  fieldId: number;
  from: string;
  to: string;
};

const AdminTable = <T extends { id: number }>({
  title,
  type,
  config,
  onCreate,
  onView,
  onEdit,
  onDelete,
  onDataDelete,
  onImport,
  onRefreshMildew,
  onRefresh,
  onRestore,
  deleteLabel = "name" as string & keyof T,
}: {
  title: string | React.ReactNode;
  type: string;
  config: any;
  onCreate?: () => void;
  onView?: (id: number, row: T) => void;
  onEdit?: (id: number) => void;
  onDelete?: (row: T) => Promise<any>;
  onDataDelete?: (info: FieldInfo) => Promise<any>;
  onImport?: (info: FieldInfo) => Promise<any>;
  onRefreshMildew?: (info: { fieldId: number; start: string }) => Promise<any>;
  onRefresh?: () => void;
  onRestore?: (row: T) => Promise<any>;
  deleteLabel?: string & keyof T;
}) => {
  const classes = useStyles();
  const [record, setRecord] = useState<T>();
  const [waiting, setWaiting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  // importing historic data vars
  const [importWaiting, setImportWaiting] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const openImportModal = () => setImportModalOpen(true);
  const closeImportModal = () => setImportModalOpen(false);

  // refreshing mildew vars
  const [mildewWaiting, setMildewWaiting] = useState(false);
  const [mildewModalOpen, setMildewModalOpen] = useState(false);
  const openMildewModal = () => setMildewModalOpen(true);
  const closeMildewModal = () => setMildewModalOpen(false);

  // deleting data  vars
  const [deleteFieldDataWaiting, setDeleteFieldDataWaiting] = useState(false);
  const [deleteFieldDataModalOpen, setDeleteFieldDataModalOpen] =
    useState(false);
  const openDeleteFieldDataModal = () => setDeleteFieldDataModalOpen(true);
  const closeDeleteFieldDataModal = () => setDeleteFieldDataModalOpen(false);

  const dispatch = useDispatch();

  const deleteRecord = (event: unknown, row: T) => {
    openModal();
    setRecord(row);
  };

  // delete data modal date range
  const [deleteDataRange, setDeleteDataRange] = useState({
    start_date: moment().utc(),
    end_date: moment().utc(),
  });

  //handle dates change for deleting data modal
  const handleDeleteDataStartDateChange = (e: Moment) => {
    setDeleteDataRange({
      start_date: e.clone().utc(),
      end_date: deleteDataRange.end_date,
    });
  };
  const handleDeleteDataEndDateChange = (e: Moment) =>
    setDeleteDataRange({
      start_date: deleteDataRange.start_date,
      end_date: e.clone().utc(),
    });

  const deleteData = (event: unknown, row: T) => {
    openDeleteFieldDataModal();
    setRecord(row);
  };

  const [deleteAgroData, setDeleteAgroData] = useState(false);

  const tables = {
    agronomics: {
      value: false,
      onChange: () => {
        setDeleteAgroData(!deleteAgroData);
      },
    },
  };

  const handleDataDelete = async () => {
    setDeleteFieldDataWaiting(true);
    try {
      const fieldId = record!.id;
      const from = moment(deleteDataRange.start_date).format(
        "YYYY-MM-DD HH:mm"
      );
      const to = moment(deleteDataRange.end_date).format("YYYY-MM-DD HH:mm");
      const values = { fieldId, from, to };

      const response = await onDataDelete?.(values);
      onRefresh?.();
      dispatch(
        triggerToast(response.message ?? "Data deleted succesfully", {
          variant: "success",
        })
      );
      setDeleteFieldDataWaiting(false);
      closeDeleteFieldDataModal();
    } catch (e) {
      setDeleteFieldDataWaiting(false);
      handleRequestError(e, `Failed to delete data ${type}: `);
    }
  };

  // import data modal date range
  const [importDataRangevalue, setImportDataRangevalue] = useState({
    start_date: moment().utc(),
    end_date: moment().utc(),
  });
  //handle dates change for importing data modal
  const handleStartDateChange = (e: Moment) =>
    setImportDataRangevalue({
      start_date: e.clone().utc(),
      end_date: importDataRangevalue.end_date,
    });

  const handleEndDateChange = (e: Moment) =>
    setImportDataRangevalue({
      start_date: importDataRangevalue.start_date,
      end_date: e.clone().utc(),
    });

  const importData = (event: unknown, row: T) => {
    openImportModal();
    setRecord(row);
  };

  const [mildewRefreshFrom, setmildewRefreshFrom] = useState({
    start_date: moment().utc(),
  });

  const handleMildewDateChange = (e: Moment) =>
    setmildewRefreshFrom({
      start_date: e.clone().utc(),
    });

  const refreshMildew = (event: unknown, row: T) => {
    openMildewModal();
    setRecord(row);
  };

  const handleDelete = async () => {
    setWaiting(true);
    try {
      const response = await onDelete?.(record!);
      onRefresh?.();
      dispatch(triggerToast(response.message, { variant: "success" }));
      setWaiting(false);
      closeModal();
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, `Failed to delete ${type}: `);
    }
  };
  const handleImport = async () => {
    setImportWaiting(true);
    try {
      const fieldId = record!.id;
      const from = moment(importDataRangevalue.start_date).format(
        "YYYY-MM-DD HH:mm"
      );
      const to = moment(importDataRangevalue.end_date).format(
        "YYYY-MM-DD HH:mm"
      );

      const values = { fieldId, from, to };

      const response = await onImport?.(values);
      onRefresh?.();
      dispatch(
        triggerToast(response.message ?? "Data imported succesfully", {
          variant: "success",
        })
      );
      setImportWaiting(false);
      closeImportModal();
    } catch (e) {
      setImportWaiting(false);
      handleRequestError(e, `Failed to import ${type}: `);
    }
  };
  const handleMildewRefresh = async () => {
    setMildewWaiting(true);
    try {
      const fieldId = record!.id;
      const start = moment(mildewRefreshFrom.start_date).format(
        "YYYY-MM-DD HH:mm"
      );

      const values = { fieldId, start };

      const response = await onRefreshMildew?.(values);
      onRefresh?.();
      dispatch(
        triggerToast(response.message ?? "Mildew data refreshed succesfully", {
          variant: "success",
        })
      );
      setImportWaiting(false);
      closeMildewModal();
    } catch (e) {
      setMildewWaiting(false);
      handleRequestError(e, `Failed to refresh ${type}: `);
    }
  };

  const handleRestore = async (event: unknown, row: T) => {
    setWaiting(true);
    setRecord(row);
    try {
      const response = await onRestore?.(row);
      onRefresh?.();
      dispatch(triggerToast(response.message, { variant: "success" }));
      setWaiting(false);
      closeModal();
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, `Failed to restore ${type}: `);
    }
  };

  const handleEdit = (event: unknown, row: T) => {
    onEdit?.(row.id);
  };

  const handleView = (event: unknown, row: T) => {
    onView?.(row.id, row);
  };

  return (
    <>
      <ActionTable
        config={{
          ...config,
          options: {
            ...config.options,
            pageSizeOptions: [5, 10, 20, 50],
            actionsColumnIndex: -1,
          },
          title,
        }}
        onCreate={
          onCreate && {
            tooltip: `Add ${type}`,
            handleClick: onCreate,
          }
        }
        onView={
          onView && {
            tooltip: `View ${type}`,
            handleClick: handleView,
          }
        }
        onEdit={
          onEdit && {
            tooltip: `Edit ${type}`,
            handleClick: handleEdit,
          }
        }
        onDelete={
          onDelete && {
            tooltip: `Delete ${type}`,
            handleClick: deleteRecord,
          }
        }
        onDataDelete={
          onDataDelete && {
            tooltip: `Delete data from ${type}`,
            handleClick: deleteData,
          }
        }
        onImport={
          onImport && {
            tooltip: `Import historic data for  ${type}`,
            handleClick: importData,
          }
        }
        onRefreshMildew={
          onRefreshMildew && {
            tooltip: `Refresh Mildew risk for ${type}`,
            handleClick: refreshMildew,
          }
        }
        onRestore={
          onRestore && {
            tooltip: `Restore ${type}`,
            handleClick: handleRestore,
          }
        }
        styles={classes}
      />
      {record && (
        <Modal open={modalOpen}>
          <DeleteRecord
            title={`Delete ${type} "${record[deleteLabel]}"`}
            id={record.id}
            waiting={waiting}
            onDelete={handleDelete}
            closeModal={closeModal}
          />
        </Modal>
      )}
      {record && (
        <Modal open={deleteFieldDataModalOpen}>
          <DeleteFieldData
            title={`Delete field data from "${record[deleteLabel]}"`}
            id={record.id}
            waiting={deleteFieldDataWaiting}
            onDelete={handleDataDelete}
            onStartDateChanged={handleDeleteDataStartDateChange}
            onEndDateChanged={handleDeleteDataEndDateChange}
            dateTimeValue={deleteDataRange}
            closeModal={closeDeleteFieldDataModal}
            tables={tables}
          />
        </Modal>
      )}
      {record && (
        <Modal open={importModalOpen}>
          <ImportHistoricData
            title={`Import historic data of  ${type} "${record[deleteLabel]}"`}
            id={record.id}
            waiting={importWaiting}
            onImport={handleImport}
            onStartDateChanged={handleStartDateChange}
            onEndDateChanged={handleEndDateChange}
            dateTimeValue={importDataRangevalue}
            closeModal={closeImportModal}
          />
        </Modal>
      )}
      {record && (
        <Modal open={mildewModalOpen}>
          <RefreshMildew
            title={`Refresh Mildew risk data  ${type} "${record[deleteLabel]}"`}
            id={record.id}
            waiting={mildewWaiting}
            onRefresh={handleMildewRefresh}
            onStartDateChanged={handleMildewDateChange}
            dateTimeValue={mildewRefreshFrom}
            closeModal={closeMildewModal}
          />
        </Modal>
      )}
    </>
  );
};

export default AdminTable;
