import React, { useMemo } from "react";
import AdminTable from "./AdminTable";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";

export default function IrrigatorTypesTable({ user, activeClient, data }) {
  const history = useHistory();

  const config = useMemo(
    () => ({
      columns: [
        {
          title: "Name",
          field: "name",
        },
      ],
      data: data,
      options: { pageSizeOptions: [5, 10, 20, 50] },
    }),
    [data]
  );

  // const history = useHistory();
  const onCreate = () => history.push("/admin/irrigator-type");
  const onEdit = (id) => history.push(`/admin/irrigator-type/${id}`);
  // const onDelete = (record) => deleteIrrigatorType(user.token, record.id);

  return (
    <AdminTable
      title={<Typography variant="h2">Irrigator Types</Typography>}
      type="irrigator"
      config={config}
      // onRefresh={loadIrrigatorTypes}
      onCreate={onCreate}
      onEdit={onEdit}
      // onDelete={onDelete}
      // deleteLabel="id"
    />
  );
}
