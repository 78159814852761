import { listAutomationEvents } from "../../api/automation";
import { useState, useEffect } from "react";
import moment from "moment";
import Table from "./Table";
import { useAuthState } from "../../context/auth";
import { Typography } from "@material-ui/core";

const columns = [
  {
    title: "Time",
    field: "timestamp",
    render: ({ timestamp }) => moment(timestamp).format("DD/MM/YYYY HH:mm"),
  },
  {
    title: "Event",
    field: "description",
  },
];

export const AutomationLogTable = ({ automation_id }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const user = useAuthState();

  useEffect(() => {
    setLoading(true);
    if (user) {
      listAutomationEvents(user.token, user.client.id, automation_id)
        .then((response) => {
          setLogs(response.data);
          setLoading(false);
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    }
  }, [automation_id, user]);

  return (
    <Table
      title={
        <Typography variant="h2" color="primary">
          Automation Logs
        </Typography>
      }
      loading={loading}
      error={error}
      data={logs}
      columns={columns}
      options={{
        pageSizeOptions: [5, 10, 20, 50],
      }}
    />
  );
};
