import axios from "axios";
import * as Sentry from "@sentry/react";

export const craft = axios.create({
  baseURL: "https://www.agri-tech.co.uk/",
});

export const local = axios.create({
  baseURL: "https://app.agri-tech.co.uk/",
});

export const irrimax = axios.create({
  baseURL: "https://dashboard.agri-tech.co.uk/",
});

export interface ServerResponse<D> {
  status: number;
  message: string;
  data: D;
}

export type DateRange = {
  start_date: string;
  end_date: string;
};

var api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/v2/",
  // baseURL: "https://api.staging.agri-tech.co.uk/v2/",
  // baseURL: "http://agritech-api.local/v2/",
  headers: { "Content-Type": "application/json" },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // whatever you want to do with the error
    if (error && error.response?.data) {
      console.error(error);
      Sentry.captureMessage(error.response.data, Sentry.Severity.Error);
    }
    throw error;
  }
);

export default api;
