import moment from "moment-timezone";
import {
  Action,
  Automation,
  ClimateCondition,
  SingleCondition,
  SoilCondition,
} from "../../../api/automation";
import { Irrigator, IrrigatorCommand } from "../../../api/irrigators";
import { capitalizeFirstLetter as capitalise } from "../../../helpers";

const formatMetric = (
  metric: ClimateCondition["metric"] | SoilCondition["metric"]
): string => {
  if (metric === "vpd") {
    return "VPD";
  }
  return capitalise(metric);
};

const formatCondition = (condition: SingleCondition): string => {
  switch (condition.type) {
    case "climate":
    case "soil":
      return `${formatMetric(condition.metric)} is ${
        condition.comparison === "gt" ? "greater than" : "less than"
      } ${condition.threshold}`;
    case "soil_climate":
      return `Soil moisture is ${
        condition.comparison === "gt" ? "greater than" : "less than"
      } ${condition.threshold_manual ?? condition.threshold_name} `;
    case "time":
      return `The time is between ${condition.start} and ${condition.end}`;
  }
};

const formatAction = (
  action: Action,
  irrigators: Irrigator[],
  irrigatorCommands: IrrigatorCommand[]
): string => {
  switch (action.type) {
    case "sms":
      return `Send an SMS message to ${action.phone_number} with content "${action.message}"`;
    case "irrigator":
      const irrigator = irrigators.find((i) => i.id === action.irrigator_id);
      const name = irrigatorCommands.find(
        (c) => c.command === action.command
      )?.name;
      return `${name} (${action.command}) irrigator ${irrigator?.name} via SMS message to ${irrigator?.phone_number}`;
    case "talgil":
      return `Activate Program #${action.program_id} on Irrigator #${action.irrigator_id}`;
    case "email":
      return `Send an email to ${action.email}`;
  }
};

const flattenConditions = (
  conditions: Automation["data"]["conditions"],
  lastHint?: "AND" | "OR",
  hint: "AND" | "OR" = "AND"
): { condition: SingleCondition; andOr?: "AND" | "OR" }[] => {
  const flattened: { condition: SingleCondition; andOr?: "AND" | "OR" }[] = [];
  conditions.forEach((condition, idx) => {
    switch (condition.type) {
      case "and":
        flattened.push(...flattenConditions(condition.conditions, hint, "AND"));
        break;
      case "or":
        flattened.push(...flattenConditions(condition.conditions, hint, "OR"));
        break;
      default:
        flattened.push({ condition, andOr: idx ? hint : lastHint });
    }
  });
  return flattened;
};

export const AutomationDisplay: React.FC<{
  automation: Automation;
  irrigators: Irrigator[];
  irrigatorCommands: IrrigatorCommand[];
}> = ({ automation, irrigators, irrigatorCommands }) => {
  const last_triggered =
    automation.last_triggered &&
    moment(automation.last_triggered).format("h:mm a");
  return (
    <>
      <p style={{ marginBottom: 0 }}>
        For field{" "}
        {automation.field_name ? (
          <strong>{automation.field_name}</strong>
        ) : (
          <strong>#{automation.field_id}</strong>
        )}{" "}
        when...
      </p>
      <ul>
        {flattenConditions(automation.data.conditions).map(
          ({ condition, andOr }, idx) => (
            <li key={idx}>
              {andOr ? (
                <>
                  <strong>{andOr}</strong>{" "}
                </>
              ) : undefined}
              {formatCondition(condition)}
            </li>
          )
        )}
      </ul>
      <p style={{ marginTop: 0, marginBottom: 0 }}>Then...</p>
      <ul style={{ marginBottom: "1.5rem" }}>
        {automation.data.actions.map((action, idx) => (
          <li key={idx}>
            {formatAction(action, irrigators, irrigatorCommands)}
          </li>
        ))}
      </ul>
      {last_triggered && (
        <small>Last activated at {last_triggered} today</small>
      )}
    </>
  );
};
