import { useState, ReactElement, useMemo } from "react";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import { Typography, CardContent, makeStyles } from "@material-ui/core";
import AnalyticsTable from "./AnalyticsTable";
import { handleRequestError, triggerToast } from "../../helpers";
import Modal from "../elements/Modal";
import DeleteRecord from "../actions/DeleteRecord";

import FormButton from "../forms/FormButton";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const EditDeleteTable = <D extends { id: string | number }>({
  title,
  type,
  data,
  record,
  setRecord,
  renderForm,
  onUpdate,
  onDelete,
  onRefresh,
  readonly,
}: {
  title: string;
  type: string;
  data: any;
  record?: D;
  setRecord: (id: D["id"]) => void;
  renderForm: (props: {
    isValid: (status: boolean) => void;
    isWaiting: (status: boolean) => void;
    onSubmit: (formValues: D) => void;
    initialValues: D;
  }) => ReactElement;
  onUpdate: (formValues: D) => Promise<{ message: string } | void> | void;
  onDelete: () => Promise<{ message: string } | void> | void;
  onRefresh: () => void;
  readonly: boolean;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState<"update" | "delete">();
  const [valid, setValid] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleEditModal = (event: unknown, row: D) => {
    if (readonly) {
      return;
    }
    openModal();
    setRecord(row.id);
    setAction("update");
  };

  const handleDeleteModal = (event: unknown, row: D) => {
    if (readonly) {
      return;
    }
    openModal();
    setRecord(row.id);
    setAction("delete");
  };

  const handleUpdate = async (formValues: D) => {
    try {
      const response = await onUpdate(formValues);
      onRefresh();
      if (response && response.message) {
        dispatch(triggerToast(response.message, { variant: "success" }));
      }
      closeModal();
    } catch (e) {
      handleRequestError(e, `Failed to update ${type}: `);
    }
  };

  const handleDelete = async () => {
    setWaiting(true);
    try {
      const response = await onDelete();
      onRefresh();
      if (response && response.message) {
        dispatch(triggerToast(response.message, { variant: "success" }));
      }
      setWaiting(false);
      closeModal();
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, `Failed to delete ${type}: `);
    }
  };

  const formComponent = useMemo(
    () =>
      record &&
      renderForm({
        isValid: (status: boolean) => setValid(status),
        isWaiting: (status: boolean) => setWaiting(status),
        onSubmit: handleUpdate,
        initialValues: record,
      }),
    [record, renderForm]
  );

  const onEditHandler = readonly
    ? undefined
    : {
        tooltip: `Edit ${type}`,
        handleClick: handleEditModal,
      };

  const onDeleteHandler = readonly
    ? undefined
    : {
        tooltip: `Delete ${type}`,
        handleClick: handleDeleteModal,
      };

  return (
    <>
      {data ? (
        <>
          <AnalyticsTable
            config={data}
            onEdit={onEditHandler}
            onDelete={onDeleteHandler}
          />
          {record && (
            <Modal open={modalOpen}>
              {action === "update" && (
                <CardContent>
                  {formComponent && [
                    formComponent,
                    <div className={classes.buttonGroup}>
                      <FormButton text="Close" handleClick={closeModal} />
                      <FormButton
                        text="Update"
                        disabled={!valid || waiting}
                        handleClick={() =>
                          dispatch(submit(formComponent.props.form))
                        }
                        variant="contained"
                        color="primary"
                        waiting={waiting}
                        waitingText="Saving"
                      />
                    </div>,
                  ]}
                </CardContent>
              )}
              {action === "delete" && (
                <DeleteRecord
                  title={`Delete ${type}`}
                  id={record.id}
                  waiting={waiting}
                  onDelete={handleDelete}
                  closeModal={closeModal}
                />
              )}
            </Modal>
          )}
        </>
      ) : (
        <>
          <Typography variant="h3" component="h3" gutterBottom>
            {title}
          </Typography>
          <Typography>
            No {type} recorded within the selected date range
          </Typography>
        </>
      )}
    </>
  );
};

export default EditDeleteTable;
