import React, { Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Card from "../../";
import { handleRequestError, triggerToast } from "../../../../../../../helpers";
import moment from "moment";
import UpdateAgronomicsRecord from "../../../../../../../components/actions/UpdateAgronomicsRecord";
import Modal from "../../../../../../elements/Modal";
import { normaliseAgronomicsKeys } from "../../../../../../../api/agronomics";
import DeleteRecord from "../../../../../../actions/DeleteRecord";
import { DeleteAgronomics } from "../../../../../../../api/agronomics";
import { useAuthState } from "../../../../../../../context/auth";
import AnalyticsTable from "../../../../../../tables/AnalyticsTable";
import {
  useChartData,
  useReloadChartData,
} from "../../../../../../../data/useChartData";
import { useMetricsState } from "../../../../../../../context/metrics";

const formatTableData = (data, headers, metrics) => {
  const chartMetrics = Object.values(headers);
  if (!chartMetrics.length) return null;

  return {
    columns: [
      { title: "Timestamp", field: "timestamp" },
      ...chartMetrics.map((type) => {
        const metric = metrics.filter((item) => item.ref === type);
        const config = metric.length ? metric[0] : null;
        return {
          title: config?.name || `Unkown (${type})`,
          field: type.replace("ag_", ""),
          cellStyle: { minWidth: 120 },
        };
      }),
    ],
    data: data
      .sort((a, b) => (moment(a.timestamp).isBefore(b.timestamp) ? 1 : -1))
      .map((item) => ({
        ...item,
        timestamp: moment(item.timestamp).format("DD/MM/YY HH:mm"),
      })),
  };
};

const CardAgronomics = ({ chart, data, loading, tableData, field, range }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [record, setRecord] = useState(null);
  const [action, setAction] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const dispatch = useDispatch();
  const user = useAuthState();

  const { chartData: rawData } = useChartData("agronomics", range, field.id);
  const { reloadData } = useReloadChartData();

  const agronomicsEdit = (event, row) => {
    openModal();
    const r = normaliseAgronomicsKeys(
      rawData.find((item) => item.id === row.id),
      true
    );
    setRecord(r);
    setAction("update");
  };

  const agronomicsDelete = (event, row) => {
    openModal();
    const r = normaliseAgronomicsKeys(
      rawData.find((item) => item.id === row.id),
      true
    );
    setRecord(r);
    setAction("delete");
  };

  const handleAgronomicsDelete = async () => {
    setWaiting(true);
    try {
      const response = await DeleteAgronomics(
        user.token,
        user.client.id,
        field.id,
        record.id
      );
      reloadData();
      dispatch(triggerToast(response.message, { variant: "success" }));
      setWaiting(false);
      closeModal();
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, "Failed to delete Agronomics record: ");
    }
  };

  const metrics = useMetricsState();

  const tableInfo = useMemo(() => {
    const res = formatTableData(rawData ?? [], chart.table, metrics);
    return res;
  }, [rawData]);

  return (
    <Fragment>
      <Card
        loading={loading}
        chart={chart}
        data={data}
        tables={[
          {
            title: "Recorded Data",
            display: false,
            table: (
              <AnalyticsTable
                key="agronomics"
                type={chart.id}
                config={{
                  ...tableData,
                  ...tableInfo,
                  title: "Agronomics Sensor Data",
                }}
                onEdit={{
                  tooltip: "Edit Agronomics Record",
                  handleClick: agronomicsEdit,
                }}
                onDelete={{
                  tooltip: "Delete Agronomics Record",
                  handleClick: agronomicsDelete,
                }}
              />
            ),
          },
        ]}
      />
      {record && (
        <Modal open={modalOpen}>
          {action === "update" && (
            <UpdateAgronomicsRecord
              field={field}
              user={user}
              range={range}
              agronomicData={rawData}
              record={record}
              closeModal={closeModal}
            />
          )}
          {action === "delete" && (
            <DeleteRecord
              title="Delete Agronomics Record"
              id={record.id}
              waiting={waiting}
              onDelete={handleAgronomicsDelete}
              closeModal={closeModal}
            />
          )}
        </Modal>
      )}
    </Fragment>
  );
};

export default CardAgronomics;
