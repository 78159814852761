import React from "react";
import {
  makeStyles,
  CircularProgress,
  Typography,
} from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  loader: {
    textAlign: "center"
  },
  loaderText: { marginTop: theme.spacing(2) },
}));

export default (props) => {
  const classes = useStyles();
  return (
    <div>
      {((props.active && props.showChildren) || !props.active) && props.children}
      {props.active && (
        <div className={classes.loader}>
          <CircularProgress color="primary" size={60} />
          <Typography
            variant="h4"
            color="primary"
            className={classes.loaderText}
          >
            {props.message}
          </Typography>
        </div>
      )}
    </div>
  );
};
