import { useEffect, useMemo, useState } from "react";
import AdminTable from "./AdminTable";
import { Box, Typography } from "@material-ui/core";
import moment, { Moment } from "moment-timezone";
import { useHistory } from "react-router-dom";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { ParsedField } from "./FieldsTable";

const warningThreshold = [2, "hours"] as const;
const errorThreshold = [4, "hours"] as const;

type FieldStatus = {
  id: number;
  client_name?: string;
  client_id: number;
  name: string;
  farm?: string;
  last_soil?: Moment;
  last_ambient?: Moment;
};

const ErrorInfo = () => (
  <Box mt={1}>
    <Typography variant="body1">
      <Typography color="secondary" variant="body1" component="span">
        Warning
      </Typography>{" "}
      - No data for more than {warningThreshold[0]} {warningThreshold[1]}
    </Typography>
    <Typography variant="body1">
      <Typography color="error" variant="body1" component="span">
        Error
      </Typography>{" "}
      - No data for more than {errorThreshold[0]} {errorThreshold[1]}
    </Typography>
    <Typography color="textSecondary">
      Only fields with issues and enabled importing are shown
    </Typography>
  </Box>
);
export const FieldStatusTable = ({ fields }: { fields?: ParsedField[] }) => {
  const data = useMemo<FieldStatus[] | undefined>(() => {
    return fields
      ?.filter((f) => f.import)
      ?.map((field) => {
        const last_soil = field.import_summary?.last_soil_import;
        const last_ambient = field.import_summary?.last_ambient_import;
        return {
          id: field.id,
          client_name: field.client.name,
          client_id: field.client.id,
          name: field.name,
          farm: field.farm,
          last_soil: last_soil ? moment(last_soil) : undefined,
          last_ambient: last_ambient ? moment(last_ambient) : undefined,
        };
      });
  }, [fields]);

  const [now, setNow] = useState(moment());
  // update time every minute
  useEffect(() => {
    const interval = setInterval(() => setNow(moment()), 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  type DateData = FieldStatus["last_soil"] | FieldStatus["last_ambient"];
  const getDataStatus = (lastData: DateData) => {
    if (!lastData) return;
    if (now.diff(lastData, errorThreshold[1]) >= errorThreshold[0])
      return "error";
    if (now.diff(lastData, warningThreshold[1]) >= warningThreshold[0])
      return "warning";
  };

  const getStatusElement = (lastData: DateData) => {
    const status = getDataStatus(lastData);
    switch (status) {
      case "error":
        return <Typography color="error">{lastData!.fromNow()}</Typography>;
      case "warning":
        return <Typography color="secondary">{lastData!.fromNow()}</Typography>;
      default:
        return (
          <Typography color="textSecondary">
            {lastData?.fromNow() ?? "No data"}
          </Typography>
        );
    }
  };

  const maxStatus = (...statuses: ("error" | "warning" | undefined)[]) => {
    let max: "error" | "warning" | undefined;
    for (const status of statuses) {
      if (status === "error") return "error";
      if (status === "warning") max = "warning";
    }
    return max;
  };

  const withWarnings = useMemo(
    () =>
      data
        ?.map((x) => {
          // overall status is error if both soil and ambient are missing, otherwise the worst of the two
          let status: "error" | "warning" | undefined;
          if (!x.last_ambient && !x.last_soil) {
            status = "error";
          } else {
            status = maxStatus(
              getDataStatus(x.last_ambient),
              getDataStatus(x.last_soil)
            );
          }
          return { ...x, status };
        })
        .filter((x) => x.status !== undefined),
    [data, now]
  );

  const sortDateData = (a: DateData, b: DateData) => {
    if (a && b) return a.diff(b);
    if (a) return -1;
    if (b) return 1;
    return 0;
  };

  const history = useHistory();
  const onEdit = (id: number) => history.push(`/admin/field/${id}`);

  type Row = NonNullable<typeof withWarnings>[number];
  const config = useMemo(
    () => ({
      columns: [
        {
          title: "Client",
          field: "client_name",
        },
        {
          title: "Farm",
          field: "farm",
        },
        {
          title: "Field",
          field: "name",
        },
        {
          title: "Status",
          field: "status",
          render: (x: Row) => {
            switch (x.status) {
              case "error":
                return <Typography color="error">Error</Typography>;
              case "warning":
                return <Typography color="secondary">Warning</Typography>;
              default:
                return <Typography color="textSecondary">OK</Typography>;
            }
          },
        },
        {
          title: "Last Climate Data",
          field: "last_ambient",
          render: (x: Row) => getStatusElement(x.last_ambient),
          customSort: (a: Row, b: Row) =>
            sortDateData(a.last_ambient, b.last_ambient),
        },
        {
          title: "Last Soil Data",
          field: "last_soil",
          render: (x: Row) => getStatusElement(x.last_soil),
          customSort: (a: Row, b: Row) =>
            sortDateData(a.last_soil, b.last_soil),
        },
      ],
      data: withWarnings,
      options: {
        pageSizeOptions: [5, 10, 20, 50],
        exportAllData: true,
        exportMenu: [
          {
            label: "Export PDF",
            exportFunc: (cols: any[], data: Row[]) =>
              ExportPdf(cols, data, "export"),
          },
          {
            label: "Export CSV",
            exportFunc: (cols: any[], data: Row[]) =>
              ExportCsv(cols, data, "export"),
          },
        ],
      },
    }),
    [withWarnings, now]
  );

  return (
    <AdminTable
      title={
        <Box my={1}>
          <Typography variant="h2">Field Status</Typography>
          <ErrorInfo />
        </Box>
      }
      type="field"
      onEdit={onEdit}
      config={config}
    />
  );
};
