import { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "./Admin";
import {
  Grid,
  Card,
  Box,
  TextField,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { useAuthState } from "../../../context/auth";
import { handleRequestError } from "../../../helpers";
import Loader from "../../elements/Loader";
import {
  addIrrigatorCommand,
  updateIrrigatorCommand,
  getIrrigatorCommand,
  listIrrigatorTypes,
} from "../../../api/irrigators";
import {
  fetchClients,
  useClientsState,
  useClientsDispatch,
} from "../../../context/clients";

import { SelectInput } from "../../forms/FormInputs";

import Headline from "../../elements/Headline";
import FormButton from "../../forms/FormButton";

export const ManageIrrigatorCommand = ({ id, setIsLoading }) => {
  const history = useHistory();
  const user = useAuthState();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [irrigatorCommand, setIrrigatorCommand] = useState(null);
  const [irrigatorTypes, setIrrigatorTypes] = useState([]);

  const clients = useClientsState();
  const clientsDispatch = useClientsDispatch();

  useEffect(() => {
    setIsLoading(loading);
  }, [setIsLoading, loading]);

  useEffect(() => {
    if (!clients.length) {
      fetchClients(clientsDispatch, user.token);
    }
  }, [clientsDispatch, clients, user.token]);

  const loadIrrigatorCommand = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getIrrigatorCommand(user.token, id);
      setIrrigatorCommand(response.data);
      setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching irrigator command: ");
      setLoading(false);
    }
  }, [user.token, id]);

  const loadIrrigatorTypes = useCallback(async () => {
    setLoading(true);
    try {
      const response = await listIrrigatorTypes(user.token);
      setIrrigatorTypes(response.data);
      setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching irrigator types: ");
      setLoading(false);
    }
  }, [user.token, id]);

  useEffect(() => {
    loadIrrigatorTypes().then(() => {
      if (id) loadIrrigatorCommand();
    });
  }, [loadIrrigatorTypes, loadIrrigatorCommand]);

  const [name, setName] = useState();
  const [command, setCommand] = useState();
  const [type, setType] = useState();

  useEffect(() => {
    if (irrigatorCommand?.name) {
      setName(irrigatorCommand.name);
    }
    if (irrigatorCommand?.command) {
      setCommand(irrigatorCommand.command);
    }
    if (irrigatorCommand?.irrigator_type_id) {
      setType(irrigatorCommand.irrigator_type_id.toString());
    }
  }, [irrigatorCommand]);

  const typeInput = {
    value: type,
    onChange: (evt) => {
      setType(evt.target.value);
    },
  };

  const typeOptions = useMemo(() => {
    return irrigatorTypes.reduce((obj, field) => {
      obj[field.id] = field.name;
      return obj;
    }, {});
  }, [irrigatorTypes]);

  const changed = useMemo(
    () =>
      name !== irrigatorCommand?.name ||
      command !== irrigatorCommand?.command ||
      parseInt(type) !== irrigatorCommand?.irrigator_type_id,
    [name, command, type, irrigatorCommand]
  );

  const missingRequiredInfo = useMemo(() => {
    return !name || !command || !type;
  }, [name, command, type]);

  const [waiting, setWaiting] = useState(false);

  const onSubmit = useCallback(async () => {
    if (!missingRequiredInfo && changed) {
      setWaiting(true);
      const response = id
        ? await updateIrrigatorCommand(user.token, id, {
            name,
            command,
            irrigator_type_id: parseInt(type),
          })
        : await addIrrigatorCommand(user.token, {
            name,
            command,
            irrigator_type_id: parseInt(type),
          });
      if (response.status === 200) {
        setIrrigatorCommand({
          ...irrigatorCommand,
          name,
          command,
          irrigator_type_id: parseInt(type),
        });
      }
      setWaiting(false);
      if (!id) history.push(`/admin`);
    }
  }, [name, command, type, changed, id, user.token]);

  return (
    <Loader
      active={id ? loading : false}
      showChildren={false}
      message={`Loading Irrigator command...`}
    >
      <Headline
        headline={
          id
            ? `Edit Irrigator Command: ${irrigatorCommand?.name}`
            : "Add Irrigator Command"
        }
        headlineVariant="h1"
        divider={true}
      >
        <FormButton
          text={id ? "Update" : "Add"}
          disabled={!changed || waiting || missingRequiredInfo}
          handleClick={() => onSubmit()}
          variant="contained"
          color="primary"
          waiting={waiting}
          waitingText={id ? "Updating" : "Adding"}
        />
      </Headline>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12} lg={7}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.subTitle}
              >
                DETAILS:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ marginTop: 10 }}>
                <Box p={2} pt={1}>
                  <InputLabel id={`select-${id}-label`}>Name</InputLabel>
                  <TextField
                    style={{ width: "100%" }}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    error={!name}
                  ></TextField>
                </Box>
                <Box p={2} pt={1}>
                  <InputLabel id={`select-${id}-label`}>Command</InputLabel>
                  <TextField
                    style={{ width: "100%" }}
                    value={command}
                    onChange={(e) => setCommand(e.target.value)}
                    error={!command}
                  ></TextField>
                </Box>
                <Box p={2} pt={1}>
                  <SelectInput
                    label={"Type"}
                    input={typeInput}
                    options={typeOptions}
                    disabled={loading}
                    meta={{
                      invalid: !typeInput.value,
                      error: !typeInput.value,
                    }}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
};
