import React, { useMemo, useState } from "react";
import moment from "moment";
import EditDeleteTable from "./EditDeleteTable";
import {
  DeleteRecommendation,
  UpdateRecommendation,
} from "../../api/recommendation";
import RecommendationForm from "../forms/RecommendationForm";
import { useTheme } from "@material-ui/core";
import {
  analyticsTableToolbar,
  defaultTableOptions,
} from "../pages/Analytics/util";
import { useReloadChartData } from "../../data/useChartData";

const RecommendationTable = ({ data, user, field, range }) => {
  const { reloadData } = useReloadChartData();

  const [recommendationId, setRecommendationId] = useState(null);
  const recommendationRecord = useMemo(() => {
    const record =
      recommendationId && data.filter((item) => item.id === recommendationId);
    return record && record.length && record[0];
  }, [recommendationId]);
  const recommendations = useMemo(
    () =>
      data.length && {
        columns: [
          { title: "Timestamp", field: "timestamp_label" },
          { title: "Description", field: "description" },
        ],
        data: data.map((item) => ({
          ...item,
          timestamp_label: moment(item.timestamp).format("DD/MM/YY HH:mm"),
        })),
      },
    [data]
  );
  const handleRecommendationUpdate = ({ timestamp, description }) =>
    UpdateRecommendation(
      user.token,
      user.client.id,
      field.id,
      recommendationId,
      {
        description,
        timestamp: moment
          .tz(moment(timestamp).format("YYYY-MM-DD HH:mm:ss"), field.timezone)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
      }
    );
  const handleRecommendationDelete = () =>
    DeleteRecommendation(
      user.token,
      user.client.id,
      field.id,
      recommendationId
    );
  const handleRecommendationRefresh = () => {
    reloadData();
  };

  const theme = useTheme();
  const tableConfig = {
    title: "Recommendations",
    components: analyticsTableToolbar(theme),
    options: defaultTableOptions,
  };

  return (
    <EditDeleteTable
      title="Recommendations"
      type="Recommendation"
      data={{ ...recommendations, ...tableConfig }}
      record={recommendationRecord}
      renderForm={(props) => (
        <RecommendationForm
          {...props}
          title="Amend the Recommendation:"
          timezone={field.timezone}
        />
      )}
      setRecord={setRecommendationId}
      onUpdate={handleRecommendationUpdate}
      onDelete={handleRecommendationDelete}
      onRefresh={handleRecommendationRefresh}
      readonly={!user.isAdmin}
    />
  );
};

export default RecommendationTable;
