import React, { useMemo } from "react";

import { useSettingsState } from "../../../../context/settings";
import { useChartEvents } from "../../Analytics/components/Card/hooks";

import ChartSelection from "../../../charts/ChartSelection";
import config from "../../../../constants/charts";
import {
  ClimateMetric,
  SoilMetric,
  chart_metric_translations,
} from "../../../../constants/metrics";
import { useAuthState } from "../../../../context/auth";
import { useDataLoader } from "../../../../data/DataLoader";

export const DefaultConditionChart: React.FC<{
  field: { id: string; timezone: string };
  metric: ClimateMetric | SoilMetric | undefined;
}> = ({ field, metric }) => {
  const {
    chartsSettings: { visibility },
  } = useSettingsState();

  const user = useAuthState();
  useDataLoader({
    user,
    field,
    groups: ["ambient_climates", "soil_climates"],
  });

  const chartEvents = useChartEvents();

  const chart = useMemo(() => {
    if (!metric) return undefined;

    // if chartType is a SoilCondition or ClimateCondition, then get the metric
    // from the chartType object
    const chartMetric = chart_metric_translations[metric];
    if (!chartMetric) return undefined;
    const chart = config[chartMetric];

    const settings = visibility?.find(
      ({ field_id: id, ref }) => field.id === id && chartMetric === ref
    );
    const { order, hidden } = settings ?? { order: 999, hidden: 0 };
    return { ...chart, id: chartMetric, ...{ order, hidden } };
  }, [metric, field.id]);

  return (
    <ChartSelection
      chart={chart}
      data={{}}
      selectedField={field}
      chartEvents={chartEvents}
      selectedResolution={"15 mins"}
    />
  );
};
