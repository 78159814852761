import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { normaliseAgronomicsKeys, SaveAgronomics } from "../../api/agronomics";
import { SaveSqueeze } from "../../api/squeeze";
import { SaveRecommendation } from "../../api/recommendation";
import { saveParameter } from "../../api/parameters";
import { SaveSpray } from "../../api/disease-risk";

import { useAnalyticsState } from "../../context/analytics";
import { useAuthState } from "../../context/auth";

import { useReloadChartData } from "../../data/useChartData";

import SpraysForm from "./SpraysForm";
import AgronomicsForm from "./AgronomicsForm";
import SqueezeForm from "./SqueezeForm";
import RecommendationForm from "./RecommendationForm";
import ParametersForm from "./ParametersForm";

import { triggerToast, handleRequestError } from "../../helpers";

export const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitIcon: {
    marginRight: theme.spacing(1),
  },
}));

const FormSelection = ({
  type,
  handleWaiting,
  handleValid,
  closeModal,
  isSecondarySuccessButtonClicked,
}) => {
  const user = useAuthState();
  const { field } = useAnalyticsState();
  const dispatch = useDispatch();
  const { reloadData } = useReloadChartData();

  const handleSpraySubmit = async (formValues) => {
    const record = {
      ...formValues,
      timestamp: formValues.timestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    try {
      const response = await SaveSpray(
        user.token,
        user.client.id,
        field.id,
        record
      );
      triggerToast(response.message, { variant: "success" });
      closeModal();
      reloadData();
      return response.data;
    } catch (e) {
      handleRequestError(e, "Failed to store Spray Activity: ");
    }
  };

  const handleAgronomicsSubmit = async (formValues) => {
    const record = normaliseAgronomicsKeys({
      ...formValues,
      timestamp: formValues.timestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
    });
    try {
      const response = await SaveAgronomics(
        user.token,
        user.client.id,
        field.id,
        record
      );
      reloadData();
      dispatch(triggerToast(response.message, { variant: "success" }));
      if (!isSecondarySuccessButtonClicked) {
        closeModal();
      }
      return response.data;
    } catch (e) {
      handleRequestError(e, "Failed to store Agronomics data: ");
    }
  };

  const handleSqueezeSubmit = async (formValues) => {
    const record = {
      ...formValues,
      timestamp: formValues.timestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    try {
      const response = await SaveSqueeze(
        user.token,
        user.client.id,
        field.id,
        record
      );
      reloadData();
      dispatch(triggerToast(response.message, { variant: "success" }));
      if (!isSecondarySuccessButtonClicked) {
        closeModal();
      }
      return response.data;
    } catch (e) {
      handleRequestError(e, "Failed to store Observation record: ");
    }
  };

  const handleRecommendationSubmit = async (formValues) => {
    const record = {
      ...formValues,
      timestamp: formValues.timestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    try {
      const response = await SaveRecommendation(
        user.token,
        user.client.id,
        field.id,
        record
      );
      reloadData();
      dispatch(triggerToast(response.message, { variant: "success" }));
      closeModal();
      return response.data;
    } catch (e) {
      handleRequestError(e, "Failed to store Recommendation record: ");
    }
  };

  const handleParameterSubmit = async (formValues) => {
    const record = {
      ...formValues,
      timestamp: formValues.timestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    try {
      const response = await saveParameter(
        user.token,
        user.client.id,
        field.id,
        record
      );
      reloadData();
      dispatch(triggerToast(response.message, { variant: "success" }));
      closeModal();
      return response.data;
    } catch (e) {
      handleRequestError(e, "Failed to store Parameter record: ");
    }
  };

  switch (type) {
    case "spray":
      return (
        <SpraysForm
          title="Provide details of the spray activity:"
          isValid={(status) => handleValid(status)}
          isWaiting={(status) => handleWaiting(status)}
          onSubmit={handleSpraySubmit}
          initialValues={{ timestamp: moment().tz(field.timezone) }}
        />
      );

    case "agronomics":
      return (
        <AgronomicsForm
          title="Provide the Agronomics data:"
          isValid={(status) => handleValid(status)}
          isWaiting={(status) => handleWaiting(status)}
          onSubmit={handleAgronomicsSubmit}
          initialValues={{ timestamp: moment().tz(field.timezone) }}
        />
      );

    case "squeeze":
      return (
        <SqueezeForm
          title="Provide the Observation details:"
          isValid={(status) => handleValid(status)}
          isWaiting={(status) => handleWaiting(status)}
          onSubmit={handleSqueezeSubmit}
          initialValues={{ timestamp: moment().tz(field.timezone) }}
        />
      );

    case "recommendations":
      return (
        <RecommendationForm
          title="Outline your recommendation:"
          isValid={(status) => handleValid(status)}
          isWaiting={(status) => handleWaiting(status)}
          onSubmit={handleRecommendationSubmit}
          initialValues={{ timestamp: moment().tz(field.timezone) }}
        />
      );

    case "parameters":
      return (
        <ParametersForm
          title="Define the parameter settings:"
          formStyles={useStyles}
          isValid={(status) => handleValid(status)}
          isWaiting={(status) => handleWaiting(status)}
          onSubmit={handleParameterSubmit}
          initialValues={{
            group: "moisture",
            timestamp: moment().tz(field.timezone),
          }}
        />
      );

    default:
      return "Something went wrong...";
  }
};

export default FormSelection;
