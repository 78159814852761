import {
  Typography,
  Select,
  MenuItem,
  TextField,
  Box,
} from "@material-ui/core";
import { useState, useMemo, useEffect } from "react";
import { SoilCondition, ClimateCondition } from "../../../../api/automation";
import { metricLabels } from "../../../../constants/metrics";
import { SubConditionInput, Spacer, parseNumber } from "./Helpers";

export const MetricConditionInput = <
  C extends SoilCondition | ClimateCondition
>(
  type: C["type"],
  options: C["metric"][]
): SubConditionInput<C> => {
  const Input: SubConditionInput<C> = ({
    value,
    onChange,
    field,
    onSelectMetric,
  }) => {
    const [metric, setMetric] = useState<C["metric"] | undefined>(
      value.metric ?? undefined
    );
    const [threshold, setThreshold] = useState<number | undefined>(
      value.threshold ?? undefined
    );
    const [comparison, setComparison] = useState<C["comparison"] | undefined>(
      value.comparison ?? undefined
    );
    const comparisonOptions = ["gt", "lt"];

    const result = useMemo<C | undefined>(() => {
      if (
        metric === undefined ||
        threshold === undefined ||
        comparison === undefined
      ) {
        return undefined;
      }
      return {
        type,
        metric,
        threshold,
        comparison,
      } as C;
    }, [metric, threshold, comparison]);

    useEffect(() => {
      onChange(result);
    }, [result]);

    useEffect(() => {
      if (onSelectMetric) {
        onSelectMetric(metric);
      }
    }, [metric]);

    return (
      <Box>
        <Typography>
          When the
          <Spacer>
            <Select
              value={metric ?? ""}
              onChange={(e) => setMetric(e.target.value as C["metric"])}
            >
              {options.map((m) => (
                <MenuItem value={m} key={m}>
                  {metricLabels.get(m) ?? m}
                </MenuItem>
              ))}
            </Select>
          </Spacer>
          is
          <Spacer>
            <Select
              label="comparison"
              value={comparison ?? ""}
              onChange={(e) => {
                setComparison(e.target.value as C["comparison"]);
              }}
            >
              {comparisonOptions.map((c) => (
                <MenuItem value={c} key={c}>
                  {c === "gt" ? "greater" : "less"}
                </MenuItem>
              ))}
            </Select>
          </Spacer>
          than
          <Spacer>
            <TextField
              value={threshold}
              type="number"
              onChange={(e) => setThreshold(parseNumber(e.target.value))}
            />
          </Spacer>
        </Typography>
      </Box>
    );
  };
  return Input;
};
