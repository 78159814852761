import React from "react";
import { makeStyles, CardContent, Typography } from "@material-ui/core";
import FormButton from "../forms/FormButton";
import DatePickerToFrom from "../elements/DatePickerToFrom";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
// modal that is displayed when clicking on delete field data icon
const DeleteFieldData = ({
  title,
  id,
  waiting,
  onDelete,
  onStartDateChanged,
  onEndDateChanged,
  dateTimeValue,
  closeModal,
  tables,
}) => {
  const classes = useStyles();

  return (
    <CardContent>
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      {/* add more check boxex for other tables
      <Checkbox
        checked={tables.agronomics.value ? true : false}
        onChange={tables.agronomics.onChange}
        label="Agronomics"
        name="Agronomics"
        color="primary"
      /> */}

      <DatePickerToFrom
        timestampFrom={dateTimeValue.start_date}
        onChangeFrom={onStartDateChanged}
        timestampTo={dateTimeValue.end_date}
        onChangeTo={onEndDateChanged}
        color="green"
      />

      <Typography gutterBottom>
        Warning: Some data may not be reimportable later.
      </Typography>
      <div className={classes.buttonGroup}>
        <FormButton text="Close" handleClick={closeModal} />
        <FormButton
          text="Delete"
          disabled={waiting}
          handleClick={() => onDelete()}
          variant="contained"
          color="primary"
          waiting={waiting}
          waitingText="importing"
        />
      </div>
      {waiting && <Typography gutterBottom>Deleting...</Typography>}
    </CardContent>
  );
};

export default DeleteFieldData;
