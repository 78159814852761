import Dexie from "dexie";

export const db = new Dexie("agritech_db");

db.version(1).stores({
  ambient_climates: "++id, [field_id+timestamp]",
  agronomics: "++id, [field_id+timestamp]",
  soil_climates: "++id, [field_id+timestamp]",
  recommendations: "++id, [field_id+timestamp]",
  mildew_risk: "++id, [field_id+timestamp]",
  sprays: "++id, [field_id+timestamp]",
  squeezes: "++id, [field_id+timestamp]",
  parameters: "++id, field_id, [field_id+timestamp]",
  parameter_types: "++id",
});

db.open().catch(() => {
  // Reset the database if an error occurs
  db.delete().then(() => db.open());
});

// Clear the database when user logs in / out
export const resetDatabase = () => {
  db.delete().then(() => db.open());
};
