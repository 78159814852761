// Modified from: https://github.com/AbelHeinsbroek/chartjs-plugin-crosshair

import {valueOrDefault} from 'chart.js/helpers';
import throttle from 'lodash/throttle';

var defaultOptions = {
  line: {
    color: "#536d7e",
    width: 1,
    dashPattern: [5, 5],
  },
  sync: {
    enabled: true,
    group: 1,
    suppressTooltips: false
  },
  snap: {
    enabled: false,
  }
};

export default {

  id: 'crosshair',

  afterInit: function(chart) {
    
    if (!chart.config.options.scales.x) {
      return
    }

    var xScaleType = chart.config.options.scales.x.type

    if (xScaleType !== 'linear' && xScaleType !== 'time' && xScaleType !== 'category' && xScaleType !== 'logarithmic') {
      return;
    }

    if (chart.options.plugins.crosshair === undefined) {
      chart.options.plugins.crosshair = defaultOptions;
    }

    chart.crosshair = {
      enabled: true,
      suppressUpdate: false,
      x: null,
      originalData: [],
      originalXRange: {},
      suppressTooltips: false,
      ignoreNextEvents: 0
    };

    var syncEnabled = this.getOption(chart, 'sync', 'enabled');
    if (syncEnabled) {
      var th = throttle(function(e) {
        this.handleSyncEvent(chart, e);
      }, 250).bind(this);
      chart.crosshair.syncEventHandler = function(e) {
        th(e);
      };

      window.addEventListener('sync-event', chart.crosshair.syncEventHandler);
    }
  },

  destroy: function(chart) {
    var syncEnabled = this.getOption(chart, 'sync', 'enabled');
    if (syncEnabled) {
      window.removeEventListener('sync-event', chart.crosshair.syncEventHandler);
    }
  },

  getOption: function(chart, category, name) {
    return valueOrDefault(chart.options.plugins.crosshair[category] ? chart.options.plugins.crosshair[category][name] : undefined, defaultOptions[category][name]);
  },

  getXScale: function(chart) {
    return chart.data.datasets.length ? chart.scales[chart.getDatasetMeta(0).xAxisID] : null;
  },
  getYScale: function(chart) {
    return chart.scales[chart.getDatasetMeta(0).yAxisID];
  },

  handleSyncEvent: function(chart, e) {

    var syncGroup = this.getOption(chart, 'sync', 'group');
    // stop if the sync event was fired from this chart
    if (e.chartId === chart.id) { return; }

    // stop if the sync event was fired from a different group
    if (e.syncGroup !== syncGroup) { return; }

    var xScale = this.getXScale(chart);

    if (!xScale) { return; }

    // Safari fix
    var buttons = (e.original.native.buttons === undefined ? e.original.native.which : e.original.native.buttons);
    if (e.original.type === 'mouseup') {
      buttons = 0;
    }

    var newEvent = {
      type: e.original.type === "click" ? "mousemove" : e.original.type,  // do not transmit click events to prevent unwanted changing of synced charts. We do need to transmit a event to stop zooming on synced charts however.
      chart: chart,
      x: xScale.getPixelForValue(e.xValue),
      y: e.original.y,
      native: {
        buttons: buttons
      },
      stop: true
    };
    chart.crosshair.enabled = (newEvent.type !== 'mouseout' && (newEvent.x > xScale.getPixelForValue(xScale.min) && newEvent.x < xScale.getPixelForValue(xScale.max)));
    chart.crosshair.x = newEvent.x;
    chart.tooltip.handleEvent(newEvent);
    chart._eventHandler(newEvent);
  },

  afterEvent: function(chart, e) {
    if (chart.config.options.scales.x.length === 0) {
      return;
    }

    var xScaleType = chart.config.options.scales.x.type;
    if (xScaleType !== 'linear' && xScaleType !== 'time' && xScaleType !== 'category' && xScaleType !== 'logarithmic') {
      return;
    }

    var xScale = this.getXScale(chart);
    if (!xScale) { return; }

    var syncEnabled = this.getOption(chart, 'sync', 'enabled');
    var syncGroup = this.getOption(chart, 'sync', 'group');

    if (!e.stop && syncEnabled) {
      var newEvent = new CustomEvent('sync-event');
      newEvent.chartId = chart.id;
      newEvent.syncGroup = syncGroup;
      newEvent.original = e.event;
      newEvent.xValue = xScale.getValueForPixel(e.event.x);
      window.dispatchEvent(newEvent);
    }

    chart.crosshair.enabled = (e.type !== 'mouseout' && (e.x > xScale.getPixelForValue(xScale.min) && e.x < xScale.getPixelForValue(xScale.max)));
    chart.crosshair.x = e.event.x;

    chart.draw();
  },

  afterDraw: function(chart) {
    this.drawTraceLine(chart);
    return true;
  },

  beforeTooltipDraw: function(chart) {
    return !chart.crosshair.suppressTooltips;
  },

  drawTraceLine: function(chart) {
    var yScale = this.getYScale(chart);

    var lineWidth = this.getOption(chart, 'line', 'width');
    var color = this.getOption(chart, 'line', 'color');
    var dashPattern = this.getOption(chart, 'line', 'dashPattern');

    var lineX = chart.crosshair.x;

    if (lineX > 0 && yScale) {
      chart.ctx.beginPath();
      chart.ctx.setLineDash(dashPattern);
      chart.ctx.moveTo(lineX, yScale.getPixelForValue(yScale.max));
      chart.ctx.lineWidth = lineWidth;
      chart.ctx.strokeStyle = color;
      chart.ctx.lineTo(lineX, yScale.getPixelForValue(yScale.min));
      chart.ctx.stroke();
      chart.ctx.setLineDash([]);
    }
  }
};

