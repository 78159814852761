import React, { useMemo } from "react";
import moment from "moment";
import { Bar } from "../../plugins/react-chartjs-2";
import config from "../../constants/charts";
import { defaultChartOptions } from "../../helpers";
import { useMetricsState } from "../../context/metrics";

import { getChartConfig } from "../../helpers/getChartConfig";
import { useChartData } from "../../data/useChartData";
import { useAnalyticsState } from "../../context/analytics";
import { roundTo } from "./Grouping";

const ChartDefault = ({
  chart,
  fieldId,
  range,
  theme,
  chartEvents,
  selectedResolution,
  field,
}) => {
  const { chartData } = useChartData(chart.group, range, fieldId);
  const metrics = useMetricsState();

  // calculate diff in weeks/days to display correct information
  const {
    range: { start_date, end_date },
  } = useAnalyticsState();
  const calculateDifferenceInWeeks = () => {
    const msInWeek = 1000 * 60 * 60 * 24 * 7;
    const diffEpoch = end_date - start_date;
    const differenceInWeeks = diffEpoch / msInWeek;
    return differenceInWeeks;
  };
  let differenceInWeeks = calculateDifferenceInWeeks();

  const chartConfig = useMemo(() => {
    var data = getChartConfig(chartData, chart.id, theme, metrics, field);
    data.datasets.forEach((item, i) => {
      if (item.label === "Rainfall") {
        item.data = getGroupedDatasets(
          item.data,
          selectedResolution,
          differenceInWeeks
        );
      }
    });
    return data;
  }, [chartData, theme, chart.id, metrics, selectedResolution]);

  const options = useMemo(
    () =>
      defaultChartOptions(
        config[chart.id].axis,
        config[chart.id].legend,
        range,
        chartEvents
      ),
    [chart.id, range]
  );

  return (
    <Bar ref={chartEvents.chartRef} data={chartConfig} options={options} />
  );
};

function getGroupedDatasets(data, selectedResolution, differenceInWeeks) {
  const groupedData = {};
  let selectedRes = selectedResolution;
  //if selected resolution is 24 hours,  difference in days must be more than 1
  if (selectedRes === "24 hours") {
    const diffInDays = differenceInWeeks * 7;
    if (diffInDays <= 1) {
      selectedRes = "1 hour";
    }
  } else if (selectedRes === "15 mins") {
    //if selected resolution is 15 mins,  difference in weeks must be more than 2
    const diffInWeeks = differenceInWeeks;
    if (diffInWeeks >= 2) {
      selectedRes = "1 hour";
    }
  }

  let dateKeyFormat = "YYYY-MM-DD HH";
  if (selectedRes === "15 mins") {
    dateKeyFormat = "YYYY-MM-DD HH:mm";
  } else if (selectedRes === "1 hour") {
    dateKeyFormat = "YYYY-MM-DD HH";
  } else if (selectedRes === "24 hours") {
    dateKeyFormat = "YYYY-MM-DD";
  }

  // Group data based on date
  data.forEach((record) => {
    const date = moment(record.x).format(dateKeyFormat);
    groupedData[date] = groupedData[date] || {};
    groupedData[date][record.x] = {
      x: record.x,
      y: record.y > 0 ? record.y : 0,
    };
  });

  // Sum data based on groups
  var res = Object.keys(groupedData).map((date) => {
    const res = {
      x: roundTo(
        groupedData[date][Object.keys(groupedData[date])[0]].x,
        selectedResolution
      ),
      y: 0,
    };
    Object.keys(groupedData[date]).forEach((timestamp) => {
      res.y += groupedData[date][timestamp].y;
    });
    return res;
  });

  return res;
}

export default ChartDefault;
