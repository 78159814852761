import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Card as MuiCard,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  YoutubeSearchedFor,
  ZoomIn,
  ZoomOut,
  AddComment,
  Timeline,
} from "@material-ui/icons";
import Loader from "../../../../../../elements/Loader";
import DropdownMenu from "../../../../../../elements/DropdownMenu";
import { useChartWidth } from "../../hooks";
import { useAnalyticsState } from "../../../../../../../context/analytics";

import { useAuthState } from "../../../../../../../context/auth";
import AnnotationModal from "./AnnotationModal";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: "100%",
    borderRadius: 0,
    boxShadow: "inset 0 -5px 0 0 " + theme.palette.primary.main,
    position: "relative",
    margin: "0px -8px",
  },
  sections: {
    paddingTop: theme.spacing(2),
    minHeight: "350px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "275px",
    },
    margin: "0px -5px -20px",
  },
  staticSections: {
    paddingTop: theme.spacing(2),
    margin: "0px -5px 0px",
  },
}));

const CardLayout = ({
  title,
  data,
  children,
  loading = false,
  options,
  chartEvents,
  isStaticImage = false,
  setResolution,
}) => {
  const width = useChartWidth(options);
  const classes = useStyles();
  const user = useAuthState();

  const [modalOpen, setModalOpen] = useState(false);
  const [isAnnotating, setIsAnnotating] = useState(false);

  const {
    range: { start_date, end_date },
  } = useAnalyticsState();

  const handleAnnotation = (isAdd) => {
    setIsAnnotating(true);
    chartEvents.chartRef.current._options["onClick"] = (e, element) => {
      //get x value from onclick event on the chart
      chartEvents.triggerAnnotationModal(
        data.id,
        "",
        element[0].element.$context.raw.x
      );
      //show modal to add comment
      setIsAnnotating(false);
      setModalOpen(!modalOpen);
    };
  };

  // simplify by moving to separate folder
  const resolutionKey = `resolutionOptions-${title.replace(/\s/g, "_").toLowerCase()}`;
  let resolutionOptions = [
    {
      headline: "Resolution",
      items: [
        {
          label: "15 minutes",
          type: "link",
          meta: { width: 6 },
          onClick: () => {
            localStorage.setItem(resolutionKey, "15 mins");
            setResolution("15 mins");
          },
        },
        {
          label: "1 hour",
          type: "link",
          meta: { width: 6 },
          onClick: () => {
            localStorage.setItem(resolutionKey, "1 hour");
            setResolution("1 hour");
          },
        },
        {
          label: "24 hours",
          type: "link",
          meta: { width: 6 },
          onClick: () => {
            localStorage.setItem(resolutionKey, "24 hours");
            setResolution("24 hours");
          },
        },
      ],
    },
  ];
  // calculate difference in weeks between the start and end date selected by user
  let differenceInWeeks;
  const calculateDifferenceInWeeks = () => {
    const msInWeek = 1000 * 60 * 60 * 24 * 7;
    const diffEpoch = end_date - start_date;
    differenceInWeeks = diffEpoch / msInWeek;
    return differenceInWeeks;
  };

  // update the options list based on selected time intertvals
  useEffect(() => {
    const differenceInWeeks = calculateDifferenceInWeeks();
    const differenceInDays = differenceInWeeks * 7;

    resolutionOptions.map((option) => {
      // if difference is more than or equals 2 weeks, remove 15 min option
      if (differenceInWeeks >= 2) {
        option.items.splice(0, 1);
      } else if (differenceInDays <= 1) {
        // if difference is less than or equals 1 day, remove 24 hours option
        option.items.splice(2, 1);
      }
      return option;
    });
  }, [start_date, end_date, calculateDifferenceInWeeks]);

  const showResolutionOptions = title === "Crop Water Usage & Gains" || title === "Rainfall" || title === "Run-Off";

  return (
    <Grid item xs={12} lg={width || 6}>
      <Loader active={loading} showChildren={true} message={`Loading...`}>
        <MuiCard className={classes.card}>
          <CardContent>
            {title === "Root-Zone Moisture Content" && user.isAdmin ? (
              <AnnotationModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                data={data}
                chartEvents={chartEvents}
              />
            ) : null}

            <div>
              {options ? (
                <div style={{ float: "right" }}>
                  {title === "Root-Zone Moisture Content" && user.isAdmin ? (
                    <IconButton
                      size="small"
                      aria-label="Annotate"
                      title="Annotate"
                      onClick={() => handleAnnotation(true)}
                    >
                      <AddComment
                        color={!!isAnnotating ? "primary" : "action"}
                      />
                    </IconButton>
                  ) : null}

                  {showResolutionOptions && (
                    <DropdownMenu
                      icon={<Timeline />}
                      sections={resolutionOptions}
                    />
                  )}

                  <IconButton
                    size="small"
                    aria-label="Zoom In"
                    title="Zoom In"
                    onClick={chartEvents.zoomIn}
                  >
                    <ZoomIn />
                  </IconButton>
                  <IconButton
                    size="small"
                    aria-label="Zoom Out"
                    title="Zoom Out"
                    onClick={chartEvents.zoomOut}
                  >
                    <ZoomOut />
                  </IconButton>
                  <IconButton
                    size="small"
                    aria-label="Reset Zoom"
                    title="Reset Zoom"
                    onClick={chartEvents.resetZoom}
                  >
                    <YoutubeSearchedFor />
                  </IconButton>
                  <DropdownMenu sections={options} />
                </div>
              ) : null}
              <div>
                <Typography variant="h4">{title}</Typography>
              </div>
            </div>
            <div
              className={
                isStaticImage ? classes.staticSections : classes.sections
              }
            >
              {children}
            </div>
          </CardContent>
        </MuiCard>
      </Loader>
    </Grid>
  );
};
export default CardLayout;
