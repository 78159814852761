import React, { useMemo, useState } from "react";
import moment from "moment";
import EditDeleteTable from "../tables/EditDeleteTable";
import { DeleteSqueeze, UpdateSqueeze } from "../../api/squeeze";
import SqueezeForm from "../forms/SqueezeForm";
import config from "../../constants/squeeze";
import { useTheme } from "@material-ui/core";
import {
  analyticsTableToolbar,
  defaultTableOptions,
} from "../pages/Analytics/util";

import { useReloadChartData } from "../../data/useChartData";

const SqueezeTable = ({ data, user, field, range }) => {
  const { reloadData } = useReloadChartData();
  // Squeezes
  const [squeezeId, setSqueezeId] = useState(null);
  const squeezeRecord = useMemo(() => {
    const record = squeezeId && data.filter((item) => item.id === squeezeId);
    return record && record.length && record[0];
  }, [squeezeId]);
  const squeezes = useMemo(
    () =>
      data.length && {
        columns: [
          { title: "Timestamp", field: "timestamp_label" },
          { title: "Type", field: "type" },
        ],
        data: data.map((item) => ({
          ...item,
          type: config.options[item.type],
          timestamp_label: moment(item.timestamp).format("DD/MM/YY HH:mm"),
        })),
      },
    [data]
  );
  const handleUpdate = ({ type, timestamp }) => {
    return UpdateSqueeze(user.token, user.client.id, field.id, squeezeId, {
      type,
      timestamp: moment
        .tz(moment(timestamp).format("YYYY-MM-DD HH:mm:ss"), field.timezone)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
    });
  };
  const handleDelete = () =>
    DeleteSqueeze(user.token, user.client.id, field.id, squeezeId);
  const handleRefresh = () => {
    reloadData();
  };

  const theme = useTheme();
  const tableConfig = {
    title: "Observations",
    components: analyticsTableToolbar(theme),
    options: defaultTableOptions,
  };

  return (
    <EditDeleteTable
      title="Observation Records"
      type="Observation Record"
      data={{ ...squeezes, ...tableConfig }}
      record={squeezeRecord}
      renderForm={(props) => (
        <SqueezeForm
          title="Amend the Observation details:"
          timezone={field.timezone}
          {...props}
        />
      )}
      setRecord={setSqueezeId}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      onRefresh={handleRefresh}
    />
  );
};

export default SqueezeTable;
