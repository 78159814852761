import moment, { Moment } from "moment";

type Param = {
  id: number;
  client_id: number;
  field_id: number;
  type: number;
  description: string;
  timestamp: string;
  group: string;
  value: string;
};

type Types = {
  id: number;
  name: number;
  slug: string;
};
export const formatChartParams = (
  paramsData: Param[] | undefined,
  typesData: Types[] | undefined,
  timezone: string,
  range: {
    start_date: Moment;
    end_date: Moment;
  }
) => {
  const params: Param[] = paramsData ? [...paramsData] : [];
  const types: Types[] = typesData ?? [];
  const events: { x: moment.Moment; label: string }[] = [];

  // sort params by timestamp
  params.sort((a, b) => {
    return moment(a.timestamp).diff(moment(b.timestamp));
  });

  // Create initial data structure
  let chart = params.reduce(
    (obj: { [key: string]: { x: moment.Moment; y: string }[] }, item: any) => {
      obj[item.type] = [];
      return obj;
    },
    {}
  );

  // Populate with provided params
  params.forEach((item) => {
    const timestamp = moment(
      moment(item.timestamp).clone().tz(timezone).format("YYYY-MM-DD HH:mm:ss")
    );
    if (timestamp.isBefore(range.start_date)) {
      // if chart already has a start of line for this type, overwrite it
      // since params are sorted by timestamp, checking for any elements is sufficient
      if (chart[item.type].length) {
        chart[item.type][0] = {
          x: moment(range.start_date),
          y: item.value,
        };
      } else {
        // set the start of the line
        chart[item.type].push({
          x: moment(range.start_date),
          y: item.value,
        });
      }
    } else {
      // add the adjusted value
      chart[item.type].push({ x: timestamp, y: item.value });

      if (!events.map((item) => item.x).includes(timestamp)) {
        events.push({ x: timestamp, label: item.description });
      }
    }
  });

  const formattedParams = Object.keys(chart).reduce(
    (obj: any, item: string) => {
      const count = chart[item].length;
      const arr = [];
      if (count > 1) {
        let prev: { x: moment.Moment; y: string } | null = null;
        chart[item].forEach(
          (record: { x: moment.Moment; y: string }, index: number) => {
            if (index === 0) {
              arr.push(record);
            } else {
              arr.push({ x: record.x, y: prev?.y });
              arr.push(record);
              if (index === count - 1) {
                arr.push({
                  x: moment(range.end_date).add(1, "month"),
                  y: record.y,
                });
              }
            }
            prev = record;
          }
        );
      } else {
        const record = chart[item][0];
        arr.push({ x: moment(range.start_date), y: record.y });
        arr.push({ x: moment(range.end_date).add(1, "month"), y: record.y });
      }

      const type = types.filter((type) => type.id === parseInt(item));
      const key = type.length ? type[0].slug : item;

      obj[key] = arr;
      return obj;
    },
    {}
  );

  return { events, settings: formattedParams };
};
