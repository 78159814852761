import { useState, useEffect, ReactElement } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Divider,
  Typography,
} from "@material-ui/core";
import FormButton from "../forms/FormButton";
import { sidebarStyles } from "./SidebarContent";
import { useSidebarDispatch } from "../../context/sidebar";
import api from "../../api/agritech";
import { handleRequestError } from "../../helpers";
import { UserState, useAuthDispatch } from "../../context/auth";
import { BaseSetting } from "../../context/settings";

const SettingsForm = ({
  user,
  label,
  items,
  subheader,
  buttonText,
  activeButtonText,
  setLoading,
}: {
  user: UserState;
  label: string;
  items: BaseSetting[];
  subheader: ReactElement;
  buttonText?: string;
  activeButtonText?: string;
  setLoading?: (state: boolean) => void;
}) => {
  const classes = sidebarStyles();
  const [dragActive, setDragActive] = useState(false);
  const [list, setList] = useState<BaseSetting[]>([]);
  const [pristine, setPristine] = useState(true);

  useEffect(() => {
    setList(items);
  }, [items]);

  const handleDragStart = () => setDragActive(true);
  const handleDragEnd: OnDragEndResponder = (result) => {
    const { destination, source, draggableId } = result;
    // do nothing if dragged outside or returned to the same location
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    setDragActive(false);
    setPristine(false);
    // reorder user field list
    // split on first dash
    const id = draggableId.split(/-(.*)/)[1];
    const listOrder = [...list];
    const movedItem = listOrder.filter((item) => item.id.toString() === id);
    listOrder.splice(source.index, 1);
    listOrder.splice(destination.index, 0, movedItem[0]);
    setList(listOrder.map((item, index) => ({ ...item, order: index })));
  };

  const handleToggle = (id: BaseSetting["id"]) => {
    setPristine(false);
    setList(
      [...list].map((item) =>
        item.id === id ? { ...item, hidden: (1 - item.hidden) as 0 | 1 } : item
      )
    );
  };

  const authDispatch = useAuthDispatch();
  const sidebarDispatch = useSidebarDispatch();
  const handleSave = async (updatedSettings: BaseSetting[]) => {
    sidebarDispatch({ type: "setSettingsOpen", payload: false });
    // save to API
    try {
      const response = await api.patch(
        `users/${user.id}/settings`,
        { settings: updatedSettings },
        { headers: { Authorization: "Bearer " + user.token } }
      );
      const { settings } = response.data.data;
      authDispatch({ type: "setUser", payload: { settings } });
      setLoading && setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed to save settings: ");
      setLoading && setLoading(false);
    }
  };

  return (
    <div className={classes.settingsList}>
      <Divider />
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Droppable droppableId={`settings-${label}`}>
          {(provided) => (
            <List
              dense
              subheader={subheader}
              innerRef={provided.innerRef}
              {...provided.droppableProps}
            >
              {list.length > 0 &&
                list.map((item, index) => (
                  <Draggable
                    key={item.type + "-" + item.id}
                    draggableId={item.type + "-" + item.id}
                    index={index}
                  >
                    {(provided) =>
                      item && (
                        <ListItem
                          button
                          innerRef={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classes.listItem}
                        >
                          <ListItemText>
                            <Typography variant="body2" noWrap={true}>
                              {item.label}
                            </Typography>
                          </ListItemText>
                          {!dragActive && (
                            <ListItemSecondaryAction>
                              <Switch
                                edge="end"
                                color="primary"
                                onChange={() => handleToggle(item.id)}
                                checked={!item.hidden}
                              />
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                      )
                    }
                  </Draggable>
                ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      <FormButton
        text={buttonText ? buttonText : "Save"}
        disabled={pristine}
        variant="contained"
        color="primary"
        handleClick={() => {
          setLoading && setLoading(true);
          handleSave(list);
          setPristine(true);
        }}
        waitingText={activeButtonText ? activeButtonText : "Saving"}
        buttonStyles={classes.formButton}
      />
    </div>
  );
};

export default SettingsForm;
