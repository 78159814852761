import { irrimax } from "../api/agritech";
import { handleRequestError } from "../helpers";

async function fetchIrrimax(folder) {
  try {
    const folderName = folder.replace("/[^a-zA-Z0-9-_.]/", "").toLowerCase();
    const response = await irrimax.get(
      "/irrimax/" + folderName + "/workspace.html"
    );
    const cleanedResponse = JSON.parse(
      response.data.replace(/(?:\r\n|\r|\n)/g, "").replace("},]", "}]")
    );
    // Note: these images are stored on the old FTP server, hence the different domain name!
    const baseUrl =
      "https://dashboard.agri-tech.co.uk/irrimax/" + folderName + "/";

    return cleanedResponse.map((field) => ({
      ...field,
      link: baseUrl + field.link,
      gauge: baseUrl + field.gauge,
    }));
  } catch (e) {
    handleRequestError(e, "Failed fetching irrimax: ");
  }
}

export { fetchIrrimax };
