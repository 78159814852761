import Card from "../../cards/Card";
import { runIrrigator } from "../../../api/irrigators";
import { SelectInput } from "../../forms/FormInputs";
import { IrrigationAction } from "./actions/IrrigatorAction";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import { useMemo, useState } from "react";
import { useAuthState } from "../../../context/auth";
import { handleRequestSuccess } from "../../../helpers";
import { IrrigatorAction } from "../../../api/automation";

export const ManualIrrigationCard: React.FC<{
  fields: { id: number; name: string }[];
}> = ({ fields }) => {
  const user = useAuthState();

  const [fieldId, setFieldId] = useState("");

  const fieldOptions = useMemo(
    () =>
      fields.reduce((obj, field) => {
        obj[field.id] = field.name;
        return obj;
      }, {} as { [key: number]: string }),
    [fields]
  );

  const fieldInput = {
    value: fieldId,
    onChange: (evt: any) => {
      setFieldId(evt.target.value || "");
    },
  };

  const [irrigatorInfo, setIrrigatorInfo] = useState<Partial<IrrigatorAction>>({
    irrigator_id: undefined,
    command_id: undefined,
    repeat: "always",
  });

  const [logs, setLogs] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    const { irrigator_id, command_id } = irrigatorInfo;
    if (
      !fieldId ||
      !irrigator_id ||
      !command_id ||
      !user.token ||
      !user.client.id
    ) {
      return;
    }
    setIsSubmitting(true);
    const response = await runIrrigator(
      user.token,
      user.client.id,
      irrigator_id,
      command_id
    );
    if (response?.status === 200) {
      const logs = response.data.data;

      handleRequestSuccess("Irrigation request sent");
      setLogs(logs.length ? logs.join("\n") : "No logs returned");

      setIsSubmitting(false);

      // reset the form
      setFieldId("");
      setIrrigatorInfo({
        irrigator_id: undefined,
        command_id: undefined,
      });
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <Card headline={"Manual Irrigation "} headlineVariant="h3" divider={true}>
      <SelectInput
        id="targetField"
        label="Target Field"
        input={fieldInput}
        options={fieldOptions}
        meta={{}}
      />

      {fieldId && (
        <IrrigationAction
          targetField={fieldId}
          action={irrigatorInfo}
          setAction={setIrrigatorInfo}
          isManual={true}
        />
      )}

      <Box mt={3}>
        <Button
          disabled={
            !fieldId ||
            !irrigatorInfo.irrigator_id ||
            !irrigatorInfo.command_id ||
            isSubmitting
          }
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
        {logs && (
          <>
            <Divider style={{ marginTop: 10 }} />
            <Box mt={2}>
              <Typography variant="h3">Logs</Typography>
              <Typography>{logs}</Typography>
            </Box>
          </>
        )}
      </Box>
    </Card>
  );
};
