import api, { ServerResponse } from "./agritech";
import { processAdminLog } from "../helpers/processLog";

export type AdminLog = {
  id: number;
  severity: "error" | "warning" | "log";
  message: string;
  created_at: string;
  field_id: number | null;
  sync_id: string | null;
  client_name?: string;
  field_name?: string;
};

async function _listAdminLogs(
  token: string,
  fieldId?: number,
  rootLogId?: number
) {
  if (fieldId) {
    return await api.get<ServerResponse<AdminLog[]>>(
      `/admin_logs?field=${fieldId}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  } else if (rootLogId) {
    return await api.get<ServerResponse<AdminLog[]>>(
      `/admin_logs?import_id=${rootLogId}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  } else {
    return await api.get<ServerResponse<AdminLog[]>>(`/admin_logs`, {
      headers: { Authorization: "Bearer " + token },
    });
  }
}

export async function listAdminLogs(
  ...args: Parameters<typeof _listAdminLogs>
) {
  const response = await _listAdminLogs(...args);
  return response.data.data.map(processAdminLog);
}

export const syncConfigWithProd = (token: string) => {
  return api.post<ServerResponse<void>>(`/syncFieldConfig`, undefined, {
    headers: { Authorization: "Bearer " + token },
  });
};
