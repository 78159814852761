import React from "react";
import { ClimateMetric, SoilMetric } from "../../../constants/metrics";
import { DefaultConditionChart } from "./ConditionCharts/DefaultConditionChart";
import { MoistureContentConditionChart } from "./ConditionCharts/MoistureContentConditionChart";

export const ConditionChart: React.FC<{
  field: { id: string; timezone: string };
  metric: ClimateMetric | SoilMetric | undefined;
}> = ({ field, metric }) => {
  if (!metric) return null;

  if (metric === "moisture_content") {
    return <MoistureContentConditionChart field={field} />;
  } else {
    return <DefaultConditionChart field={field} metric={metric} />;
  }
};
