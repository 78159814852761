import React, { useState, useMemo } from "react";

import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import { makeStyles, CardContent } from "@material-ui/core";
import AgronomicsForm from "../forms/AgronomicsForm";
import FormButton from "../forms/FormButton";
import {
  normaliseAgronomicsKeys,
  UpdateAgronomics,
} from "../../api/agronomics";
import { triggerToast, handleRequestError } from "../../helpers";
import { useReloadChartData } from "../../data/useChartData";
import { unshiftTimestamp } from "../../helpers/timezones";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const UpdateAgronomicsRecord = ({ field, user, record, closeModal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [valid, setValid] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const data = useMemo(
    () =>
      record && {
        ...record,
        timestamp: unshiftTimestamp(record.timestamp, field.timezone),
      },
    [record, field.timezone]
  );

  const { reloadData } = useReloadChartData();

  const handleAgronomicsUpdate = async (formValues) => {
    const record = normaliseAgronomicsKeys({
      ...formValues,
      timestamp: formValues.timestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
    });
    try {
      const response = await UpdateAgronomics(
        user.token,
        user.client.id,
        field.id,
        record.id,
        record
      );
      reloadData();
      dispatch(triggerToast(response.message, { variant: "success" }));
      closeModal();
      return response.data;
    } catch (e) {
      handleRequestError(e, "Failed to update Agronomics data: ");
    }
  };

  return (
    <CardContent>
      {data !== null && (
        <AgronomicsForm
          title="Amend the Agronomics data:"
          isValid={(status) => setValid(status)}
          isWaiting={(status) => setWaiting(status)}
          onSubmit={handleAgronomicsUpdate}
          initialValues={data}
          timezone={field && field.timezone}
        />
      )}

      <div className={classes.buttonGroup}>
        <FormButton text="Close" handleClick={closeModal} />
        <FormButton
          text="Update"
          disabled={!valid || waiting}
          handleClick={() => dispatch(submit("RecordAgronomics"))}
          variant="contained"
          color="primary"
          waiting={waiting}
          waitingText="Saving"
        />
      </div>
    </CardContent>
  );
};

export default UpdateAgronomicsRecord;
