import React from "react";
import clsx from "clsx";
import {
  makeStyles,
  Grid,
  Drawer,
  IconButton,
  Divider,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useSidebarState } from "../../context/sidebar";
import MainNav from "../sidebar/MainNav";

const Sidebar = ({ children, drawerWidth, onToggleSidebar }) => {
  const useStyles = makeStyles((theme) => ({
    sidebar: {
      whiteSpace: "nowrap",
      width: drawerWidth,
      background: theme.palette.secondary.main,
      borderRightWidth: 0,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    sidebarCompact: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(9),
      },
    },
    sidebarContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    sidebarNav: {
      flexGrow: 1,
    },
    sidebarHeader: {
      background: theme.palette.primary.main,
      ...theme.mixins.toolbar,
    },
    sidebarFooter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 8px",
    },
    sidebarButtonHidden: {
      display: "none",
    },
    grid: {
      height: "100%"
    },
  }));
  const classes = useStyles();
  const { open } = useSidebarState();

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.sidebar, !open && classes.sidebarCompact),
      }}
      open={open}
    >
      <div className={classes.sidebarHeader}></div>
      <div className={classes.sidebarContainer}>
        <div className={classes.sidebarNav}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            className={classes.grid}
          >
            <MainNav />
            {children}
          </Grid>
        </div>
        <Divider />
        <div className={classes.sidebarFooter}>
          <IconButton
            aria-label="open drawer"
            onClick={onToggleSidebar}
            className={clsx(open && classes.sidebarButtonHidden)}
          >
            <ChevronRightIcon />
          </IconButton>
          <IconButton
            aria-label="close drawer"
            onClick={onToggleSidebar}
            className={clsx(!open && classes.sidebarButtonHidden)}
          >
            <ChevronLeftIcon color="disabled" />
          </IconButton>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
