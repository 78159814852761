export default {
  site: {
    name: "Agri-Tech",
    url: "https://agri-tech.co.uk/",
  },
  company: {
    name: "Agri-Tech Services Ltd.",
  },
};

export const isStaging =
  process.env.NODE_ENV === "development" ||
  process.env.REACT_API_URL?.includes("staging");
