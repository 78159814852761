import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Typography, capitalize } from "@material-ui/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import moment from "moment";
import { handleRequestError } from "../../../helpers";
import { listAdminLogs, AdminLog } from "../../../api/admin";
import AdminTable from "./AdminTable";
import { UserState } from "../../../context/auth";

const AdminLogsTable: React.FC<{ user: UserState }> = ({ user }) => {
  const [allLogs, setAllLogs] = useState<AdminLog[]>();
  const [logsForSelection, setLogsForSelection] = useState<AdminLog[]>();

  const allLogsConfig = useMemo(
    () => ({
      columns: [
        {
          title: "Time",
          field: "created_at",
          width: 200,
          render: (x: AdminLog) =>
            moment(x.created_at).format("DD/MM/YYYY HH:mm"),
        },
        {
          title: "Type",
          field: "severity",
          width: 100,
          render: ({ severity }) => capitalize(severity),
        },
        {
          title: "Message",
          field: "message",
        },
      ],
      data: allLogs,
      options: { pageSizeOptions: [5, 10, 20, 50] },
    }),
    [allLogs]
  );

  const logsForSelectionConfig = useMemo(
    () => ({
      columns: [
        {
          title: "Time",
          field: "created_at",
          width: 200,
          render: (x: AdminLog) =>
            moment(x.created_at).format("DD/MM/YYYY HH:mm"),
        },
        {
          title: "Type",
          field: "severity",
          width: 60,
          render: ({ severity }: AdminLog) => capitalize(severity),
        },
        { title: "Client", field: "client_name", width: 120 },
        { title: "Field", field: "field_name", width: 120 },

        {
          title: "Field ID",
          field: "field_id",
          width: 40,
        },
        {
          title: "Message",
          field: "message",
        },
      ],
      data: logsForSelection,
      options: {
        pageSizeOptions: [5, 10, 20, 50],
        exportAllData: true,
        exportMenu: [
          {
            label: "Export PDF",
            exportFunc: (cols: any[], data: AdminLog[]) =>
              ExportPdf(cols, data, "export"),
          },
          {
            label: "Export CSV",
            exportFunc: (cols: any[], data: AdminLog[]) =>
              ExportCsv(cols, data, "export"),
          },
        ],
      },
    }),
    [logsForSelection]
  );

  useEffect(() => {
    loadAdminLogs();
  }, []);

  const loadAdminLogs = useCallback(async () => {
    if (!user.token) return;
    try {
      const response = await listAdminLogs(user.token);
      setAllLogs(response);
    } catch (e) {
      handleRequestError(e, "Failed fetching admin logs: ");
    }
  }, [user.token]);

  const [selectedRoot, setSelectedRoot] = useState<AdminLog>();
  const loadLogsForId = useCallback(async () => {
    if (!selectedRoot || !user.token) return;
    try {
      const response = await listAdminLogs(
        user.token,
        undefined,
        selectedRoot.id
      );
      setLogsForSelection(response);
    } catch (e) {
      handleRequestError(e, "Failed fetching admin logs for date: ");
    }
  }, [user.token, selectedRoot]);

  useEffect(() => {
    loadLogsForId();
  }, [selectedRoot]);

  return (
    <>
      <AdminTable
        title={<Typography variant="h2">Data Sync Logs</Typography>}
        type="log"
        config={allLogsConfig}
        deleteLabel="id"
        onView={(x, row: AdminLog) => {
          setSelectedRoot(row);
        }}
      />
      {selectedRoot !== undefined && (
        <AdminTable
          title={
            <Typography variant="h2">
              Data Sync Logs for{" "}
              {moment(selectedRoot.created_at).format("DD/MM/YYYY HH:mm")}
            </Typography>
          }
          type="log"
          config={logsForSelectionConfig}
          deleteLabel="id"
        />
      )}
    </>
  );
};

export default AdminLogsTable;
