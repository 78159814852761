import React, { useMemo } from "react";
import moment from "moment";
import { useAuthState } from "../../../../../../../context/auth";
import RecommendationTable from "../../../../../../tables/RecommendationTable";
import SqueezeTable from "../../../../../../tables/SqueezeTable";
import ParameterTable from "../../../../../../tables/ParametersTable";
import Card from "../..";
import Table, {
  analyticsTableToolbar,
  defaultTableOptions,
} from "../../../../../../tables/ChartTable";
import { useTheme } from "@material-ui/core";
import { useChartData } from "../../../../../../../data/useChartData";
import { formatChartParams } from "../../../../../../../helpers/formatChartParams";

const RootZoneMoisture = ({
  chart,
  data,
  loading,
  tableData,
  field,
  range: { start_date, end_date },
}) => {
  const user = useAuthState();

  const range = useMemo(
    () => ({
      start_date: moment(
        start_date.clone().tz(field.timezone).format("YYYY-MM-DD HH:mm:ss")
      ),
      end_date: moment(
        end_date.clone().tz(field.timezone).format("YYYY-MM-DD HH:mm:ss")
      ),
    }),
    [end_date, start_date, field.timezone]
  );

  const { chartData: paramsData } = useChartData("parameters", range, field.id);
  const { chartData: typesData } = useChartData(
    "parameter_types",
    range,
    field.id
  );
  const { chartData: squeezeData } = useChartData("squeezes", range, field.id);
  const { chartData: recommendationData } = useChartData(
    "recommendations",
    range,
    field.id
  );

  const formatParams = useMemo(
    () => formatChartParams(paramsData, typesData, field.timezone, range),
    [field.timezone, range, paramsData, typesData]
  );

  const chartData = useMemo(() => {
    return data
      ? {
          ...data,
          ...field,
          squeeze:
            squeezeData &&
            squeezeData.map((item) => ({
              timestamp: item.timestamp,
              squeeze: item.value,
              label: item.type.toUpperCase(),
            })),
          params: formatParams,
        }
      : null;
  }, [data, squeezeData, formatParams, field.timezone]);

  const theme = useTheme();
  const tableConfig = {
    components: analyticsTableToolbar(theme),
    options: defaultTableOptions,
  };

  return (
    <Card
      loading={loading}
      chart={chart}
      data={chartData}
      tables={[
        {
          title: "Sensor Data",
          display: false,
          table: (
            <Table
              {...{
                ...tableData,
                title: "Moisture Sensor Data",
                ...tableConfig,
                range: range,
              }}
            />
          ),
        },
        {
          title: "Recommendations",
          display: false,
          table: (
            <RecommendationTable
              data={recommendationData}
              user={user}
              field={field}
              range={range}
            />
          ),
        },
        {
          title: "Observations",
          display: false,
          table: (
            <SqueezeTable
              data={squeezeData}
              user={user}
              field={field}
              range={range}
            />
          ),
        },
        {
          title: "Parameters",
          display: false,
          table: (
            <ParameterTable
              data={{ params: paramsData, types: typesData }}
              user={user}
              field={field}
              range={range}
            />
          ),
        },
      ]}
    />
  );
};

export default RootZoneMoisture;
