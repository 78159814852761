import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  useClientsState,
  useClientsDispatch,
  ClientsProvider,
  fetchClients,
} from "../../../context/clients";
import { useAuthState } from "../../../context/auth";
import Layout from "../../layout/Layout";
import {
  makeStyles,
  Grid,
  Typography,
  Chip,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";

import { listModules, ModuleInfo } from "../../../api/modules";
import { handleRequestError } from "../../../helpers";
import Loader from "../../elements/Loader";
import UsersTable from "../../tables/admin/UsersTable";
import FieldsTable, { ParsedField } from "../../tables/admin/FieldsTable";
import ClientsTable from "../../tables/admin/ClientsTable";
import LoggersTable from "../../tables/admin/LoggersTable";
import CardWarning from "../../cards/CardWarning";
import IrrigatorsTable from "../../tables/admin/IrrigatorsTable";
import IrrigatorCommandsTable from "../../tables/admin/IrrigatorCommandsTable";
import IrrigatorTypesTable from "../../tables/admin/IrrigatorTypesTable";

import { listDeletedFields } from "../../../api/fields";
import { listLoggers } from "../../../api/loggers";

import "./Admin.css";

import {
  listIrrigators,
  listIrrigatorCommands,
  listIrrigatorTypes,
  Irrigator,
  IrrigatorCommand,
  IrrigatorType,
} from "../../../api/irrigators";
import AdminLogsTable from "../../tables/admin/LogTable";
import { Client } from "../../../api/clients";
import { isStaging } from "../../../constants/app";
import { SyncConfigButton } from "./SyncConfigButton";
import { FieldStatusTable } from "../../tables/admin/FieldStatusTable";

export const useStyles = makeStyles((theme) => ({
  spacer: {
    marginTop: theme.spacing(3),
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    fontWeight: "bold",
  },
  buttonDelete: {
    marginRight: theme.spacing(2),
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
  subTitle: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },

  adminContainer: {
    display: "flex",
  },
}));

const Admin = () => {
  return (
    <ClientsProvider>
      <AdminContent />
    </ClientsProvider>
  );
};

const formatFields = ({
  fields,
  client,
}: {
  fields: Client["fields"];
  client: Pick<Client, "name" | "id">;
}) =>
  fields?.map((field) => {
    return {
      ...field,
      import_summary: field.import_summary && JSON.parse(field.import_summary),
      client: { id: client.id!, name: client.name },
      client_id: undefined,
    };
  });

type AdminTab = "clients" | "irrigation" | "data-sync";

const AdminContent = () => {
  const classes = useStyles();
  const user = useAuthState();
  const [tab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState<ModuleInfo[]>([]);
  const [deletedFields, setDeletedFields] = useState([]);
  const [irrigators, setIrrigators] = useState<Irrigator[]>([]);
  const [irrigatorCommands, setIrrigatorCommands] = useState<
    IrrigatorCommand[]
  >([]);
  const [irrigatorTypes, setIrrigatorTypes] = useState<IrrigatorType[]>([]);
  const [activeClient, setActiveClient] = useState<Client>();
  const [activeField, setActiveField] = useState<ParsedField>();
  const clients = useClientsState();
  const clientsDispatch = useClientsDispatch();
  const [loggers, setLoggers] = useState([]);
  const users = useMemo(() => {
    return activeClient
      ? activeClient.users?.map((item) => ({
          ...item,
          client: activeClient.name,
        }))
      : clients
          .map((item) =>
            item?.users?.map((user) => ({
              ...user,
              client: item.name,
            }))
          )
          .flat(1);
  }, [activeClient, clients]);

  const handleTabChange = (event: unknown, newValue: number) => {
    setTab(newValue);
  };

  const fields = useMemo<ParsedField[] | undefined>(() => {
    if (tab) {
      return activeClient && deletedFields?.length
        ? formatFields({
            fields: deletedFields,
            client: { name: activeClient.name, id: activeClient.id },
          })
        : [];
    }

    return activeClient
      ? formatFields({
          fields: activeClient.fields,
          client: { name: activeClient.name, id: activeClient.id },
        })
      : clients
          .map(
            (client) =>
              formatFields({
                fields: client.fields,
                client: { name: client.name, id: client.id },
              }) ?? []
          )
          .flat();
  }, [activeClient, clients, deletedFields, tab]);

  const filteredloggers = useMemo(
    () => (activeField ? activeField.loggers : loggers),
    [activeField, loggers]
  );

  async function fetchPermissions(token: string) {
    setIsLoading(true);
    try {
      const { data: modules } = await listModules(token);
      setPermissions(modules);
      setIsLoading(false);
      return modules;
    } catch (e) {
      setIsLoading(false);
      handleRequestError(e, "Failed fetching modules: ");
    }
  }

  const loadLoggers = useCallback(async () => {
    if (!user.isAdmin || !user.token) return;
    setIsLoading(true);
    try {
      const response = await listLoggers(user.token);
      setLoggers(response.data);
      setIsLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching logger: ");
      setIsLoading(false);
    }
  }, [user.token]);

  const loadDeletedFields = useCallback(async () => {
    if (activeClient?.id && user.token) {
      try {
        const response = await listDeletedFields(user.token, activeClient.id);
        setDeletedFields(response.data);
        return response.data;
      } catch (e) {
        handleRequestError(e, "Failed fetching deleted fields: ");
      }
    }
  }, [activeClient, user.token]);

  const loadIrrigators = useCallback(async () => {
    if (!user.token || !user.isAdmin) return;
    try {
      const response = await listIrrigators(user.token);
      setIrrigators(response.data);
    } catch (e) {
      handleRequestError(e, "Failed fetching irrigators: ");
    }
  }, [user.token]);

  const loadIrrigatorCommands = useCallback(async () => {
    if (!user.token) return;
    try {
      const response = await listIrrigatorCommands(user.token);
      setIrrigatorCommands(response.data);
    } catch (e) {
      handleRequestError(e, "Failed fetching irrigator commands: ");
    }
  }, [user.token]);

  const loadIrrigatorTypes = useCallback(async () => {
    if (!user.token) return;
    try {
      const response = await listIrrigatorTypes(user.token);
      setIrrigatorTypes(response.data);
    } catch (e) {
      handleRequestError(e, "Failed fetching irrigator types: ");
    }
  }, [user.token]);

  async function handleRefresh() {
    if (!user.token) return;
    setIsLoading(true);
    try {
      const clientData = await fetchClients(clientsDispatch, user.token);
      loadDeletedFields();
      setIsLoading(false);
      return clientData;
    } catch (e) {
      handleRequestError(e, "Failed fetching logger: ");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    user.token && fetchPermissions(user.token);
  }, [user.token]);

  useEffect(() => {
    loadDeletedFields();
  }, [activeClient, loadDeletedFields, user.token]);

  useEffect(() => {
    if (!user.token) return;
    fetchClients(clientsDispatch, user.token);
    loadLoggers();
  }, [loadLoggers, clientsDispatch, user.token]);

  useEffect(() => {
    loadIrrigators();
    loadIrrigatorCommands();
    loadIrrigatorTypes();
  }, []);

  const defaultProps = {
    classes,
    onRefresh: handleRefresh,
    user,
  };

  const [currentView, setCurrentView] = useState<AdminTab>("clients");

  const showSyncButton = useMemo(() => {
    return isStaging && user.isAdmin;
  }, [user]);

  return (
    <Layout>
      <Loader
        active={isLoading}
        showChildren={false}
        message="Loading client data..."
      >
        <div className="admin-container">
          {user.isAdmin && (
            <div className="admin-nav">
              <h2>Admin</h2>
              <button
                className={
                  currentView === "clients"
                    ? "admin-nav-btn active"
                    : "admin-nav-btn"
                }
                onClick={() => setCurrentView("clients")}
              >
                Clients
              </button>
              <button
                className={
                  currentView === "irrigation"
                    ? "admin-nav-btn active"
                    : "admin-nav-btn"
                }
                onClick={() => setCurrentView("irrigation")}
              >
                Irrigation & Automation
              </button>
              <button
                className={
                  currentView === "data-sync"
                    ? "admin-nav-btn active"
                    : "admin-nav-btn"
                }
                onClick={() => setCurrentView("data-sync")}
              >
                Data Sync
              </button>
            </div>
          )}
          <div className="admin-content">
            {(activeClient || activeField) && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item>
                  <Typography variant="h6" color="textSecondary">
                    FILTERS:
                  </Typography>
                </Grid>
                {activeClient && (
                  <Grid item>
                    <Chip
                      color="primary"
                      label={activeClient.name}
                      onDelete={() => setActiveClient(undefined)}
                    />
                  </Grid>
                )}
                {activeField && (
                  <Grid item>
                    <Chip
                      color="primary"
                      label={activeField.name}
                      onDelete={() => setActiveField(undefined)}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            <Grid container spacing={3}>
              {currentView === "clients" && (
                <>
                  <Grid item xs={12}>
                    {showSyncButton && (
                      <Box display="flex" justifyContent="end">
                        <SyncConfigButton user={user} />
                      </Box>
                    )}
                    <ClientsTable
                      data={clients}
                      permissions={permissions}
                      activeClient={activeClient}
                      onRowClick={(event: unknown, rowData: Client) =>
                        activeClient && activeClient.id === rowData.id
                          ? setActiveClient(undefined)
                          : setActiveClient(rowData)
                      }
                      {...defaultProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Tabs
                      indicatorColor="primary"
                      value={tab}
                      onChange={handleTabChange}
                    >
                      <Tab label="Live" />
                      <Tab label="Archived" />
                    </Tabs>
                    <div hidden={tab !== 0}>
                      <FieldsTable
                        data={fields}
                        status={[
                          "create",
                          "edit",
                          "delete",
                          "dataDelete",
                          "import",
                          "refreshMildew",
                        ]}
                        activeField={activeField}
                        onRowClick={(event, rowData) =>
                          activeField && activeField.id === rowData.id
                            ? setActiveField(undefined)
                            : setActiveField(rowData)
                        }
                        {...defaultProps}
                      />
                      {user.isAdmin && (
                        <>
                          <LoggersTable
                            data={filteredloggers}
                            user={user}
                            classes={classes}
                            onRefresh={loadLoggers}
                          />
                          <UsersTable data={users} {...defaultProps} />
                        </>
                      )}
                    </div>
                    <div hidden={tab !== 1}>
                      {activeClient ? (
                        <FieldsTable
                          data={fields}
                          status={["restore"]}
                          {...(defaultProps as any)}
                        />
                      ) : (
                        <CardWarning message="Please select a client." />
                      )}
                    </div>
                  </Grid>
                </>
              )}

              {currentView === "data-sync" && (
                <Grid item xs={12}>
                  <FieldStatusTable fields={fields} />
                  <AdminLogsTable user={user} />
                </Grid>
              )}

              {currentView === "irrigation" && (
                <Grid item xs={12}>
                  <IrrigatorsTable
                    user={user}
                    activeClient={activeClient}
                    data={irrigators}
                    types={irrigatorTypes}
                  />
                  <IrrigatorCommandsTable
                    user={user}
                    activeClient={activeClient}
                    data={irrigatorCommands}
                    types={irrigatorTypes}
                    refresh={loadIrrigatorCommands}
                  />
                  <IrrigatorTypesTable
                    user={user}
                    activeClient={activeClient}
                    data={irrigatorTypes}
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </Loader>
    </Layout>
  );
};

export default Admin;
