import React from "react";
import moment from "moment";
import { getLocalDate, percentage } from "../../../../helpers";
import { MTableToolbar } from "@material-table/core";

export function getMetricsValue(
  allData,
  group,
  metric,
  params,
  auto_drain_facility,
  conductivity_line,
  ec_division_factor
) {
  var data = allData[group];

  try {
    let value = null;
    if (metric.substring(0, 3) === "ag_") {
      if (
        auto_drain_facility === "1" &&
        (metric === "ag_ph_in" ||
          metric === "ag_ph_out" ||
          metric === "ag_ec_in" ||
          metric === "ag_ec_out" ||
          metric === "ag_ec_sum" ||
          metric === "ag_ec_bag" ||
          metric === "ag_moisture" ||
          metric === "ag_water_cubes" ||
          metric === "ag_run_off")
      ) {
        const soil_climate_data = allData["soil_climate"];
        value = soil_climate_data[metric.slice(3)];
        if (!value && data[metric.slice(3)]) {
          value = data[metric.slice(3)];
        }
        if (metric.includes("ec") && value > 10) {
          var factor = ec_division_factor
            ? parseFloat(ec_division_factor)
            : 10000;
          return value / factor;
        }
      } else value = data[metric.slice(3)];
    } else {
      const irriTrigger = params && params[8] ? params[8].value : null;
      switch (metric) {
        case "mildew":
          value = Math.round(data.value);
          break;
        case "ambient_temp":
          value = data.temperature;
          break;
        case "humidity":
          value = percentage(Number(data.humidity), 1);
          break;
        case "irri_trigger":
          value = irriTrigger;
          break;
        case "predicted_irri":
          const moisture = data?.moisture_content;
          const waterUsage =
            typeof allData["past24hr_soilClimates_data"]["total_24hr_gains"] ==
            "string"
              ? 0 -
                parseFloat(
                  allData["past24hr_soilClimates_data"]["total_24hr_gains"]
                )
              : null;
          if (irriTrigger && moisture && waterUsage > 0) {
            const days = Math.floor(
              (moisture - irriTrigger) / Math.abs(waterUsage)
            );
            value = moment().add(days, "days").format("DD/MM/YY");
          }
          break;
        case "vic":
          // check if certain conductivity line is shown
          if (
            !conductivity_line ||
            conductivity_line === "default" ||
            conductivity_line === null
          ) {
            value = data[metric];
          } else {
            value = (data["vic_" + conductivity_line] / 1000).toFixed(2);
          }

          break;
        case "run_off":
          value =
            typeof allData["latest_run_off"]["run_off"] == "string"
              ? parseFloat(allData["latest_run_off"]["run_off"]).toFixed(2)
              : null;

          break;
        case "avg_24hr_run_off":
          value =
            typeof allData["past24hr_soilClimates_data"]["avg_24hr_run_off"] ==
            "string"
              ? parseFloat(
                  allData["past24hr_soilClimates_data"]["avg_24hr_run_off"]
                ).toFixed(2)
              : null;
          break;
        case "total_24hr_gains":
          value =
            typeof allData["past24hr_soilClimates_data"]["total_24hr_gains"] ==
            "string"
              ? (
                  0 -
                  parseFloat(
                    allData["past24hr_soilClimates_data"]["total_24hr_gains"]
                  )
                ).toFixed(2)
              : null;
          break;
        default:
          value = data[metric];
          break;
      }
    }
    return value;
  } catch (error) {
    return null;
  }
}

export function getMomentTimestamp(
  data,
  group,
  metric,
  timezone,
  auto_drain_facility
) {
  if (
    auto_drain_facility === "1" &&
    (metric === "ag_ph_in" ||
      metric === "ag_ph_out" ||
      metric === "ag_ec_in" ||
      metric === "ag_ec_out" ||
      metric === "ag_ec_sum" ||
      metric === "ag_moisture" ||
      metric === "ag_water_cubes" ||
      metric === "ag_run_off") &&
    data["soil_climate"]?.timestamp
  ) {
    return moment.utc(
      getLocalDate(data["soil_climate"].timestamp, timezone).format(
        "YYYY-MM-DD HH:mm:ss"
      )
    );
  }
  if (metric === "avg_24hr_run_off") {
    const date = data["past24hr_soilClimates_data"]?.fdate;
    return date ? moment.utc(date) : null;
  }
  if (!data[group]?.timestamp) {
    return null;
  }
  return moment.utc(
    getLocalDate(data[group].timestamp, timezone).format("YYYY-MM-DD HH:mm:ss")
  );
}

export function getFilteredMetrics(
  data,
  kpis,
  metrics,
  metricGroup,
  group,
  timezone,
  params,
  auto_drain_facility,
  conductivity_line,
  ec_division_factor
) {
  return metrics?.length
    ? metrics
        .filter(
          ({ ref, module_id }) =>
            module_id === metricGroup[group] && kpis[group]?.includes(ref)
        )
        .map(({ short_name, ref, suffix, thresholds }) => ({
          short_name,
          ref,
          suffix,
          thresholds,
          value: getMetricsValue(
            data,
            group,
            ref,
            params,
            auto_drain_facility,
            conductivity_line,
            ec_division_factor
          ),
          parameters: ref === "moisture_content" ? data.parameters : null,
          timestamp: getMomentTimestamp(
            data,
            group,
            ref,
            timezone,
            auto_drain_facility
          ),
        }))
    : [];
}

export function getKpis(
  data,
  permissions,
  metrics,
  timezone,
  field_type,
  params,
  auto_drain_facility,
  conductivity_line,
  ec_division_factor
) {
  if (!data) return [];
  const substrateKpis = {
    soil_climate: [
      "run_off",
      "avg_24hr_run_off",
      "moisture_content",
      "gains",
      "vic",
      "soil_temp",
    ],
    agronomics: [
      "ag_ph_in",
      "ag_ph_out",
      "ag_ph_bag",
      "ag_ec_in",
      "ag_ec_out",
      "ag_ec_bag",
      "ag_ec_sum",
    ],
    ambient_climate: ["ambient_temp", "humidity", "vpd"],
    disease_risk: ["mildew"],
  };
  const soilKpis = {
    daily: {
      soil_climate: ["gains"],
    },
    soil_climate: [
      "moisture_content",
      "smd",
      "rainfall",
      "irri_trigger",
      "predicted_irri",
      "total_24hr_gains",
    ],
    agronomics: [],
    ambient_climate: ["ambient_temp", "humidity"],
    disease_risk: [],
  };
  const kpis = field_type === "soil" ? soilKpis : substrateKpis;
  const metricGroup = {
    soil_climate: 4,
    agronomics: 2,
    ambient_climate: 3,
    disease_risk: 1,
  };

  const latest = Object.keys(metricGroup).reduce((arr, group) => {
    if (!permissions.includes(metricGroup[group])) return arr;
    return [
      ...arr,
      ...getFilteredMetrics(
        data,
        kpis,
        metrics,
        metricGroup,
        group,
        timezone,
        params,
        auto_drain_facility,
        conductivity_line,
        ec_division_factor
      ),
    ];
  }, []);

  const daily = kpis?.daily
    ? Object.keys(metricGroup).reduce((arr, group) => {
        if (!permissions.includes(metricGroup[group])) return arr;
        return [
          ...arr,
          ...getFilteredMetrics(
            data,
            kpis.daily,
            metrics,
            metricGroup,
            group,
            timezone,
            params
          ),
        ];
      }, [])
    : [];
  return [...latest, ...daily];
}

export function getVisibility(field, visibility) {
  const settings = visibility.filter(({ field_id }) => field.id === field_id);
  if (!settings.length) {
    return { order: 999, hidden: false };
  }
  const { order, hidden } = settings[0];
  return { order, hidden };
}

export function fieldsWithKpis(fields, permissions, metrics) {
  return fields?.length
    ? fields.map(
        ({
          id,
          name,
          farm,
          field_type,
          crop_type,
          irrimax,
          latest,
          auto_drain_facility,
          conductivity_line,
          timezone,
          ec_division_factor,
        }) => ({
          id,
          name,
          farm,
          field_type,
          crop_type,
          irrimax,
          data: getKpis(
            latest,
            permissions,
            metrics,
            timezone,
            field_type,
            latest?.parameters,
            auto_drain_facility,
            conductivity_line,
            ec_division_factor
          ),
          parameters: latest?.parameters,
        })
      )
    : [];
}

export function filteredSortedFields(fields, { farm, crop_type }) {
  return fields
    .filter(
      (field) =>
        !field.hidden &&
        (farm === "" || farm === field.farm) &&
        (crop_type === "" || crop_type === field.crop_type)
    )
    .sort((a, b) => (a.order > b.order ? 1 : -1));
}

export function fieldsByType(fields, type) {
  return fields.filter(({ field_type }) => field_type === type);
}

export const defaultTableOptions = {
  search: false,
  padding: "dense",
  actionsColumnIndex: -1,
  exportButton: true,
};

export const analyticsTableToolbar = (theme) => ({
  Toolbar: (props) => (
    <div
      style={{
        backgroundColor: theme.palette.tertiary.light,
        color: theme.palette.tertiary.dark,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      }}
    >
      <MTableToolbar {...props} />
    </div>
  ),
});
