import config from "./charts";
const distances = [
  "10",
  "20",
  "30",
  "40",
  "50",
  "60",
  "70",
  "80",
  "90",
] as const;
type Distance = typeof distances[number];
type InOut = "in" | "out";
type InOutBag = InOut | "bag";
export type SoilMetric =
  | "moisture_content"
  | "gains"
  | "vic"
  | "soil_temp"
  | "rainfall"
  | "run_off"
  | `moisture_${Distance}`
  | `vic_${Distance}`
  | `soil_temp_${Distance}`
  | `ph_${InOutBag}`
  | `ec_${InOutBag}`
  | "ec_sum"
  | "water_cubes"
  | `run_off_${InOut}`;

export type ClimateMetric = "temperature" | "humidity" | "vpd";

export const metricLabels = new Map<SoilMetric | ClimateMetric, string>([
  ["moisture_content", "Moisture Content"],
  ["gains", "Gains"],
  ["vic", "VIC"],
  ["soil_temp", "Soil Temperature"],
  ["rainfall", "Rainfall"],
  ["water_cubes", "Water Cubes"],
  ["run_off", "Run Off"],
  ["temperature", "Temperature"],
  ["humidity", "Humidity"],
  ["vpd", "VPD"],
  ["run_off_in", "Run Off In"],
  ["run_off_out", "Run Off Out"],
]);

// create dictionary of chart metric translations
export const chart_metric_translations: Partial<
  Record<ClimateMetric | SoilMetric, keyof typeof config>
> = {
  soil_temp: "soil_temp",
  vic: "vic",
  gains: "soil_moisture_gains",
  run_off: "run_off",
  rainfall: "rainfall",
  ec_bag: "ec",
  ph_bag: "ph",
  temperature: "ambient_climate",
  humidity: "ambient_climate",
  vpd: "vpd",
  moisture_content: "soil_climate",
};

for (const distance of distances) {
  metricLabels.set(`moisture_${distance}`, `Moisture ${distance}cm`);
  metricLabels.set(`vic_${distance}`, `VIC ${distance}cm`);
  metricLabels.set(`soil_temp_${distance}`, `Soil Temp ${distance}cm`);
}

for (const inOut of ["in", "out", "bag"] as const) {
  metricLabels.set(`ph_${inOut}`, `pH ${inOut}`);
  metricLabels.set(`ec_${inOut}`, `EC ${inOut}`);
}
