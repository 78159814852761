import { Typography, TextField } from "@material-ui/core";
import { useState, useMemo, useEffect } from "react";
import { TimeCondition } from "../../../../api/automation";
import { SubConditionInput, Spacer } from "./Helpers";

export const TimeConditionInput: SubConditionInput<TimeCondition> = ({
  value,
  onChange,
}) => {
  const [startTime, setStartTime] = useState(value?.start || undefined);
  const [endTime, setEndTime] = useState(value?.end || undefined);

  const result = useMemo<TimeCondition | undefined>(() => {
    if (startTime === undefined || endTime === undefined) {
      return undefined;
    }
    return {
      type: "time",
      start: startTime,
      end: endTime,
    };
  }, [startTime, endTime]);

  useEffect(() => {
    onChange(result);
  }, [result]);

  return (
    <Typography>
      When the time is between
      <Spacer>
        <TextField
          value={startTime}
          type="time"
          onChange={(e) => setStartTime(e.target.value)}
        />
      </Spacer>
      and
      <Spacer>
        <TextField
          value={endTime}
          type="time"
          onChange={(e) => setEndTime(e.target.value)}
        />
      </Spacer>
      (24 hour format, local time at farm)
    </Typography>
  );
};
