import { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "./Admin";
import {
  Grid,
  Card,
  Box,
  TextField,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { useAuthState } from "../../../context/auth";
import { handleRequestError } from "../../../helpers";
import Loader from "../../elements/Loader";
import {
  addIrrigator,
  updateIrrigator,
  getIrrigator,
  listIrrigatorTypes,
} from "../../../api/irrigators";
import { SelectInput } from "../../forms/FormInputs";
import {
  fetchClients,
  useClientsState,
  useClientsDispatch,
} from "../../../context/clients";

import Headline from "../../elements/Headline";
import FormButton from "../../forms/FormButton";

export const ManageIrrigator = ({ id, setIsLoading }) => {
  const history = useHistory();
  const user = useAuthState();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [irrigator, setIrrigator] = useState(null);
  const [irrigatorTypes, setIrrigatorTypes] = useState([]);

  const clients = useClientsState();
  const clientsDispatch = useClientsDispatch();

  useEffect(() => {
    setIsLoading(loading);
  }, [setIsLoading, loading]);

  useEffect(() => {
    if (!clients.length) {
      fetchClients(clientsDispatch, user.token);
    }
  }, [clientsDispatch, clients, user.token]);

  const loadIrrigator = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getIrrigator(user.token, id);
      setIrrigator(response.data);
      setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching irrigator: ");
      setLoading(false);
    }
  }, [user.token, id]);

  const loadIrrigatorTypes = useCallback(async () => {
    setLoading(true);
    try {
      const response = await listIrrigatorTypes(user.token);
      setIrrigatorTypes(response.data);
      setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching irrigator types: ");
      setLoading(false);
    }
  }, [user.token, id]);

  useEffect(() => {
    loadIrrigatorTypes().then(() => {
      if (id) loadIrrigator();
    });
  }, [loadIrrigator]);

  const [client, setClient] = useState();
  const [name, setName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [type, setType] = useState();

  useEffect(() => {
    if (irrigator?.client_id) {
      setClient(irrigator.client_id.toString());
    }
    if (irrigator?.name) {
      setName(irrigator.name);
    }
    if (irrigator?.phone_number) {
      setPhoneNumber(irrigator.phone_number);
    }
    if (irrigator?.irrigator_type_id) {
      setType(irrigator.irrigator_type_id.toString());
    }
  }, [irrigator]);

  const clientInput = {
    value: client,
    onChange: (evt) => {
      setClient(evt.target.value);
    },
  };

  const typeInput = {
    value: type,
    onChange: (evt) => {
      setType(evt.target.value);
    },
  };

  const clientOptions = useMemo(
    () =>
      clients.reduce((obj, client) => {
        obj[client.id] = client.name;
        return obj;
      }, {}),
    [clients]
  );

  const typeOptions = useMemo(() => {
    return irrigatorTypes.reduce((obj, type) => {
      obj[type.id] = type.name;
      return obj;
    }, {});
  }, [irrigatorTypes]);

  const changed = useMemo(
    () =>
      parseInt(client) !== irrigator?.client_id ||
      name !== irrigator?.name ||
      phoneNumber !== irrigator?.phone_number ||
      parseInt(type) !== irrigator?.irrigator_type_id,
    [client, name, phoneNumber, type, irrigator]
  );

  const missingRequiredInfo = useMemo(() => {
    return !client || !name || !phoneNumber || !type;
  }, [client, name, phoneNumber, type]);

  const isNumber = (value) => {
    // permissive regex since being strict is complicated and requires a whole js library
    return /^\+?[\d\s()-]*$/.test(value);
  };

  const [waiting, setWaiting] = useState(false);

  const onSubmit = useCallback(async () => {
    if (!missingRequiredInfo && changed) {
      setWaiting(true);
      const newIrrigator = {
        name,
        client_id: parseInt(client),
        phone_number: phoneNumber,
        irrigator_type_id: parseInt(type),
      };
      const response = id
        ? await updateIrrigator(user.token, id, newIrrigator)
        : await addIrrigator(user.token, newIrrigator);
      if (response.status === 200) {
        setIrrigator({
          ...irrigator,
          client_id: parseInt(client),
          name,
          phone_number: phoneNumber,
          irrigator_type_id: parseInt(type),
        });
      }
      setWaiting(false);
      if (!id) history.push(`/admin`);
    }
  }, [client, name, phoneNumber, type, changed, id, user.token]);

  return (
    <Loader
      active={id ? loading : false}
      showChildren={false}
      message={`Loading Irrigator...`}
    >
      <Headline
        headline={id ? `Edit Irrigator: ${irrigator?.name}` : "Add Irrigator"}
        headlineVariant="h1"
        divider={true}
      >
        <FormButton
          text={id ? "Update" : "Add"}
          disabled={
            !changed || waiting || missingRequiredInfo || !isNumber(phoneNumber)
          }
          handleClick={() => onSubmit()}
          variant="contained"
          color="primary"
          waiting={waiting}
          waitingText={id ? "Updating" : "Adding"}
        />
      </Headline>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12} lg={7}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.subTitle}
              >
                DETAILS:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <Box p={2} pt={1}>
                  <InputLabel id={`select-${id}-label`}>Name</InputLabel>
                  <TextField
                    style={{ width: "100%" }}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    error={!name}
                  ></TextField>
                </Box>
                <Box p={2} pt={1}>
                  <InputLabel id={`select-${id}-label`}>
                    Phone number
                  </InputLabel>
                  <TextField
                    style={{ width: "100%" }}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    error={!phoneNumber || !isNumber(phoneNumber)}
                  ></TextField>
                </Box>
                <Box p={2} pt={1}>
                  <SelectInput
                    label={"Client"}
                    input={clientInput}
                    options={clientOptions}
                    meta={{ error: !clientInput.value }}
                  />
                </Box>
                <Box p={2} pt={1}>
                  <SelectInput
                    label={"Type"}
                    input={typeInput}
                    options={typeOptions}
                    meta={{ error: !typeInput.value }}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
};
