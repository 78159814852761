import { ServerResponse } from "./agritech";
import api from "./agritech";
import { Client } from "./clients";

type Setting = {
  id: number;
  client_id: number;
  field_id: number;
  type: string;
  ref: string | null;
  hidden: 0 | 1;
  order: number;
};

type AdminAccess = {
  id: number;
  user_id: number;
  client_id: number;
  created_at: string | null;
  updated_at: string | null;
};

export type User = {
  id: number;
  name: string;
  email: string;
  active: 0 | 1;
  admin: 0 | 1;
  created_at: string;
  updated_at: string | null;
  settings: Setting[];
  admin_access: AdminAccess[];
  client: { id: number; name: string };
};

export async function auth(credentials: { email: string; password: string }) {
  const response = await api.post(`login`, credentials);
  return response.data;
}

export async function getUser(token: string) {
  const response = await api.get<
    ServerResponse<{ user: User; client: Client }>
  >(`user`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

export async function refresh(token: string) {
  const response = await api.post(
    `refresh`,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}
