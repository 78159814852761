import {
  Typography,
  Select,
  MenuItem,
  Box,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { useState, useMemo, useEffect, useCallback } from "react";
import { SoilClimateCondition } from "../../../../api/automation";
import {
  ParameterType,
  listParameterTypes,
  listParameters,
} from "../../../../api/parameters";
import { SubConditionInput, Spacer, parseNumber } from "./Helpers";

export const SoilClimateInput: SubConditionInput<SoilClimateCondition> = ({
  value,
  onChange,
  info,
  field,
}) => {
  const [parameterOptions, setParameterOptions] = useState<ParameterType[]>([]);
  const [manuallyEnteredValue, setManuallyEnteredValue] = useState<boolean>(
    value.threshold_manual ? true : false
  );
  const [thresholdId, setThresholdId] = useState<number | undefined>(
    value.threshold_id ?? undefined
  );
  const [thresholdManual, setThresholdManual] = useState<number | undefined>(
    value.threshold_manual ?? undefined
  );
  const [comparison, setComparison] = useState<
    SoilClimateCondition["comparison"] | undefined
  >(value.comparison ?? undefined);
  const comparisonOptions = ["gt", "lt"];

  const result = useMemo<SoilClimateCondition | undefined>(() => {
    if (
      (thresholdId === undefined && thresholdManual === undefined) ||
      comparison === undefined
    ) {
      return undefined;
    }
    return {
      type: "soil_climate",
      threshold_id: thresholdId,
      threshold_manual: thresholdManual,
      comparison,
      threshold_name: manuallyEnteredValue
        ? "manual"
        : parameterOptions.find((p) => p.id === thresholdId)?.name ?? "Unknown",
    };
  }, [
    thresholdId,
    thresholdManual,
    manuallyEnteredValue,
    comparison,
    parameterOptions,
  ]);

  useEffect(() => {
    onChange(result);
  }, [result]);

  const fetchParameters = useCallback(async () => {
    if (info.fieldId === undefined) {
      return;
    }
    const parameterTypes = await listParameterTypes(info.token);
    const parameters = await listParameters(
      info.token,
      info.clientId,
      info.fieldId
    );
    return parameterTypes.data.filter((p) =>
      parameters.data.some((p2) => p2.type === p.id)
    );
  }, [info]);

  useEffect(() => {
    let isSubscribed = true;
    if (info.fieldId) {
      fetchParameters().then((parameters) => {
        if (isSubscribed && parameters) {
          setParameterOptions(parameters);
        }
      });
    }
    return () => {
      isSubscribed = false;
    };
  }, [fetchParameters, info]);

  return (
    <Box>
      <Typography>
        When the soil moisture is
        <Spacer>
          <Select
            label="comparison"
            value={comparison ?? ""}
            onChange={(e) => {
              setComparison(
                e.target.value as SoilClimateCondition["comparison"]
              );
            }}
          >
            {comparisonOptions.map((c) => (
              <MenuItem value={c} key={c}>
                {c === "gt" ? "greater" : "less"}
              </MenuItem>
            ))}
          </Select>
        </Spacer>
        than
        <Spacer>
          {manuallyEnteredValue ? (
            <TextField
              value={thresholdManual}
              type="number"
              onChange={(e) => {
                setThresholdManual(parseNumber(e.target.value));
              }}
              style={{ marginTop: 5, width: 200 }}
            />
          ) : (
            <Select
              value={
                thresholdId !== undefined && parameterOptions.length
                  ? thresholdId
                  : ""
              }
              onChange={(e) => {
                setThresholdId(parseNumber(e.target.value as string));
              }}
              error={
                parameterOptions.length === 0 ||
                (thresholdId !== undefined &&
                  !parameterOptions.some((p) => p.id === thresholdId))
              }
              style={{ width: 200 }}
            >
              {parameterOptions.map((p) => (
                <MenuItem value={p.id} key={p.id}>
                  {p.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Spacer>
        <Spacer>
          <FormControlLabel
            control={
              <Switch
                name="Enabled"
                checked={manuallyEnteredValue}
                onChange={(evt, checked) => {
                  setManuallyEnteredValue(checked);
                  if (checked) setThresholdId(undefined);
                  else setThresholdManual(undefined);
                }}
                color="primary"
              />
            }
            label={"Enter manually"}
          />
        </Spacer>
      </Typography>
    </Box>
  );
};
