import React, { useState } from "react";
import {
  makeStyles,
  useTheme,
  Grid,
} from "@material-ui/core";
import AnalyticsDateTime from "../../../../header/AnalyticsDateTime";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
  },
  expandButton: {
    maxWidth: 48,
  },
}));

const AnalyticsFooter = () => {
  const [open] = useState(false);
  const theme = useTheme();
  const classes = useStyles({ open });

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item>
        <AnalyticsDateTime color={theme.palette.secondary.main} />
      </Grid>
    </Grid>
  );
};

export default AnalyticsFooter;
