import _ from 'lodash';
import React from "react";
import GaugeChart from 'react-gauge-chart'

// https://www.npmjs.com/package/react-gauge-chart

const ChartGrowerMoisture = (config) => {
  const bandArray = !config.isSoil ? [
    {
      slug: 'full_point', // Top of green
      value: -1,
      bound: 0.85,
    },
    {
      slug: 'med_squeeeze', // Top of yellow
      value: -1,
      bound: 0.5,
    },
    {
      slug: 'irri_lower', // Top of red
      value: -1,
      bound: 0.15
    },
  ] : [
    {
      slug: 'field_capactiy', // Top of green
      value: -1,
      bound: 0.85,
    },
    {
      slug: 'irrigation_trigger', // Top of yellow
      value: -1,
      bound: 0.5,
    },
    {
      slug: 'refill_point', // Top of red
      value: -1,
      bound: 0.15
    },
  ];

  const level = parseFloat(config.level);

  // Set default value so that if we can't be clever we can be dumb
  let gaugeReading = level / 100;
  let gaugeBand = 0; // Blue
  let gaugeClever = true;


  _.each(bandArray, (band, i) => {
    // Read value from config
    const bandValue = _.find(config.paramData, (d) => {
      return d.types.slug === band.slug;
    });
    if(!bandValue){
      gaugeClever = false;
      return
    }
    if(level < bandValue.value){
      gaugeBand = i + 1;
    }
    bandArray[i].value = bandValue.value;
  });

  if(gaugeClever){

    switch(gaugeBand){
      case(0): // Blue
        gaugeReading = 0.98;
        break;
      case(3): // Red
        gaugeReading = 0.02;
        break;
      default:
        const upperBound = bandArray[gaugeBand - 1].value;
        const lowerBound = bandArray[gaugeBand].value;
        const rangeBound = upperBound - lowerBound;
        const inBounds = level - lowerBound;
        const inBoundPC = (inBounds / rangeBound)
        const lowerDisplayBound = bandArray[gaugeBand].bound;
        const upperDisplayBound = bandArray[gaugeBand - 1].bound;
        const displayBound = lowerDisplayBound + ((upperDisplayBound - lowerDisplayBound) * inBoundPC);

        gaugeReading = displayBound;
        break;  
    }
  }

  const chartStyle = {
    width: 100,
    margin: '0 auto'
  }

  const divStyle = {
    width:'100%',
    height:50,
    textAlign: 'center'
  }

  return (
    <div style={divStyle}>
      <GaugeChart
        id={config.id}
        nrOfLevels={4} 
        percent={gaugeReading}
        style={chartStyle}
        hideText={true}
        arcsLength={[0.15, 0.35, 0.35, 0.15]}
        colors={["#e1483d", "#ffd95f","#40b2a6", "#62bff4"]} 
      />
    </div>
  )
};

export default ChartGrowerMoisture;
