import React, { ChangeEvent, useMemo } from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Switch,
  TextFieldProps,
  CheckboxProps,
  SwitchProps,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  DateTimePickerProps,
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import DateRangeIcon from "@material-ui/icons/DateRange";
import MomentUtils from "@date-io/moment";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import moment from "moment";

type InputProps<V, E> = {
  label: string;
  input: { name?: string; value: V; onChange: (evt: E) => void };
  meta: { touched?: boolean; invalid?: boolean; error?: boolean };
  disabled?: boolean;
};

export const TextInput = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}: InputProps<string, ChangeEvent<HTMLInputElement | HTMLTextAreaElement>> &
  Partial<TextFieldProps>) => {
  return (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      fullWidth
      margin="normal"
      variant="standard"
      {...input}
      {...custom}
    />
  );
};

export const CheckboxInput = ({
  input,
  label,
  ...custom
}: InputProps<boolean, ChangeEvent<HTMLInputElement>> &
  Partial<CheckboxProps>) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={input.value ? true : false}
        onChange={input.onChange}
        name={input.name}
        color="primary"
        {...custom}
      />
    }
    label={label}
  />
);

export const SelectInput = <
  K extends string | number,
  O extends { name?: string | undefined; value: unknown } | string
>({
  label,
  input,
  id,
  options,
  allowEmpty,
  valueType,
  disabled,
  meta: { error },
}: InputProps<string, ChangeEvent<O>> & {
  id: string;
  options: Record<K, O>;
  valueType?: "number";
  allowEmpty?: boolean;
}) => {
  const val = valueType === "number" ? parseInt(input.value) : input.value;
  return (
    <FormControl fullWidth margin="normal" disabled={disabled}>
      <InputLabel id={`select-${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`select-${id}-label`}
        id={`select-${id}`}
        value={val}
        onChange={(value) => input.onChange(value as ChangeEvent<O>)}
        error={error}
      >
        {allowEmpty && (
          <MenuItem key={`select-${id}-option-empty`} value="">
            <em>None</em>
          </MenuItem>
        )}
        {Object.keys(options)
          .map((item) => ({
            value: item,
            label: options[item as K],
          }))
          .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
          .map(({ label, value }) => (
            <MenuItem key={`select-${id}-option-${value}`} value={value}>
              {label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export const AutocompleteSelectInput = ({
  label,
  input,
  id,
  options,
  disabled,
  meta: { error },
}: InputProps<number, string> & {
  id: string;
  options: Record<number, string>;
}) => {
  //input.value is a number
  const val =
    typeof input.value === "string" ? parseInt(input.value) : input.value;
  const getOptionsValue = (key: number) => options[key] ?? "";
  const optionsMap = useMemo<Map<string | null, string>>(() => {
    return new Map(Object.entries(options).map(([key, value]) => [value, key]));
  }, [options]);

  return (
    <FormControl fullWidth margin="normal" disabled={disabled}>
      <Autocomplete
        value={getOptionsValue(val)}
        onChange={(e, v) => {
          const newKey = optionsMap.get(v) ?? "";
          input.onChange(newKey);
        }}
        id={`autocomplete-select-${id}`}
        options={Object.values(options)}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder="Logger ID" />
        )}
      />
    </FormControl>
  );
};

export const DateTimeInput = ({
  label,
  input,
  meta: { touched, invalid, error },
  timezone,
  keepLocal,
  ...custom
}: InputProps<ParsableDate, unknown> & {
  timezone?: string;
  keepLocal?: boolean;
} & Partial<DateTimePickerProps>) => {
  return (
    <FormControl fullWidth margin="normal">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateTimePicker
          label={label}
          error={touched && invalid}
          helperText={touched && error}
          disableFuture={true}
          format="DD/MM/YY HH:mm"
          onChange={(val) =>
            timezone ? input.onChange(val?.tz(timezone)) : input.onChange(val)
          }
          value={
            timezone ? moment(input.value).tz(timezone, keepLocal) : input.value
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <DateRangeIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...custom}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};

type TrueFalse = "1" | "0" | true | false;

export const ToggleInput = ({
  label,
  input,
  ...custom
}: InputProps<TrueFalse, ChangeEvent<HTMLInputElement>> &
  Partial<SwitchProps>) => {
  return (
    <FormControlLabel
      control={
        <Switch
          edge="end"
          color="primary"
          checked={input.value === "1" || input.value === true ? true : false}
          onChange={input.onChange}
        />
      }
      label={label}
    />
  );
};
