import { useEffect, useState } from "react";

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T,
  useJSON = false
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const savedItem = localStorage.getItem(key);
  let savedValue;
  if (useJSON && savedItem) {
    try {
      savedValue = JSON.parse(savedItem);
    } catch {
      console.warn(`Invalid JSON: ${savedItem}`);
    }
  } else {
    savedValue = savedItem;
  }
  const [value, setValue] = useState(savedValue ?? defaultValue);
  useEffect(() => {
    localStorage.setItem(key, useJSON ? JSON.stringify(value) : value);
  }, [value]);
  return [value, setValue];
};
