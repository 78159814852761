import React, { useEffect } from "react";
import {
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import {
  useSettingsState,
  useSettingsDispatch,
  setFilterOptions,
  BaseSetting,
} from "../../context/settings";
import {
  useFieldsFiltersDispatch,
  useFieldsFiltersState,
} from "../../context/field-filters";

export const Filter = ({
  id,
  label,
  value,
  options,
  handleChange,
  color,
}: {
  id: string;
  label: string;
  value: string;
  options: string[];
  handleChange: (value: string) => void;
  color?: string;
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 120,
      maxWidth: 190,
      marginRight: theme.spacing(2),
    },
    input: {
      color: color ? color : theme.palette.text.secondary,
      fontWeight: 700,
      fontSize: "0.9rem",
    },
  }));
  const classes = useStyles();

  return (
    <FormControl className={classes.root}>
      <InputLabel shrink id={`field-${id}-filter-label`}>
        {label}
      </InputLabel>
      <Select
        className={classes.input}
        labelId={`field-${id}-filter-label`}
        id={`field-${id}-filter`}
        value={value}
        onChange={(event) => handleChange(event.target.value as string)}
        displayEmpty
        disableUnderline
      >
        <MenuItem value="">None</MenuItem>
        {options.map((option, index) => (
          <MenuItem key={`field-${id}-filter-option-${index}`} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const FieldFilters = ({
  color,
  fields,
}: {
  color: string;
  fields: { id: number, client_id: number, name: string }[];
}) => {
  const { fieldsSettings } = useSettingsState();
  const { filters } = fieldsSettings;
  const selection = useFieldsFiltersState();
  const settingsDispatch = useSettingsDispatch();
  const filtersDispatch = useFieldsFiltersDispatch();
  useEffect(() => {
    setFilterOptions(settingsDispatch, fields);
  }, [settingsDispatch, fields]);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      color: theme.palette.primary.dark,
      marginRight: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  // add any missing fields to the settings
  useEffect(() => {
    if (fieldsSettings.visibility.length > 0){
      const missingFields = fields.filter(
        (field) =>
          !fieldsSettings.visibility.find((item) => item.field_id === field.id)
      );
      if (missingFields.length > 0) {
        settingsDispatch({
          type: "fieldsVisibility",
          payload: [
            ...fieldsSettings.visibility,
            ...missingFields.map((field,idx) => ({
              id: `new_${idx}`,
              label: field.name,
              client_id: field.client_id,
              field_id: field.id,
              type: "field",
              hidden: 0,
              order: idx,
              ref: null
            } satisfies BaseSetting)),
          ],
        });
      }
    }
  }, [fields, filters, filtersDispatch]);

  return (
    <div className={classes.root}>
      <LocationSearchingIcon className={classes.icon} />
      <Filter
        id="farm"
        label="Farm"
        value={selection.farm}
        options={filters.farm}
        handleChange={(value) =>
          filtersDispatch({
            type: "fieldsFilterSelection",
            payload: { farm: value },
          })
        }
        color={color}
      />
      <Filter
        id="crop_type"
        label="Crop Type"
        value={selection.crop_type}
        options={filters.crop_type}
        handleChange={(value) =>
          filtersDispatch({
            type: "fieldsFilterSelection",
            payload: { crop_type: value },
          })
        }
        color={color}
      />
    </div>
  );
};

export default FieldFilters;
