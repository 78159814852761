import React from "react";
import FormButton from "../forms/FormButton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const StepperButtons = ({
  hideSucessButton,
  failButtonText,
  handleFailButton,
  disableFailButton,
  successButtonText,
  disableSuccessButton,
  handleSuccessButton,
  waiting,
  waitingText,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonGroup}>
      <FormButton
        text={failButtonText}
        handleClick={handleFailButton}
        disabled={disableFailButton}
      />
      {!hideSucessButton && (
        <FormButton
          text={successButtonText}
          disabled={disableSuccessButton}
          handleClick={handleSuccessButton}
          variant="contained"
          color="primary"
          waiting={waiting && waiting}
          waitingText={waitingText && waitingText}
        />
      )}
    </div>
  );
};

export default StepperButtons;
